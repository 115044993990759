/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

function Field(props) {
  const {
    label,
    errors,
    handleChange,
    handlePhoneChange,
    onBlur,
    name,
    value,
    type,
    noMargin,
    placeholder,
  } = props;
  const actualType = type ? type : 'text';

  let input;
  if (actualType === 'phone') {
    input = (
      <PhoneInput
        className=""
        defaultCountry="IE"
        placeholder="0832476137"
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={handlePhoneChange}
      />
    );
  } else if (actualType === 'checkbox') {
    input = (
      <input
        onChange={handleChange}
        onBlur={onBlur}
        name={name}
        value={value}
        className="w3-check w3-input"
        type={actualType}
      />
    );
  } else {
    input = (
      <input
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        name={name}
        value={value}
        className="w3-input w3-border"
        type={actualType}
      />
    );
  }

  return (
    <React.Fragment>
      <label className={`w3-text-teal ${noMargin ? '' : 'w3-margin-top'}`}>
        <b>
          {label} *
          {name === 'nickName' && (
            <>
              <span className="w3-margin-left w3-small w3-text-black">
                Used as a display name on the public leaderboard. Offensive
                names will be edited to your initials at the discretion of the
                organiser.
              </span>
            </>
          )}
        </b>
      </label>
      <div
        id="input-wrapper"
        style={{ border: errors && errors[name] ? '1px red solid' : '' }}
      >
        {input}
      </div>
    </React.Fragment>
  );
}

export default Field;
