import React from 'react';

function MostPicked(props) {
  const { mostPicked, sweepstakesData, textColor } = props;
  return (
    <React.Fragment>
      <h3 className={`w3-large sweeps-font-bold ${textColor}`}>
        Most Picked By Group
      </h3>
      <ul style={{ listStyleType: 'none', paddingInlineStart: 0 }}>
        {mostPicked.map((value, index) => {
          return (
            <li key={index}>
              {' '}
              {value.player} (
              {((value.picks / sweepstakesData.length) * 100).toFixed(0)}%)
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
}

export default MostPicked;
