import React from 'react';
import {
  CardContent,
  CardHeader,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Card from '@mui/material/Card';
import IndividualOrganisation from './IndividualOrganisation';

function OrganisationsView(props) {
  const {
    organisations,
    currentOrganisation,
    setCurrentOrganisation,
    orgDeepLinked,
  } = props;

  return (
    <div className="w3-row-padding w3-margin-top">
      <Grid container spacing={1}>
        <Grid item md={2} xs={12}>
          <Card>
            <CardHeader
              className="w3-text-teal sweeps-font-bold"
              title="My Organisations"
            />
            <CardContent>
              {!orgDeepLinked && (
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                  }}
                >
                  {organisations.map((organisation) => {
                    return (
                      <ListItem
                        key={organisation.name}
                        onClick={() => setCurrentOrganisation(organisation)}
                      >
                        <ListItemText
                          primary={organisation.name}
                          secondary={organisation.primarySport}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
              {orgDeepLinked && (
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                  }}
                >
                  <ListItem key={currentOrganisation?.name}>
                    <ListItemText
                      primary={currentOrganisation?.name}
                      secondary={currentOrganisation?.primarySport}
                    />
                  </ListItem>
                </List>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={10} xs={12}>
          <Card>
            <CardHeader
              className="w3-text-teal sweeps-font-bold"
              title={currentOrganisation?.name}
            />
            <CardContent>
              <IndividualOrganisation
                {...props}
                organisation={currentOrganisation}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrganisationsView;
