import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from './Form';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { addNewLicense } from '../../../features/lottoLicenses/lottoLicensesSlice';

function CreateLicense(props) {
  const { user, organisationId } = props;

  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    sweepId: '',
    file: undefined,
    errors: {},
    successfulCreate: false,
    failedCreate: false,
  });

  function handleChange(evt) {
    const value =
      evt.target.name === 'file' ? evt.target.files[0] : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const canSave =
    [state.file, organisationId].every(Boolean) && addRequestStatus === 'idle';

  const onSavePostClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');
        await dispatch(
          addNewLicense({
            organisationId: organisationId,
            sweepId: state.sweepId,
            file: state.file,
            fileName: state.file?.name ? state.file.name : '',
            user: user,
          }),
        ).unwrap();
        // TODO: reset the state
      } catch (err) {
        toast.error('Failed to save the license');
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <div style={{ margin: '4px' }}>
      <p>
        After uploading a license, licenses can be found under the associated
        sweepstake itself. It may take some time for the change to be reflected
        there.
      </p>

      <Form
        handleChange={handleChange}
        sweepId={state.sweepId}
        organisationId={organisationId}
      />

      <Button
        onClick={() => onSavePostClicked()}
        variant="contained"
        disabled={addRequestStatus != 'idle'}
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Create
      </Button>
    </div>
  );
}

export default CreateLicense;
