import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadSweeps } from '../../../features/useLoadSweeps';
import { useState } from 'react';
import axios from 'axios';
import EntriesView from './EntriesView';

function EntriesContainer(props) {
  const { user } = props;

  const { getSweepsArray } = useLoadSweeps();
  let sweeps = getSweepsArray();

  const [state, setState] = useState({
    allEntries: [],
  });

  const navigate = useNavigate('');

  useEffect(() => {
    if (!user?.adminAccess) {
      navigate('/');
    } else {
      fetchEntries();
    }
  }, [user]);

  function fetchEntries() {
    axios
      .get(process.env.REACT_APP_API_URL + '/dashboard/entries', {
        headers: {
          Authorization: `Bearer ${user?.id_token}`,
        },
      })
      .then(function (response) {
        setState({
          ...state,
          allEntries: response.data['entries'],
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          setState({
            ...state,
          });
        }
      });
  }

  const totalEntriesCount = state.allEntries.length;
  state.allEntries.forEach((entry) => {
    const sweep = sweeps.filter((sweep) => sweep.id === entry['sweepstake'])[0];
    entry['competition'] = sweep?.competition?.name;
    entry['organisation'] = sweep?.organisation?.name;
  });

  return (
    <EntriesView
      user={user}
      totalEntriesCount={totalEntriesCount}
      allEntries={state.allEntries}
    />
  );
}

export default EntriesContainer;
