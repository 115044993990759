import React from 'react';
const SubmitNoPaymentTeam = (props) => {
  const { sweep, submitSelections } = props;
  return (
    <div className="w3-padding">
      <h3 className="w3-text-teal sweeps-font-bold">Submit Team</h3>

      {!sweep?.private_sweep && (
        <p>
          You are claiming a free entry based on the number of usages of your
          referral code.
        </p>
      )}

      {sweep?.private_sweep && (
        <p>
          By clicking below, you are submitting your entries. Entries can be
          edited until the competition closing date.
        </p>
      )}

      <button
        data-testid="submitButton"
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        onClick={() => submitSelections('', !sweep?.private_sweep)}
      >
        Submit Team
      </button>
    </div>
  );
};

export { SubmitNoPaymentTeam };
