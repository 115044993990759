/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { getEntryFee } from '../../../Helpers/SweepsHelper';

function Rules(props) {
  const { sweep } = props;
  return (
    <>
      <p className="w3-center">Here's how it works:</p>
      <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
        <i className="fa-solid fa-people-group w3-padding-right w3-large"></i>
        Selecting your team
      </h5>

      <p className="w3-center">
        Players will be divided up into 5 groups based on their odds <br />{' '}
        (Example: The favorite will be in Group 1, and a 200/1 outsider will be
        in group 5).
      </p>
      <p className="w3-center">You will select one player from each group.</p>
      <img
        src="/groups-divide-2.png"
        className="center"
        style={{ width: '100%', maxWidth: '600px' }}
      />

      <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
        <i className="fa-solid fa-trophy  w3-padding-right w3-large"></i>
        The Scoring
      </h5>
      <p className="w3-center">
        The finishing position of your player will be their score (Example: John
        Rahm finishing T-9th = 9 points).
      </p>
      <p className="w3-center">
        The person with the lowest total sum of points from their 5 players will
        be the winner.
      </p>

      <p className="w3-center" style={{ marginBottom: '40px' }}>
        The tiebreaker will be closest to the winning score, so simply enter
        what you believe the winning score will be (example: entering '18' means
        you believe the winner of the tournament will finish on 18 under par)
      </p>

      <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
        <i className="fa-solid fa-magnifying-glass w3-padding-right w3-large"></i>
        The Catch?
      </h5>
      <p className="w3-center" style={{ marginBottom: '40px' }}>
        As always, IF ONE OF YOUR PLAYERS MISSES THE CUT, YOU ARE OUT.
      </p>

      {!sweep?.private_sweep && (
        <>
          <h5 className="w3-large sweeps-font-bold w3-text-sviolet w3-center">
            <i className="fa-solid fa-sack-dollar  w3-padding-right w3-large"></i>
            Entry Cost
          </h5>
          <p className="w3-center">{getEntryFee(sweep)} entry per team.</p>
        </>
      )}

      <p className="w3-center">
        Please find the rules{' '}
        <a
          className="pointer"
          onClick={() =>
            window.open(`${window.location.origin}/terms`, '_blank')
          }
        >
          here
        </a>{' '}
        to understand exactly what happens in exception events like player
        withdrawals.
      </p>
    </>
  );
}

export default Rules;
