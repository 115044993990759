import React from 'react';

function Testimonial(props) {
  const { text, organisation } = props;
  return (
    <div className="w3-container">
      <div className="w3-row">
        <h3 className="w3-large w3-center sweeps-font-bold w3-text-teal">
          {organisation}
        </h3>
        <p className="w3-center">{text}</p>
      </div>
    </div>
  );
}

export default Testimonial;
