import React from 'react';
import { CardContent, CardHeader, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CreateSweep from '../CreateSweep';
import Sweeps from '../Sweeps';
import { ReplacePlayer } from '../../Dashboard/Operations/ReplacePlayer';

function DataTransformationView(props) {
  const { user } = props;

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <h3 className="w3-text-teal sweeps-font-bold">
              Replace Player in golf entries
            </h3>
            <ReplacePlayer user={user} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default DataTransformationView;
