/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from '../Login/AuthWrapper';
import Register from './Register';

function RegisterWrapper(props) {
  const { fetchUserDetails, user } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({
    registered: false,
    email: '',
  });

  function onRegisteredUser(userId, email) {
    setState({
      ...state,
      registered: true,
      email: email,
    });
  }

  useEffect(() => {
    if (user) {
      navigate('/entries');
    }
  }, [user]);

  return (
    <>
      <div className="w3-row-padding w3-margin-bottom">
        <div className="w3-container w3-margin-top">
          <div className="w3-row-padding w3-margin-top">
            <div className="w3-row">
              <div className="w3-container w3-card w3-white">
                {state.registered && (
                  <AuthWrapper
                    fetchUserDetails={fetchUserDetails}
                    successMessage={
                      "Successfully created account, We've sent you a confirmation email. Please confirm using the email link and login."
                    }
                    email={state.email}
                  />
                )}
                {!user && !state.registered && (
                  <Register
                    onSuccess={onRegisteredUser}
                    fetchUserDetails={fetchUserDetails}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterWrapper;
