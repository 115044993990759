import React from 'react';
import { getPrizeForKey } from '../../Helpers/PrizeHelper';
function PrizeWrapper(props) {
  const { sweep } = props;

  return (
    <>
      {sweep.the_sweep && (
        <>
          <h3 className="w3-large sweeps-font-bold w3-text-sviolet">
            Prize Details
          </h3>
          <ul
            style={{
              listStyle: 'none',
              marginBlockStart: '0',
              paddingInlineStart: '0',
            }}
            className="w3-center"
          >
            <li>80% of the paid entries * 10:</li>
          </ul>
          <h4 className="w3-large w3-center sweeps-font-bold w3-text-teal">
            Currently €{sweep.entry_count * 0.8}
          </h4>
        </>
      )}
      {!sweep.the_sweep && getPrizeForKey(sweep?.prize_breakdown)?.component}
    </>
  );
}

export default PrizeWrapper;
