import axios from 'axios';
import { getUrlAlias } from './SweepsHelper';
import { logMetric } from './AnalyticsHelper';

export const getReferralCode = (user, sweep_id, callback) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
    headers: { Authorization: 'Bearer ' + user.id_token },
  });

  instance
    .get('/referral/code?sweep_id=' + sweep_id)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
};

export const getReferralLink = (sweep, code) => {
  return code
    ? `https://${window.location.host}/${getUrlAlias(sweep)}?referral=${code}`
    : `https://${window.location.host}/${getUrlAlias(sweep)}`;
};

export const addReferralLinkToClipboard = async (sweep, code) => {
  logMetric('referral', 'copiedToClipboard');
  const text = getReferralLink(sweep, code);
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

export const validateReferral = async (sweep, user, callback) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
    headers: { Authorization: 'Bearer ' + user.id_token },
  });

  instance
    .get('/referral/validate?sweep_id=' + sweep['id'])
    .then((response) => {
      logMetric('referral', 'successfulReferralValidationWithApi');
      callback(response.data['valid']);
    })
    .catch((error) => {
      logMetric('referral', 'unsuccessfulReferralValidationWithApi');
      console.log(error);
      callback(false);
    });
};

export const fetchReferralCodes = async (user, callback) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 2000,
    headers: { Authorization: 'Bearer ' + user.id_token },
  });

  instance
    .get('/referral/used_codes')
    .then((response) => {
      callback(response.data['codes']);
    })
    .catch((error) => {
      callback([]);
    });
};
