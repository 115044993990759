import React from 'react';
import Field from '../Form/Fields/Field';

function Contact() {
  const [state, setState] = React.useState({
    name: '',
    organisation: '',
  });

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function getEmailString() {
    return `mailto:ben@thesweeps.io,conor@thesweeps.io
    ?subject=Sweeps for ${state.organisation}!
    &body=Hi Conor and Ben, %0D%0A%0D%0AI'm interested in running a sweeps for ${state.organisation}. %0D%0A%0D%0AThanks,  %0D%0A${state.name}`;
  }

  return (
    <>
      <Field
        label="Name"
        handleChange={handleChange}
        name="name"
        value={state.name}
        placeholder=""
      />
      <Field
        label="Organisation"
        handleChange={handleChange}
        name="organisation"
        value={state.organisation}
        placeholder=""
      />
      <a
        href={getEmailString()}
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Get in touch!
      </a>
    </>
  );
}

export default Contact;
