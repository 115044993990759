import React from 'react';

function Player(props) {
  const { player, percentPicked } = props;

  const percentDisplay = percentPicked
    ? ' - ' + percentPicked.toString() + '%'
    : '';

  return (
    <React.Fragment>
      {player && (
        <>
          <li key={player.id} className="w3-bar">
            <img
              src={player.avatar}
              className="w3-bar-item w3-circle"
              style={{ width: '85px', aspectRatio: '4.5/3.7' }}
              alt={player.name}
            />
            <div className="w3-bar-item">
              <span className="w3-large">
                {player.name} {percentDisplay}
              </span>
              <br />
            </div>
          </li>
        </>
      )}
      {!player && <></>}
    </React.Fragment>
  );
}

export default Player;
