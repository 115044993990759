import React from 'react';

function DreamTeam(props) {
  const { team, textColor } = props;
  return Object.keys(team).length !== 0 ? (
    <>
      <h3 className={`w3-large sweeps-font-bold ${textColor}`}>
        <b>The Dream Team</b>
      </h3>
      <ul style={{ listStyleType: 'none', paddingInlineStart: 0 }}>
        <li>{team.group_1}</li>
        <li>{team.group_2}</li>
        <li>{team.group_3}</li>
        <li>{team.group_4}</li>
        <li>{team.group_5}</li>
        <li>Total Score: {team.visual_score}</li>
      </ul>
    </>
  ) : (
    <></>
  );
}

export default DreamTeam;
