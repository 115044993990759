import React, { useEffect, useState } from 'react';
import Team from '../../SweepsSignup/Rugby/Team';
import { getTeamForId } from '../../../Helpers/RugbyMatchesHelper';

const SmallMatch = (props) => {
  const { pick, match, result } = props;

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    getTeamForId(match?.teams, setTeams);
  }, []);

  const homeTeam = teams[0] || undefined;
  const awayTeam = teams[1] || undefined;

  const getPickString = (pick) => {
    if (pick.draw) {
      return 'Draw';
    }
    const pickedTeam = teams.filter((team) => team.teamId === pick.teamId)[0];
    const upperBound = pick.upperBound < 1000 ? ' - ' + pick.upperBound : '+';
    return pickedTeam?.shortName + ' by ' + pick?.lowerBound + upperBound;
  };

  return (
    <div className="w3-quarter">
      <div className="w3-row">
        <Team
          team={homeTeam}
          noName={true}
          showScore={true}
          score={
            result?.result.score?.filter(
              (score) => score.team_id == homeTeam?.teamId,
            )[0]
          }
        />
        <Team
          team={awayTeam}
          noName={true}
          showScore={true}
          score={
            result?.result.score?.filter(
              (score) => score.team_id == awayTeam?.teamId,
            )[0]
          }
        />

        <div className="w3-container">
          {!result && (
            <>
              <div
                className="w3-center w3-small"
                style={{ width: '90%', height: '25px' }}
              >
                {match?.date}
              </div>
            </>
          )}
          {result?.score === 0 && (
            <span
              class="w3-tag w3-red w3-small"
              style={{ width: '90%', height: '18px' }}
            >
              {getPickString(pick)}
            </span>
          )}
          {result?.score === 8 && (
            <span
              class="w3-tag w3-orange w3-small"
              style={{ width: '90%', height: '18px' }}
            >
              {getPickString(pick)}
            </span>
          )}
          {(result?.score === 13 || result?.score === 30) && (
            <span
              class="w3-tag w3-teal w3-small"
              style={{ width: '90%', height: '18px' }}
            >
              {getPickString(pick)}
            </span>
          )}
          {!result && (
            <span
              class="w3-tag w3-grey w3-small"
              style={{ width: '90%', height: '18px' }}
            >
              {getPickString(pick)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SmallMatch);
