/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { getEntryFee } from '../../../Helpers/SweepsHelper';

function Rules(props) {
  const { sweep } = props;

  return (
    <div data-testid="horseAuctionRules">
      <p className="w3-center">Here's how it works:</p>
      <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
        <i className="fa-solid fa-people-group w3-padding-right w3-large"></i>
        Selecting your results
      </h5>

      <p className="w3-center">
        You must select 5 yearlings – one from each of the 5 lists (sorted
        alphabetically by stallion)
      </p>

      <p className="w3-center">
        You can only select one yearling from each Stallion. <br />
        If there are four yearlings sired by FRANKEL in the sale (lot numbers
        32, 297, 301 and 525) – only one of those can be selected in a
        particular entry.
      </p>

      {!sweep?.private_sweep && (
        <>
          <h5 className="w3-large sweeps-font-bold w3-text-sviolet w3-center">
            <i className="fa-solid fa-sack-dollar  w3-padding-right w3-large"></i>
            Entry Cost
          </h5>
          <p className="w3-center">{getEntryFee(sweep)} per entry.</p>
        </>
      )}

      <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
        <i className="fa-solid fa-trophy  w3-padding-right w3-large"></i>
        The Scoring
      </h5>

      <p className="w3-center">
        The yearlings’ auction prices are combined to give the entrants’ total
        for the competition.
      </p>

      <p className="w3-center">
        If a yearling is unsold (Reserve Not Attained) then the sale price is €0
        for that individual.
      </p>

      <p className="w3-center">
        Please find the rules{' '}
        <a
          className="pointer"
          onClick={() =>
            window.open(`${window.location.origin}/terms`, '_blank')
          }
        >
          here
        </a>{' '}
        to understand exactly what happens in exception events.
      </p>
    </div>
  );
}

export default Rules;
