/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Match from './Match';

function Group(props) {
  const {
    matches,
    groupTitle,
    handleMatchPicked,
    previousSlide,
    nextSlide,
    picks,
    hidePrevious,
  } = props;

  const isEachMatchPicked = (matches, picks) => {
    let hasUnpickedMatch = false;
    matches?.forEach((match) => {
      if (!picks[match.gameId] || !picks[match.gameId].teamId) {
        hasUnpickedMatch = true;
      }
    });
    return matches && !hasUnpickedMatch;
  };

  const progressButton =
    matches && isEachMatchPicked(matches, picks) ? (
      <button
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
        onClick={() => nextSlide()}
      >
        Next
      </button>
    ) : (
      <button
        disabled
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
      >
        Next
      </button>
    );

  return (
    <>
      <div className="w3-row-padding">
        <h4 className="sweeps-font-medium w3-text-teal">{groupTitle}</h4>
        <p>Select each match and continue</p>
      </div>
      <div className="w3-row">
        {matches.map((match) => (
          <Match
            key={match.id}
            match={match}
            handleMatchPicked={handleMatchPicked}
            id={match.id}
            selection={picks[match.gameId]?.teamId}
            lowerBound={picks[match.gameId]?.lowerBound}
            upperBound={picks[match.gameId]?.upperBound}
            size={matches.length > 1 ? 'w3-half' : 'w3-row'}
          />
        ))}
      </div>
      <div className="w3-row-padding">
        {!hidePrevious && (
          <button
            onClick={() => previousSlide()}
            className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
          >
            Back
          </button>
        )}

        {progressButton}
      </div>
    </>
  );
}

export default Group;
