import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import Group from './Groups';

const getColumns = (groupsData) => {
  return [
    {
      name: '#',
      selector: (row) => row.position,
      grow: 0,
      width: '60px',
    },
    {
      name: 'Player',
      selector: (row) => row.player,
      width: '200px',
    },
    {
      name: 'Total',
      selector: (row) => row.to_par,
      grow: 0,
    },
    {
      name: 'Group',
      selector: (row) => <Group player={row.player} groupsData={groupsData} />,
      grow: 0,
    },
  ];
};

class TournamentLeaderboard extends Component {
  render() {
    let { groupsData, liveScores, textColor, competition } = this.props;
    return (
      <div style={{ height: '500px', overflow: 'scroll' }}>
        <h3 className={`w3-large sweeps-font-bold ${textColor}`}>
          {competition?.name}
        </h3>

        <DataTable
          keyField={liveScores}
          columns={getColumns(groupsData)}
          data={liveScores}
        />
      </div>
    );
  }
}

export default TournamentLeaderboard;
