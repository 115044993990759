/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Error from '../Error';
import { getTypeById, getTypes } from '../../../Helpers/CompetitionHelper';

function CompetitionTypeField(props) {
  const { errors, handleChange, value, name } = props;

  const input = (
    <select
      defaultValue={''}
      onChange={handleChange}
      className="w3-select"
      name={name}
    >
      {value && value !== 'none' && (
        <option value={value} selected>
          {getTypeById(value)?.name}
        </option>
      )}
      <option value="none">Select Player</option>
      {getTypes()?.map((type) => {
        return (
          <option key={type.id} value={type.id}>
            {type.name}
          </option>
        );
      })}
    </select>
  );

  return (
    <React.Fragment>
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Select Competition Type</b>
      </label>
      {errors && errors[name] && <Error error={errors[name]} />}
      {input}
    </React.Fragment>
  );
}

export default CompetitionTypeField;
