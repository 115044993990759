import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import GolfLeaderboard from './Golf/Leaderboard';
import RugbyLeaderboard from './Rugby/Leaderboard';
import HorseAuctionLeaderboard from './HorseAuction/Leaderboard';
import axios from 'axios';

function LeaderboardWrapper(props) {
  const { user } = props;
  const mountedRef = useRef();
  const { sweep_id } = useParams();

  const [sweep, setSweep] = useState(null);

  useEffect(() => {
    mountedRef.current = true;
    fetchSweep(sweep_id);
  }, []);

  const fetchSweep = async (sweep_id) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + '/sweeps?sweep_id=' + sweep_id,
    );
    setSweep(response.data.sweeps[0]);
  };

  let leaderboard = undefined;
  if (sweep?.competition?.sport === 'golf') {
    leaderboard = <GolfLeaderboard sweep={sweep} user={user} />;
  }
  if (sweep?.competition?.sport === 'rugby') {
    leaderboard = <RugbyLeaderboard sweep={sweep} user={user} />;
  }
  if (sweep?.competition?.sport === 'horse_auction') {
    leaderboard = <HorseAuctionLeaderboard sweep={sweep} user={user} />;
  }

  return leaderboard || <></>;
}

export default LeaderboardWrapper;
