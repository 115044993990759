import React from 'react';

function Group(props) {
  const { player, groupsData } = props;

  const getGroupForPlayer = () => {
    for (const group in groupsData) {
      if (
        groupsData[group].players?.includes(player) ||
        groupsData[group].players?.includes(player + ' (a)') ||
        groupsData[group].players?.includes(
          player.substring(0, player.length - 4),
        )
      ) {
        return parseInt(group) + 1;
      }
    }
  };
  const group = getGroupForPlayer() || '';

  return <>{group}</>;
}

export default Group;
