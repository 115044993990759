import React, { useState } from 'react';

function Filters(props) {
  const { name, updateFilters } = props;

  const [filterName, setNameFilter] = useState(name);

  const updateName = (e) => {
    setNameFilter(e);
    updateFilters(e);
  };

  return (
    <React.Fragment>
      <div id="filters" style={{ marginBottom: '40px' }}>
        <label>
          <b className="w3-text-teal">Filter by Name:</b>
        </label>
        <input
          type="text"
          value={filterName}
          className="w3-input"
          name="nameFilter"
          onChange={(e) => updateName(e.target.value)}
        />
      </div>
    </React.Fragment>
  );
}

export default Filters;
