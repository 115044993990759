import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchPost } from '../../../Helpers/BlogHelper';
import { useNavigate } from 'react-router-dom';
import Card from '../../Layouts/Card';
import DOMPurify from 'dompurify';
import { getUrlAlias } from '../../../Helpers/SweepsHelper';
import { useLoadSweeps } from '../../../features/useLoadSweeps';

function Post() {
  const { post_id } = useParams();
  const navigate = useNavigate('');

  const [post, setPost] = useState({});
  const { getSweepsArray } = useLoadSweeps();
  const sweeps = getSweepsArray();

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
    fetchPost(post_id, updatePost);
  }, []);

  function updatePost(response) {
    if (response.data) {
      if (response.data['posts'].length === 0) {
        navigate('/posts');
      }
      setPost(response.data['posts'][0]);
    }
  }

  const cleanHTML = DOMPurify.sanitize(post?.body, {
    USE_PROFILES: { html: true },
  });

  const hasRelatedSweeps =
    sweeps.filter((sweep) => sweep.competition_id === post.competition_id)
      .length > 0;
  return (
    <div className="w3-row-padding">
      <div className="w3-twothird">
        <div className="w3-container w3-card w3-margin-top w3-white">
          <h3 className=" w3-text-teal sweeps-font-bold">{post?.title}</h3>
          <p className="w3-margin-top">
            <i className={`fa fa-clock-o fa-fw w3-margin-right w3-large`} />
            {post?.date_created}
          </p>
          <div dangerouslySetInnerHTML={{ __html: cleanHTML }}></div>
        </div>
      </div>
      <div className="w3-third">
        <div className="w3-container w3-text-grey">
          <Card>
            <div className="w3-display-container no-margin-top">
              <img
                src={post?.image_url}
                className="w3-center"
                style={{ width: '100%', display: 'block', margin: 'auto' }}
                alt={post?.title}
              />
            </div>
          </Card>
          {hasRelatedSweeps && (
            <Card>
              <div className="w3-display-container no-margin-top w3-padding">
                <h3 className={`w3-large sweeps-font-bold w3-text-teal`}>
                  Related Sweeps
                </h3>
                {sweeps
                  .filter(
                    (sweep) => sweep.competition_id === post.competition_id,
                  )
                  .map((sweep) => (
                    <>
                      <li className="w3-bar">
                        <img
                          src={sweep?.image_url}
                          className="w3-bar-item w3-circle"
                          style={{ maxWidth: '60px', aspectRatio: '3/2' }}
                        />
                        <div className="w3-bar-item">
                          <span className="w3-large">
                            <Link to={`/${getUrlAlias(sweep)}`}>
                              {sweep?.organisation?.name}
                            </Link>
                          </span>
                        </div>
                      </li>
                    </>
                  ))}
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}

export default Post;
