import React from 'react';
import Entry from './Entry';

function EntriesWrapper(props) {
  const { user, entriesChunk, refreshEntries } = props;
  return (
    <div className="w3-row">
      {entriesChunk.map((entry) => {
        return (
          <Entry
            user={user}
            refreshEntries={refreshEntries}
            key={entry.id}
            entry={entry}
          />
        );
      })}
    </div>
  );
}

export default EntriesWrapper;
