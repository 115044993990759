/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import axios from 'axios';
import CheckboxField from '../../../Form/Fields/CheckboxField';
import {
  Alert,
  AlertTitle,
  Button,
  List,
  ListItem,
  Typography,
} from '@mui/material';

function Connect(props) {
  const { linked, connectAccount } = props;

  const [termsAgreed, setTermsAgreed] = useState(false);

  const handleChange = (event) => {
    const value = event.target.checked;
    setTermsAgreed(value);
  };

  return (
    <>
      {linked && (
        <Alert severity="success">
          <AlertTitle>Successfully Setup Organisation</AlertTitle>
          There are no required actions at this time
        </Alert>
      )}
      {!linked && (
        <>
          <Alert severity="error" sx={{ marginBottom: '30px' }}>
            <AlertTitle>Stripe Connection Incomplete</AlertTitle>
            Your stripe account is not successfully linked. Sweeps cannot take
            payments until Stripe is successfully connected.
          </Alert>

          <Typography>
            You can finish the integration by clicking the button below and
            following the steps on stripe.
          </Typography>
          <hr />
          <Typography>
            By connecting your stripe account, you're agreeing to the following:
          </Typography>
          <List>
            <ListItem>
              Your organisation will be responsible for all prize money
              resulting from any Sweeps run on thesweeps.io that benefits your
              organisation, regardless of the number of entrants.
            </ListItem>
            <ListItem>
              Your organisation is responsible for paying out prizes to all
              winners of any Sweeps run on thesweeps.io that benefit your
              organisation within 7 days of the competition's completion.
            </ListItem>
          </List>
          <CheckboxField
            label="Agree to terms"
            errors={[]}
            handleChange={handleChange}
            name="terms"
            value={termsAgreed}
            type="checkbox"
          />
          {termsAgreed && (
            <Button onClick={() => connectAccount()}>
              Finish Stripe integration
            </Button>
          )}
          {!termsAgreed && <Button disabled>Finish Stripe integration</Button>}
        </>
      )}
    </>
  );
}

export default Connect;
