import React from 'react';
import DataTable from 'react-data-table-component';

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      minWidth: '90px',
    },
  },
};

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    item['nickName'] = item['nickName'].replace('#', ' ');
    item['nickName'] = item['nickName'].replace(',', ' ');
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function downloadCSV(array) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;
  const filename = 'sweeps-entries.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const Export = ({ onExport }) => (
  <button
    className="w3-btn w3-small w3-center w3-margin-top w3-teal w3-text-white"
    onClick={(e) => onExport(e.target.value)}
  >
    Export
  </button>
);

function Table(props) {
  const { data, columns } = props;
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    [data],
  );
  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        actions={actionsMemo}
      />
    </React.Fragment>
  );
}

export default Table;
