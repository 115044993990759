/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { isEntriesFinished, isEntriesOpen } from '../../Helpers/MiscHelpers';
import { getUrlAlias } from '../../Helpers/SweepsHelper';
import { useNavigate } from 'react-router-dom';
import { getPrizeForKey } from '../../Helpers/PrizeHelper';
import { Textfit } from 'react-textfit';

function SweepCard(props) {
  const { sweep } = props;
  const entriesOpen = isEntriesOpen(sweep.competition);
  const entriesFinished = isEntriesFinished(sweep.competition);
  const navigate = useNavigate();

  return (
    <>
      <div className="w3-third w3-margin-top">
        <div className="h-100 w3-card w3-white">
          <div>
            <img
              src={sweep?.image_url}
              style={{ width: '100%' }}
              alt={sweep?.organisation?.name}
            />
          </div>

          <div className="w3-container w3-center" style={{ width: '100%' }}>
            <Textfit
              mode="single"
              max={32}
              style={{ lineHeight: '48px' }}
              className="w3-text-sviolet sweeps-font-bold"
            >
              {!sweep?.the_sweep && sweep?.organisation?.name}
              {sweep?.the_sweep && 'The Sweep'}
            </Textfit>

            <h5 className="w3-text-sviolet sweeps-font-bold">
              {sweep.competition.name}
            </h5>
            {!sweep?.the_sweep && (
              <h6 className="sweeps-font-medium">
                Prize Fund
                {getPrizeForKey(sweep?.prize_breakdown)?.monetary
                  ? ''
                  : ' Worth'}
                : €{getPrizeForKey(sweep?.prize_breakdown)?.fund}
              </h6>
            )}
            {sweep.the_sweep && (
              <h6 className="sweeps-font-medium">
                80% of Pot: €{sweep?.entry_count * 0.8}
              </h6>
            )}

            <p># Entries: {sweep?.entry_count > 0 && sweep?.entry_count}</p>

            <div className="w3-padding">
              {entriesOpen && (
                <>
                  <button
                    onClick={() => {
                      navigate(`/${getUrlAlias(sweep)}`);
                    }}
                    className="w3-center w3-button  w3-block w3-teal"
                    style={{ margin: 'auto', float: 'center' }}
                  >
                    Enter Now
                  </button>
                </>
              )}
              {entriesFinished && (
                <>
                  <button
                    onClick={() => {
                      navigate(`/${getUrlAlias(sweep)}/leaderboard`);
                    }}
                    className="w3-center w3-button w3-block w3-teal"
                  >
                    View Leaderboard
                  </button>
                </>
              )}
              {!entriesOpen && !entriesFinished && (
                <>
                  <button
                    onClick={() => {
                      navigate(`/${getUrlAlias(sweep)}/`);
                    }}
                    className="w3-center w3-button w3-block w3-teal"
                  >
                    View Sweep
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SweepCard;
