import { CopyReferralCode } from './CopyReferralCode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getReferralCode } from '../../Helpers/ReferralsHelper';
import { getUrlAlias } from '../../Helpers/SweepsHelper';

const Referral = (props) => {
  const { sweepId, sweep, user } = props;

  const navigate = useNavigate();

  const [referralCode, setReferralCode] = useState('');
  const [usageCount, setUsageCount] = useState(0);

  useEffect(() => {
    getReferralCode(user, sweepId, updateReferralCode);
  }, []);

  const updateReferralCode = (response) => {
    if (response.data) {
      setReferralCode(response.data['code']);
      setUsageCount(response.data['usage_count']);
    }
  };

  const modoloNumber = usageCount % 5;
  const width =
    usageCount > 5 || (usageCount !== 0 && modoloNumber === 0)
      ? '100'
      : (modoloNumber / 5) * 100;
  const button =
    usageCount > 5 || (usageCount !== 0 && modoloNumber === 0) ? (
      <button
        className="w3-button w3-margin-top w3-block w3-teal"
        style={{ borderRadius: '0px' }}
        onClick={() => navigate(`/${getUrlAlias(sweep)}?type=claim`)}
      >
        Claim Free Entry
      </button>
    ) : (
      <button
        className="w3-button w3-margin-top w3-block w3-teal"
        style={{ borderRadius: '0px' }}
        disabled={true}
      >
        Claim Free Entry
      </button>
    );

  return (
    <div className="w3-margin w3-card w3-white">
      <header className="w3-teal">
        <h3
          className="w3-center sweeps-font-bold w3-padding no-margin-top"
          style={{ marginBottom: '0px', paddingBottom: '0px' }}
        >
          {sweep.competition.name}
        </h3>
        <h6
          className="w3-center sweeps-font-bold w3-padding no-margin-top"
          style={{ paddingTop: '0px' }}
        >
          {' '}
          Supporting {sweep?.organisation?.name}
        </h6>
      </header>
      <div className="w3-container">
        <div className="w3-center">
          <CopyReferralCode referralCode={referralCode} sweep={sweep} />
        </div>
        <div className="w3-margin-top w3-center">
          <span className="sweeps-font-medium w3-text-teal">
            Progress toward free entry:
          </span>
          <div class="w3-light-grey">
            {/* Calculate the percentage against every 5 */}
            <div
              className="w3-sviolet w3-center"
              style={{ height: '24px', width: `${width}%` }}
            >
              {usageCount}/5
            </div>
          </div>
        </div>
      </div>
      {button}
    </div>
  );
};

export { Referral };
