import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getOrganisations,
  getFetchingStatus,
  getLastUpdated,
  fetchOrganisations,
} from './organisations/organisationsSlice';

export const useOrganisations = () => {
  const organisations = useSelector(getOrganisations);
  const fetchingStatus = useSelector(getFetchingStatus);
  const lastUpdated = useSelector(getLastUpdated);

  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchingStatus === 'idle') {
      dispatch(fetchOrganisations());
    }
  }, [fetchingStatus, dispatch]);

  const getOrganisationsArray = () => {
    const orgsArray = [];
    Object.keys(organisations).forEach((orgId) => {
      orgsArray.push(organisations[orgId]);
    });
    return orgsArray;
  };

  return {
    fetchingStatus,
    getOrganisationsArray,
    lastUpdated,
  };
};
