import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Form from './Form';
import { createCompetition } from '../../Helpers/CompetitionHelper';
import SuccessPanel from '../Form/SuccessPanel';

function CreateCompetition(props) {
  const { user } = props;

  const navigate = useNavigate('');
  if (!user?.adminAccess) {
    navigate('/');
  }

  const [state, setState] = React.useState({
    name: '',
    sport: 'golf',
    format: 'strokeplay',
    user_id: user?.userId,
    start_date: new Date(),
    end_date: new Date(),
    entry_open_date: new Date(),
    entry_close_date: new Date(),
    last_tee_time: new Date(),
    url: '',
    makeCut: undefined,
    file: undefined,
    configFile: undefined,
    successfulCreate: false,
    failedCreate: false,
    errors: {},
  });

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
  }, []);

  function setDate(key, date) {
    setState({
      ...state,
      [key]: date,
    });
  }

  function onBlur(evt) {
    const value = evt.target.value;
    let messages = [];
    let addErrors = false;

    if (!value || !/\S/.test(value)) {
      addErrors = true;
      messages.push('Mandatory field, please enter a value');
    }

    if (addErrors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: messages,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
          form: undefined,
        },
      });
    }
  }

  function handleChange(evt) {
    const value =
      evt.target.type === 'file' ? evt.target.files[0] : evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function handleCreateCompetitionResponse(response) {
    if (response.data) {
      if (response.status === 200) {
        setState({
          ...state,
          failedCreate: false,
          successfulCreate: true,
        });
      }
    }
    if (response.response) {
      setState({
        ...state,
        successfulCreate: false,
        failedCreate: true,
      });
    }
  }

  return (
    <>
      {state.successfulCreate && (
        <SuccessPanel message="Successfully created competition" />
      )}
      {state.failedCreate && 'Failed to create competition'}
      <h3 className="sweeps-font-bold w3-text-teal">Create Competition</h3>
      <Form
        errors={state.errors}
        onBlur={onBlur}
        handleChange={handleChange}
        setDate={setDate}
        name={state.name}
        sport={state.sport}
        format={state.format}
        url={state.url}
        start_date={state.start_date}
        end_date={state.end_date}
        entry_open_date={state.entry_open_date}
        entry_close_date={state.entry_close_date}
        last_tee_time={state.last_tee_time}
        makeCut={state.makeCut}
      />

      <button
        onClick={() =>
          createCompetition(user, state, handleCreateCompetitionResponse)
        }
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Create
      </button>
    </>
  );
}

export default CreateCompetition;
