import React from 'react';
import { Alert, AlertTitle, Grid, List, ListItem } from '@mui/material';
import PDFViewer from '../../Layouts/PDFViewer';
import { prettyDate } from '../../../Helpers/MiscHelpers';
function IndividualLicenses(props) {
  const { licenses } = props;

  const licensesForSort = [...licenses];
  return (
    <Grid container>
      <Grid item md={12}>
        <h5 className="sweeps-font-bold">Lotto Licenses</h5>
      </Grid>
      {licensesForSort.length > 0 &&
        licensesForSort
          .sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
          .map((license) => {
            return (
              <Grid container md={12} key={license.id}>
                <Grid item sm={4}>
                  <List>
                    <ListItem>
                      <b>License ID:</b> {license?.id}
                    </ListItem>
                    <ListItem>
                      <b>Uploaded:</b> {prettyDate(license?.date_created)}
                    </ListItem>
                  </List>
                </Grid>
                <Grid item sm={8}>
                  {license?.file_url && license.file_url.includes('.pdf') && (
                    <PDFViewer pdf url={license.file_url} />
                  )}
                  {license?.file_url && !license.file_url.includes('.pdf') && (
                    <img src={license?.file_url} style={{ width: '100%' }} />
                  )}
                </Grid>
              </Grid>
            );
          })}
      {licenses.length === 0 && (
        <>
          <Alert severity="error">
            <AlertTitle>No Lotto License Associated</AlertTitle>
            All paid Sweeps must have a lotto license submitted to sweeps before
            going live.
          </Alert>
        </>
      )}
    </Grid>
  );
}

export default IndividualLicenses;
