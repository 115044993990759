import React from 'react';
import Field from '../../Form/Fields/Field';

import CompetitionsField from '../../Form/Fields/CompetitionsField';
import { WYSIWYG } from '../../Form/Fields/WYSIWYG';

function Form(props) {
  const { errors, handleChange, title, body, competition_id } = props;

  return (
    <>
      <Field
        label="Title"
        errors={errors}
        handleChange={handleChange}
        name="title"
        value={title}
      />
      <hr />
      <WYSIWYG onChange={handleChange} name="body" value={body} />
      <hr />
      <CompetitionsField
        value={competition_id}
        errors={errors}
        handleChange={handleChange}
      />
      <hr />
      <input
        label="Upload files"
        type="file"
        onChange={handleChange}
        name="file"
      />
      <hr />
    </>
  );
}

export default Form;
