/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import { fetchPosts } from '../../Helpers/BlogHelper';
import { Textfit } from 'react-textfit';
import { Link } from 'react-router-dom';

function SmallPostsList(props) {
  const { competitionId } = props;
  const [state, setState] = React.useState({
    posts: [],
  });

  useEffect(() => {
    fetchPosts(updateState);
  }, []);

  function updateState(response) {
    if (response.data) {
      setState({
        ...state,
        posts: response.data['posts'],
      });
    } else if (response.response) {
      setState({
        ...state,
        posts: [],
      });
    }
  }

  const postCards = state.posts
    .sort(function (a, b) {
      return new Date(b.date_created) - new Date(a.date_created);
    })
    .filter((post) => !competitionId || post.competition.id === competitionId)
    .map((post) => (
      <>
        <li className="w3-bar">
          <img
            src={post?.image_url}
            className="w3-bar-item w3-circle"
            style={{ maxWidth: '60px', aspectRatio: '3/2' }}
          />
          <div className="w3-bar-item">
            <Textfit
              mode="single"
              max={14}
              style={{ lineHeight: '23px' }}
              className="w3-text-sviolet sweeps-font-medium"
            >
              <Link to={'/' + post.id + '/post'}>{post.title}</Link>
            </Textfit>
          </div>
        </li>
      </>
    ));

  return (
    postCards.length > 0 && (
      <div
        className="w3-padding"
        style={{ maxHeight: '500px', overflow: 'scroll' }}
      >
        <h3 className={`w3-large sweeps-font-bold w3-text-teal`}>
          Related Posts
        </h3>
        <div className="w3-row-padding w3-row">
          {postCards.length > 0 && postCards}
        </div>
      </div>
    )
  );
}

export default SmallPostsList;
