import axios from 'axios';
import { uploadFile } from './S3Helper';

const TYPES = [
  {
    id: 'golf',
    name: 'Golf',
  },
  {
    id: 'rugby',
    name: 'Rugby',
  },
  {
    id: 'horse_auction',
    name: 'Horse Auction',
  },
];

export function fetchCompetitions(callback) {
  axios
    .get(process.env.REACT_APP_API_URL + '/competitions')
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {
      callback(error);
    });
}

export function createCompetition(user, competition, callback) {
  axios
    .post(
      process.env.REACT_APP_API_URL + '/competitions',
      {
        competition: {
          user_id: user.userId,
          name: competition.name,
          sport: competition.sport,
          format: competition.format,
          start_date: competition.start_date,
          end_date: competition.end_date,
          entry_open_date: competition.entry_open_date,
          entry_close_date: competition.entry_close_date,
          last_tee_time: competition.last_tee_time,
          tiebreaker_question: competition.tiebreaker_question,
          makeCut: competition.makeCut || '',
          url: competition.url,
          file_name: competition.file?.name,
          config_file: competition.configFile?.name,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    )
    .then(function (response) {
      uploadFile(
        response,
        {
          file_name: competition.file,
          config_file: competition.configFile,
        },
        callback,
      );
    })
    .catch(function (error) {
      callback(error);
    });
}

export function editCompetition(user, competition, callback) {
  axios
    .post(
      process.env.REACT_APP_API_URL + '/competitions/edit',
      {
        competition: {
          id: competition.id,
          name: competition.name,
          sport: competition.sport,
          format: competition.format,
          start_date: competition.start_date,
          end_date: competition.end_date,
          entry_open_date: competition.entry_open_date,
          entry_close_date: competition.entry_close_date,
          last_tee_time: competition.last_tee_time,
          makeCut: competition.makeCut,
          tiebreaker_question: competition.tiebreaker_question,
          url: competition.url,
          file_name: competition?.file?.name ? competition.file.name : '',
          config_file: competition.configFile?.name
            ? competition.configFile.name
            : '',
        },
      },
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    )
    .then(function (response) {
      uploadFile(
        response,
        {
          file_name: competition.file,
          config_file: competition.configFile,
        },
        callback,
      );
    })
    .catch(function (error) {
      callback(error);
    });
}

export function getCompetitionFromCompId(competitions, comp_id) {
  let competition;
  competitions.forEach((comp) => {
    if (comp.id === comp_id) {
      competition = comp;
    }
  });
  return competition;
}

export function isCompetitionEndDateInFuture(competition) {
  return new Date(competition.end_date) > Date.now();
}

export const getTypes = () => {
  return TYPES;
};

export const getTypeById = (id) => {
  return TYPES.filter((type) => type.id === id)[0];
};
