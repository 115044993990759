import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getOrganisations,
  getFetchingStatus,
  getLastUpdated,
  fetchOrganisations,
} from './organisations/myOrganisationsSlice';

export const useMyOrganisations = (props) => {
  const { user } = props;
  const organisations = useSelector(getOrganisations);
  const fetchingStatus = useSelector(getFetchingStatus);
  const lastUpdated = useSelector(getLastUpdated);

  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchingStatus === 'idle' && user) {
      dispatch(fetchOrganisations(user));
    }
  }, [fetchingStatus, dispatch, user]);

  const getOrganisationsArray = () => {
    const orgsArray = [];
    Object.keys(organisations).forEach((orgId) => {
      orgsArray.push(organisations[orgId]);
    });
    return orgsArray;
  };

  return {
    fetchingStatus,
    getOrganisationsArray,
    lastUpdated,
  };
};
