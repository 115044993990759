import React, { useEffect, useState } from 'react';

import Name from '../Shared/Name';
import Supporting from '../Shared/Supporting';
import OwnSweep from '../Shared/OwnSweep';
import Card from '../../Layouts/Card';
import SmallPostsList from '../../Blog/SmallPostsList';
import { fetchLeaderboard } from '../../../Helpers/LeaderboardHelper';
import LeaderboardTable from '../Shared/LeaderboardTable';
import ExpandedRow from './ExpandedRow';
import { getMatchesDataForSweep } from '../../../Helpers/RugbyMatchesHelper';
import PrizeWrapper from '../../Prizes/Wrapper';

function RugbyLeaderboard(props) {
  const { sweep, user } = props;

  const [results, setResults] = useState([]);
  const [expandedMatches, setExpandedMatches] = useState([]);

  useEffect(() => {
    if (sweep) {
      fetchLeaderboard(sweep.id, updateResults);
      getMatchesDataForSweep(sweep, setExpandedMatches);
    }
  }, [sweep]);

  function updateResults(response) {
    if (response.data) {
      setResults(response.data.data);
    }
  }

  const getColumns = () => {
    return [
      {
        name: '',
        cell: () => <i className="fa fa-angle-down"></i>,
        width: '30px',
      },
      {
        name: '',
        selector: (row, index) => index + 1,
        grow: 0,
        width: '65px',
      },
      {
        name: 'Name',
        cell: (row) => <Name row={row} />,
      },
      {
        name: 'Score',
        selector: (row) => (
          <span className="sweeps-font-bold">{row.total_score}</span>
        ),
        center: true,
        grow: 0,
      },
      {
        name: 'Correct Team Picks',
        cell: (row) => row.correct_teams,
        center: true,
      },
      {
        name: 'Correct Score Picks',
        cell: (row) => row.correct_scores,
        center: true,
      },
      {
        name: 'Tries by Ireland',
        selector: (row) => row.tie_breaker,
        center: true,
        grow: 1,
      },
    ];
  };

  if (results.length > 0) {
    results[0].defaultExpanded = true;
  }

  return (
    <>
      <div className="w3-row-padding">
        <div className="w3-twothird">
          <div className="w3-container w3-card w3-margin-top w3-white">
            <LeaderboardTable
              user={user}
              updatedScores={new Date()}
              sortedEntries={results}
              loading={results.length === 0}
              columns={getColumns()}
              expandedRow={ExpandedRow}
              expandableRowsComponentProps={{
                expandedMatches: expandedMatches,
              }}
              expandableRowExpanded={(row) => row.defaultExpanded}
            />
          </div>
        </div>
        <div className="w3-third">
          <div className="w3-container w3-text-grey">
            <Card>
              <Supporting sweep={sweep} entryCount={sweep?.entry_count} />
            </Card>
            <Card>
              <div className="w3-padding">
                <PrizeWrapper sweep={sweep} />
              </div>
            </Card>
            <Card>
              <SmallPostsList competitionId={sweep?.competition?.id} />
            </Card>
            <Card>
              <div>
                <OwnSweep />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default RugbyLeaderboard;
