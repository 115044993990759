import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}`;
}

export default function SetDistanceSlider(props) {
  const {
    distance,
    max,
    onChange,
    disabled,
    initialLowerBound,
    initialUpperBound,
  } = props;

  const [value, setValue] = React.useState([
    initialLowerBound,
    initialUpperBound,
  ]);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0 && newValue[0] <= max - distance) {
      setValue([Math.min(newValue[0], max - distance), newValue[0] + distance]);
      onChange([Math.min(newValue[0], max - distance), newValue[0] + distance]);
    } else if (activeThumb === 1 && newValue[1] >= 1 + distance) {
      setValue([newValue[1] - distance, Math.max(newValue[1], distance)]);
      onChange([newValue[1] - distance, Math.max(newValue[1], distance)]);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Slider
        disabled={disabled}
        getAriaLabel={() => 'Points Range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={1}
        max={max}
        disableSwap
      />
    </Box>
  );
}
