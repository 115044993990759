import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const myOrganisationsSlice = createSlice({
  name: 'myOrganisations',
  initialState: {
    organisations: {},
    fetchingStatus: 'idle',
    lastUpdated: '',
  },
  reducers: {
    myOrganisationsCleared(state) {
      state.organisations = {};
      state.fetchingStatus = 'idle';
      state.lastUpdated = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrganisations.pending, (state) => {
        state.fetchingStatus = 'loading';
      })
      .addCase(fetchOrganisations.fulfilled, (state, action) => {
        state.fetchingStatus = 'succeeded';
        action.payload.organisations.forEach((org) => {
          state.organisations[org.id] = org;
        });
        state.lastUpdated = Date.now();
      })
      .addCase(fetchOrganisations.rejected, (state, action) => {
        state.fetchingStatus = 'failed';
        state.error = action.error.message;
      });
  },
});

export const fetchOrganisations = createAsyncThunk(
  'myOrganisations/fetchOrganisations',
  async (user) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + '/account/organisations',
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    );
    return response.data;
  },
);

export const getOrganisations = (state) => state.myOrganisations.organisations;

export const getOrganisationWithId = (state, organisationId) => {
  const organisationsObj = state.myOrganisations.organisations;
  return organisationsObj[organisationId];
};

export const getFetchingStatus = (state) =>
  state.myOrganisations.fetchingStatus;
export const getLastUpdated = (state) => state.myOrganisations.lastUpdated;

export default myOrganisationsSlice.reducer;
export const { myOrganisationsCleared } = myOrganisationsSlice.actions;
