import { useState, useEffect, useRef } from 'react';
import {
  fetchCompetitions,
  getCompetitionFromCompId,
} from '../../../Helpers/CompetitionHelper';
import {
  getPlayersDataForCompetition,
  replacePlayer,
} from '../../../Helpers/PlayerHelper';
import CompetitionsField from '../../Form/Fields/CompetitionsField';
import Field from '../../Form/Fields/Field';
import PlayersField from '../../Form/Fields/PlayersField';
import Error from '../../Form/Error';
import SuccessPanel from '../../Form/SuccessPanel';

export const ReplacePlayer = (props) => {
  const { user } = props;
  const mountedRef = useRef();
  const [state, setState] = useState({
    competition_id: '',
    player_to_replace: '',
    replacing_player: '',
    group: 0,
    competitions: [],
    competition: {},
    players: [],
    errors: {},
    success: false,
  });

  useEffect(() => {
    if (mountedRef) {
      fetchCompetitions(updateCompetitions);
    }
  }, [state.competition_id]);

  useEffect(() => {
    if (mountedRef) {
      const competition = getCompetitionFromCompId(
        state.competitions,
        state.competition_id,
      );
      setState({
        ...state,
        competition: competition,
      });
    }
  }, [state.competitions]);

  useEffect(() => {
    if (mountedRef) {
      getPlayersDataForCompetition(state.competition, updatePlayersData);
    }
  }, [state.competition]);

  const updatePlayersData = (playersData) => {
    setState({
      ...state,
      players: playersData,
    });
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const updateCompetitions = (response) => {
    if (response.data) {
      setState({
        ...state,
        competitions: response.data['competitions'],
      });
    }
  };

  const handleReplacePlayerResponse = (response) => {
    if (response?.status === 200) {
      setState({
        ...state,
        success: true,
      });
    } else {
      setState({
        ...state,
        form: {
          messages: ['Failed to batch update'],
        },
      });
    }
  };

  /*
  - Pick a comp
  - Load the players data 
  - Pick a player 
  - Pick a replacement player
 */
  return (
    <>
      {state.errors && state.errors['form'] && (
        <Error error={state.errors['form']} />
      )}
      {state.success && (
        <>
          <SuccessPanel message={'Successfully replaced player'} />
        </>
      )}
      <CompetitionsField
        errors={state.errors}
        handleChange={handleChange}
        value={state.competition_id}
      />

      {state.competition && (
        <>
          <Field
            label="group"
            errors={state.errors}
            handleChange={handleChange}
            name="group"
            value={state.group}
          />
        </>
      )}
      {state.competition && state.group > 0 && (
        <>
          <label>Replace:</label>
          <br />
          <PlayersField
            errors={state.errors}
            handleChange={handleChange}
            name="player_to_replace"
            value={state.player_to_replace}
            players={state.players.filter(
              (player) => player.group === parseInt(state.group),
            )}
          />

          <label>With:</label>
          <br />
          <PlayersField
            errors={state.errors}
            handleChange={handleChange}
            name="replacing_player"
            value={state.replacing_player}
            players={state.players.filter(
              (player) => player.group === parseInt(state.group),
            )}
          />
        </>
      )}
      {state.competition &&
        state.group > 0 &&
        state.player_to_replace &&
        state.replacing_player && (
          <>
            <button
              onClick={() =>
                replacePlayer(user, state, handleReplacePlayerResponse)
              }
              className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
            >
              Replace
            </button>
          </>
        )}
    </>
  );
};
