/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { getPlayerFromKey } from '../../Helpers/PlayerHelper';
import Player from './Player';
import PlayerSkill from '../SweepsSignup/Golf/PlayerSkill';

function PlayerDetails(props) {
  const { allPlayersData, selectedPlayerId, mobile } = props;
  const player = getPlayerFromKey(selectedPlayerId, allPlayersData);
  return (
    <React.Fragment>
      <div className="w3-padding">
        <label className="w3-text-teal">
          <b>Player Details</b>
        </label>
        {selectedPlayerId && (
          <>
            {!mobile && <Player player={player} />}

            <PlayerSkill player={player} />
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default PlayerDetails;
