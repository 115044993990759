/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { getPlayerFromKey } from '../../../Helpers/PlayerHelper';
import Error from '../Error';

function PlayersField(props) {
  const { errors, handleChange, value, name, players } = props;

  const input = (
    <select
      defaultValue={''}
      onChange={handleChange}
      className="w3-select"
      name={name}
    >
      {value && value !== 'none' && (
        <option value={value} selected>
          {getPlayerFromKey(value, players)?.name}
        </option>
      )}
      <option value="none">Select Player</option>
      {players?.map((player) => {
        return (
          <option key={player.id} value={player.id}>
            {player.name}
          </option>
        );
      })}
    </select>
  );

  return (
    <React.Fragment>
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Select Player</b>
      </label>
      {errors && errors[name] && <Error error={errors[name]} />}
      {input}
    </React.Fragment>
  );
}

export default PlayersField;
