import React, { useEffect } from 'react';
import AcceptingEntries from './AcceptingEntries';
import EqualHeightColumns from '../Layouts/EqualHeightColumns';
import OwnSweep from '../Leaderboard/Shared/OwnSweep';
import SweepsGallery from '../Sweeps/Gallery';
import AboutUs from './AboutUs';
import BlogGallery from '../Blog/Gallery';
import SalesLanding from './SalesLanding';
import TestimonialsWrapper from './TestimonialsWrapper';

function Index() {
  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
  }, []);

  return (
    <>
      <div className="w3-row-padding w3-margin-bottom w3-margin-top">
        <div className="w3-container w3-margin-top">
          <SalesLanding />
        </div>
      </div>
      <TestimonialsWrapper />
      <div className="w3-row-padding w3-margin-bottom w3-margin-top">
        <div className="w3-container w3-margin-top">
          <SweepsGallery future={true} />
        </div>
      </div>

      <div className="w3-row-padding w3-margin-bottom w3-margin-top">
        <div className="w3-container w3-margin-top">
          <BlogGallery title={'Latest Posts'} limit={3} showLink={true} />
        </div>
      </div>

      <div className="w3-row-padding w3-margin-bottom w3-margin-top">
        <div className="w3-container w3-margin-top">
          <div className="w3-card w3-white">
            <AboutUs />
          </div>
        </div>
      </div>

      <div className="w3-row-padding w3-margin-bottom w3-margin-top">
        <div className="w3-container w3-margin-top">
          <div className="w3-card w3-white">
            <AcceptingEntries />
          </div>
        </div>
      </div>

      <EqualHeightColumns>
        {[
          <div
            key="ownSweep"
            className="w3-container h-100 w3-card w3-margin w3-teal"
            style={{ padding: '20px' }}
          >
            <OwnSweep />
          </div>,
          <div
            key="amountRaised"
            className="w3-container h-100 w3-card w3-margin w3-white"
          >
            <h3 className="w3-large w3-center sweeps-font-bold w3-text-teal">
              Amount fundraised on Sweeps
            </h3>
            <p className="sweeps-font-bold  w3-center sweeps-light-green-text pointer w3-xxlarge ">
              €10761
            </p>
          </div>,
        ]}
      </EqualHeightColumns>
    </>
  );
}

export default Index;
