import React, { useState } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
} from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import EmailPicker from './EmailPicker';
import { editOrganisation } from '../../features/organisations/organisationsSlice';
import WYSIWYGDataTable from './WYSIWYGDataTable';

function OrganisationDataTable(props) {
  const { user, organisations } = props;

  const [rowModesModel, setRowModesModel] = useState({});
  const dispatch = useDispatch();

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      await dispatch(
        editOrganisation({
          id: updatedRow.id,
          name: updatedRow.name,
          primarySport: updatedRow.primarySport,
          location: updatedRow.location,
          admins: updatedRow.admins,
          link: updatedRow.link,
          bio: updatedRow.bio,
          user: user,
        }),
      ).unwrap();
    } catch (err) {
      toast.error('Failed to save the organisation');
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  function getColumns() {
    return [
      { field: 'name', headerName: 'Organisation', flex: 0.3, editable: true },
      { field: 'primarySport', headerName: 'Sport', flex: 0.1, editable: true },
      { field: 'location', headerName: 'Location', flex: 0.2, editable: true },
      { field: 'link', headerName: 'Link', flex: 0.2, editable: true },
      {
        field: 'bio',
        headerName: 'Bio',
        flex: 0.2,
        editable: true,
        renderEditCell: (params) => <WYSIWYGDataTable {...params} />,
      },
      {
        field: 'admins',
        headerName: 'Admins',
        flex: 0.2,
        editable: true,
        renderEditCell: (params) => <EmailPicker {...params} />,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 0.4,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key="save"
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                key="cancel"
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key="edit"
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  }

  return (
    <DataGrid
      getRowHeight={() => 'auto'}
      rows={organisations}
      columns={getColumns()}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 100 },
        },
      }}
      pageSizeOptions={[5, 10]}
    />
  );
}

export default OrganisationDataTable;
