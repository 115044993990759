/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function Modal(props) {
  const { child, id } = props;

  return (
    <>
      <div id={id} className="w3-modal" style={{ color: 'black' }}>
        <div className="w3-modal-content">
          <div className="w3-container w3-medium">
            <span
              onClick={() => {
                document.getElementById(`${id}`).style.display = 'none';
              }}
              className="pointer w3-button w3-display-topright times"
            >
              &times;
            </span>
          </div>
          {child}
        </div>
      </div>
    </>
  );
}

export default Modal;
