import axios from 'axios';
import { uploadFile } from './S3Helper';

export function createPost(user, post, callback) {
  axios
    .post(
      process.env.REACT_APP_API_URL + '/posts',
      {
        post: {
          user_id: user.userId,
          title: post.title,
          body: post.body,
          competition_id: post.competition_id,
          file_name: post?.file?.name ? post.file.name : '',
        },
      },
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    )
    .then(function (response) {
      uploadFile(
        response,
        {
          file_name: post.file,
        },
        callback,
      );
    })
    .catch(function (error) {
      callback(false);
    });
}

export function editPost(user, post, callback) {
  axios
    .post(
      process.env.REACT_APP_API_URL + '/posts/edit',
      {
        post: {
          id: post.id,
          user_id: user.userId,
          title: post.title,
          body: post.body,
          competition_id: post.competition_id,
          file_name: post?.file?.name ? post.file.name : '',
        },
      },
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    )
    .then(function (response) {
      uploadFile(
        response,
        {
          file_name: post.file,
        },
        callback,
      );
    })
    .catch(function (error) {
      callback(error);
    });
}

export function fetchPosts(callback) {
  axios
    .get(process.env.REACT_APP_API_URL + '/posts')
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {
      callback(error);
    });
}

export function fetchPost(post_id, callback) {
  axios
    .get(process.env.REACT_APP_API_URL + '/posts?post_id=' + post_id, {})
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {
      callback(error);
    });
}
