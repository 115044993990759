import axios from 'axios';

export function getUserFromLocalStorage() {
  const localStorageUser = localStorage.getItem('user');
  if (localStorageUser) {
    return JSON.parse(localStorageUser);
  }
}

export const fetchUsers = (user, callback) => {
  axios
    .get(process.env.REACT_APP_API_URL + '/users', {
      headers: {
        Authorization: `Bearer ${user?.id_token}`,
      },
    })
    .then(function (response) {
      callback(response.data['body']);
    })
    .catch(function (error) {
      callback([]);
    });
};
