/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import HorseOption from './HorseOption';
import { getOptionsByStallion } from '../../../Helpers/HorseAuctionHelper';
import { toast } from 'react-toastify';

function Group(props) {
  const {
    options,
    groupTitle,
    handleOptionPicked,
    handleOptionUnpicked,
    previousSlide,
    hidePrevious,
    nextSlide,
    picks,
  } = props;

  const hasPickInGroup = () => {
    let containsAny = false;
    if (picks) {
      const pickKeys = Object.keys(picks);
      const optionKeys = options.map((option) => option.Lot.toString());

      for (const key of pickKeys) {
        if (optionKeys.includes(key)) {
          containsAny = true;
          break;
        }
      }
    }

    return containsAny;
  };

  const handleOptionPickedInGroup = (lot, sex, stallion, dam, consignor) => {
    if (hasPickInGroup()) {
      toast.error('Only allowed one pick per group', {
        toastId: 'one_per_group',
      });
    } else {
      handleOptionPicked(lot, sex, stallion, dam, consignor);
    }
  };

  const progressButton =
    options && hasPickInGroup() ? (
      <button
        data-testid="next-button"
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
        onClick={() => nextSlide()}
      >
        Next
      </button>
    ) : (
      <button
        data-testid="next-button-disabled"
        disabled
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
      >
        Next
      </button>
    );

  const optionsByStallion = getOptionsByStallion(options);

  const isOptionSelectable = (picks, option) => {
    let isSelectable = true;
    if (picks) {
      Object.keys(picks).forEach((pick) => {
        if (picks[pick].stallion === option.Stallion) {
          isSelectable = false;
        }
      });
    }
    return isSelectable;
  };

  return (
    <>
      <div className="w3-row-padding" data-testid="horse_auction_group">
        <div className="w3-half">
          <h4 className="sweeps-font-medium w3-text-teal">{groupTitle}</h4>
          <p>Select 1 and continue</p>
        </div>
        <div className="w3-half">
          <div style={{ float: 'right' }}>
            {!hidePrevious && (
              <button
                onClick={() => previousSlide()}
                className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
              >
                Back
              </button>
            )}
            {progressButton}
          </div>
        </div>
      </div>
      <div className="w3-row">
        {Object.keys(optionsByStallion).map((stallion) => (
          <div className="w3-row w3-padding" key={stallion}>
            <h5 className="sweeps-font-medium w3-text-teal">
              {optionsByStallion[stallion][0].Stallion}
            </h5>
            {optionsByStallion[stallion].map((option) => (
              <HorseOption
                key={option.Lot}
                active={
                  picks && Object.keys(picks).includes(option.Lot.toString())
                }
                option={option}
                handleOptionPicked={handleOptionPickedInGroup}
                handleOptionUnpicked={handleOptionUnpicked}
                selectable={isOptionSelectable(picks, option)}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="w3-row-padding">
        {!hidePrevious && (
          <button
            onClick={() => previousSlide()}
            className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
          >
            Back
          </button>
        )}
        {progressButton}
      </div>
    </>
  );
}

export default Group;
