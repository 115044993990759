import axios from 'axios';
import importedPlayersData from '../Data/masters2023-players.json';
import importedPlayersNameMappings from '../Data/playersEspnMapping.json';

export function getPlayerFromKey(playerKey, data) {
  return data && data.filter((player) => player.id === playerKey)[0];
}

export function getEspnNameForPlayerId(playerId) {
  return playerId?.includes('_')
    ? importedPlayersNameMappings[playerId]?.espn
    : playerId;
}

export const getPlayersDataForSweep = (sweep, callback) => {
  if (sweep?.competition?.config_file) {
    fetchPlayersData(sweep.competition, callback);
  } else {
    // Fallback to the masters original players
    callback(importedPlayersData);
  }
};

export const getPlayersDataForCompetition = (competition, callback) => {
  if (competition?.config_file) {
    fetchPlayersData(competition, callback);
  } else {
    callback(importedPlayersData);
  }
};

const fetchPlayersData = (competition, callback) => {
  axios
    .get(competition.config_file)
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export function getGroups(playersData) {
  let responseData = [
    { group: 'group_1', players: [] },
    { group: 'group_2', players: [] },
    { group: 'group_3', players: [] },
    { group: 'group_4', players: [] },
    { group: 'group_5', players: [] },
  ];
  playersData.map((player) => {
    responseData[player.group - 1]?.players.push(
      getEspnNameForPlayerId(player.id),
    );
  });
  return responseData;
}

export const replacePlayer = (user, replacePlayerData, callback) => {
  axios
    .post(
      process.env.REACT_APP_API_URL + '/competition/replace',
      {
        replacement: {
          competition_id: replacePlayerData.competition_id,
          group: replacePlayerData.group,
          player_to_replace: replacePlayerData.player_to_replace,
          replacing_player: replacePlayerData.replacing_player,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    )
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {
      callback(error);
    });
};
