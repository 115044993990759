/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HorseOption(props) {
  const {
    option,
    handleOptionPicked,
    handleOptionUnpicked,
    active,
    selectable,
  } = props;

  const calculateBackground = () => {
    if (active) {
      return 'w3-teal';
    }
    if (!selectable) {
      return 'w3-light-gray';
    }
  };

  const background = calculateBackground();
  const headingColor = active ? 'w3-text-white' : 'w3-text-teal';

  const handleClicked = () => {
    if (active) {
      handleOptionUnpicked(option.Lot);
    } else if (selectable) {
      handleOptionPicked(
        option.Lot,
        option.Sex,
        option.Stallion,
        option.Dam,
        option.Consignor,
      );
    } else {
      toast.error('You can only pick one yearling from each stallion.', {
        toastId: 'stallion_picked',
      });
    }
  };

  return (
    <>
      <div className={`w3-quarter`} onClick={() => handleClicked()}>
        <div
          className={
            'w3-container w3-padding w3-margin pointer match-border ' +
            background
          }
        >
          {active && (
            <span
              className="pointer w3-button w3-text-white"
              data-testid="close-button"
              style={{ float: 'right', position: 'absolute' }}
            >
              &times;
            </span>
          )}

          <div className="w3-center">
            <h6 className={'sweeps-font-medium w3-center ' + headingColor}>
              {option.Dam}
            </h6>
            <ul
              style={{ listStyleType: 'none', paddingInlineStart: '0px' }}
              data-testid="yearling-information"
            >
              <li>Lot: {option.Lot}</li>
              <li>Sex: {option.Sex}</li>
              <li>{option.Consignor}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default HorseOption;
