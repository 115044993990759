import React from 'react';
import CreateOrganisation from './CreateOrganisation';
import { CardContent, CardHeader, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import OrganisationDataTable from './OrganisationsDataTable';

function OrganisationsView(props) {
  const { user, organisations } = props;

  return (
    <Grid container spacing={1} padding={2}>
      <Grid item sm={12}>
        <Card>
          <CardHeader
            className="w3-text-teal sweeps-font-bold"
            title="Create Organisation"
          />
          <CardContent sx={{ padding: '4px' }}>
            <CreateOrganisation user={user} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        <Card>
          <CardHeader
            className="w3-text-teal sweeps-font-bold"
            title="Organisations"
          />
          <CardContent>
            <OrganisationDataTable user={user} organisations={organisations} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default OrganisationsView;
