import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLoadSweeps } from '../../../features/useLoadSweeps';

export default function SelectSweep(props) {
  const { id, handleChange, organisationId } = props;

  const { getSweepsArray, getSweepsForOrg } = useLoadSweeps();
  let sweeps = organisationId
    ? getSweepsForOrg(organisationId)
    : getSweepsArray();

  const handleLocalChange = (event) => {
    handleChange(event);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Sweep</InputLabel>
        <Select
          labelId="sweep-select-label"
          id="sweep-simple-select"
          value={id}
          defaultValue={id}
          label="Sweep"
          name="sweepId"
          onChange={handleLocalChange}
        >
          {sweeps.map((sweep) => {
            return (
              <MenuItem key={sweep.id} value={sweep.id}>
                {sweep?.organisation?.name} - {sweep?.competition?.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
