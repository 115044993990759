import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const referralsSwepSlice = createSlice({
  name: 'referralsForSweep',
  initialState: {
    sweeps: {},
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReferralCodes.pending, (state, action) => {
        state.sweeps[action.meta.arg.sweepId] = {
          status: 'loading',
          codes: [],
        };
        state.sweeps[action.meta.arg.sweepId] = {
          status: 'loading',
          codes: [],
        };
      })
      .addCase(fetchReferralCodes.fulfilled, (state, action) => {
        state.sweeps[action.meta.arg.sweepId] = {
          status: 'suceeded',
          codes: action.payload.codes,
        };
      })
      .addCase(fetchReferralCodes.rejected, (state, action) => {
        state.sweeps[action.meta.arg.sweepId] = {
          status: 'failed',
          codes: [],
        };
      });
  },
});

export const fetchReferralCodes = createAsyncThunk(
  'referrals/sweep/codes',
  async ({ user, sweepId }) => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 10000,
      headers: { Authorization: 'Bearer ' + user.id_token },
    });

    const response = await instance.get(
      '/referral/sweep/codes?sweep_id=' + sweepId,
    );
    return response.data;
  },
);

export const getCodes = (state, sweepId) => {
  return state.referralsForSweep.sweeps[sweepId]?.codes;
};

export const getCodeStatus = (state, sweepId) => {
  return state.referralsForSweep.sweeps[sweepId]?.status;
};

export default referralsSwepSlice.reducer;
