import React from 'react';
import Field from '../Form/Fields/Field';
import 'react-multi-email/dist/style.css';
import { InputLabel } from '@mui/material';
import EmailPicker from './EmailPicker';
import { WYSIWYG } from '../Form/Fields/WYSIWYG';

function Form(props) {
  const {
    errors,
    handleChange,
    organisationName,
    primarySport,
    emails,
    location,
    link,
    bio,
    setEmails,
  } = props;

  return (
    <>
      <Field
        label="Name"
        errors={errors}
        handleChange={handleChange}
        name="organisationName"
        value={organisationName}
      />
      <Field
        label="Primary Sport"
        errors={errors}
        handleChange={handleChange}
        name="primarySport"
        value={primarySport}
      />
      <Field
        label="Location"
        errors={errors}
        handleChange={handleChange}
        name="location"
        value={location}
      />
      <Field
        label="Link"
        errors={errors}
        handleChange={handleChange}
        name="link"
        value={link}
      />
      <label className={`w3-text-teal`}>
        <b>Bio</b>
      </label>
      <WYSIWYG onChange={handleChange} name="bio" value={bio} />
      <InputLabel className="w3-text-teal">Admins Emails:</InputLabel>
      <EmailPicker value={emails} setEmails={setEmails} />
    </>
  );
}

export default Form;
