import React from 'react';
import EditEntry from './EditEntry';

function HorseAuctionEntry(props) {
  const { entry, sweep, refreshEntries } = props;

  return (
    <EditEntry refreshEntries={refreshEntries} entry={entry} sweep={sweep} />
  );
}

export default HorseAuctionEntry;
