/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getOptions } from '../../../Helpers/HorseAuctionHelper';
import { toast } from 'react-toastify';

const apiUrl = process.env.REACT_APP_API_URL;

export const useHorseAuctionForm = ({ sweep, setNumberOfGroups }) => {
  const [state, setState] = useState({
    options: [],
    picks: {},
  });

  useEffect(() => {
    if (sweep && sweep.competition.sport === 'horse_auction') {
      getOptions(sweep, updateOptions);
    }
  }, [sweep]);

  const updateOptions = (optionsData) => {
    setNumberOfGroups(5);
    setState({
      ...state,
      options: optionsData,
    });
  };

  const onSubmit = (
    userId,
    email,
    nickName,
    sweep,
    referralCode,
    paymentId,
    freeEntry,
    tieBreaker,
    callbackSuccess,
    callbackError,
  ) => {
    axios
      .post(apiUrl + '/signupEntry', {
        entry: {
          user_id: userId,
          email: email,
          nickName: nickName,
          picks: state.picks,
          sweep_id: sweep.id,
          referral_code: referralCode,
          free_entry: freeEntry || false,
          payment_intent_id: paymentId,
          tie_breaker: tieBreaker,
        },
      })
      .then(function (response) {
        if (response.data.message.includes('Successfully created entry')) {
          callbackSuccess(response.data.referral_code);
        }
      })
      .catch(function (error) {
        callbackError(error);
      });
  };

  const getObjectShape = (tieBreaker, nickName) => {
    return {
      picks: state.picks,
      tie_breaker: tieBreaker,
      nickName: nickName,
    };
  };

  const handleOptionPicked = (optionId, sex, stallion, dam, consignor) => {
    if (Object.keys(state.picks).length < 5) {
      setState({
        ...state,
        picks: {
          ...state.picks,
          [optionId]: {
            sex,
            optionId,
            stallion,
            dam,
            consignor,
          },
        },
      });
    } else {
      toast.error("You've already selected 5 yearlings.", {
        toastId: 'already_selected_5',
      });
    }
  };

  const handleOptionUnpicked = (optionId) => {
    const picks = state.picks;
    delete picks[optionId];
    setState({
      ...state,
      picks: picks,
    });
  };

  return {
    handlers: {
      onSubmit,
      getObjectShape,
      handleOptionPicked,
      handleOptionUnpicked,
    },
    context: {
      options: state.options,
      picks: state.picks,
    },
  };
};
