import axios from 'axios';

export const fetchLeaderboard = (sweepId, callback) => {
  if (sweepId) {
    axios
      .get(process.env.REACT_APP_API_URL + '/leaderboard?sweep_id=' + sweepId)
      .then(function (response) {
        callback(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
};

export const fetchEntries = (sweepId, callback) => {
  if (sweepId) {
    axios
      .get(process.env.REACT_APP_API_URL + '/entries?sweep_id=' + sweepId)
      .then(function (response) {
        callback(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
};
