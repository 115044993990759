import React from 'react';
import PlayerAvatar from '../../Golfers/PlayerAvatar';

const ExpandedRow = (props) => {
  const { data, playersData } = props;
  return (
    <>
      <div className="w3-row" style={{ maxWidth: '100vw' }}>
        <div className="w3-third">
          <h2 className="w3-center sweeps-font-medium w3-text-teal">
            {data?.postion + 1}{' '}
          </h2>
          <p className="w3-center sweeps-font-medium">POSITION</p>
        </div>
        <div className="w3-third">
          <h2 className="w3-center sweeps-font-medium w3-text-teal">
            {data?.visual_score}{' '}
          </h2>
          <p className="w3-center sweeps-font-medium">SCORE</p>
        </div>
        <div className="w3-third">
          <h2 className="w3-center sweeps-font-medium w3-text-teal">
            {data?.tie_breaker?.includes('-') ? '' : '-'} {data.tie_breaker}{' '}
          </h2>
          <p className="w3-center sweeps-font-medium">TIE BREAK</p>
        </div>
      </div>
      <div className="w3-row" style={{ maxWidth: '100vw' }}>
        <div className="w3-col w3-mobile" style={{ width: '20%' }}>
          <PlayerAvatar
            allPlayersData={playersData}
            key={data?.group_1_id}
            id={data.group_1_id}
          />
        </div>
        <div className="w3-col w3-mobile" style={{ width: '20%' }}>
          <PlayerAvatar
            allPlayersData={playersData}
            key={data?.group_2_id}
            id={data.group_2_id}
          />
        </div>
        <div className="w3-col w3-mobile" style={{ width: '20%' }}>
          <PlayerAvatar
            allPlayersData={playersData}
            key={data?.group_3_id}
            id={data.group_3_id}
          />
        </div>
        <div className="w3-col w3-mobile" style={{ width: '20%' }}>
          <PlayerAvatar
            allPlayersData={playersData}
            key={data?.group_4_id}
            id={data.group_4_id}
          />
        </div>
        <div className="w3-col w3-mobile" style={{ width: '20%' }}>
          <PlayerAvatar
            allPlayersData={playersData}
            key={data?.group_5_id}
            id={data.group_5_id}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(ExpandedRow);
