/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Connect from './Connect';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function StripeConnectionContainer(props) {
  const { user, organisation } = props;
  const navigate = useNavigate();

  const [linked, setLinked] = useState(false);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    setLinked(false);
    setPending(true);
    axios
      .get(
        process.env.REACT_APP_API_URL +
          '/stripe/link/status?organisation_id=' +
          organisation?.id,
      )
      .then(function (response) {
        if (response.data.linked) {
          setLinked(true);
        }
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [organisation]);

  function connectAccount() {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/stripe/link?email=${encodeURIComponent(
            user?.email,
          )}&organisation_id=${organisation?.id}`,
      )
      .then(function (response) {
        if (response.data.linked) {
          setLinked(true);
        } else if (response.data.url) {
          window.location.replace(response.data.url);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return pending ? (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Connect {...props} linked={linked} connectAccount={connectAccount} />
  );
}

export default StripeConnectionContainer;
