/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Modal from '../Layouts/Modal';
import Field from '../Form/Fields/Field';
import { Button } from '@mui/material';
import { Stack } from '@mui/material';

const ContactModal = () => {
  const [state, setState] = React.useState({
    name: '',
    organisation: '',
  });

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  return (
    <Modal
      id="contactModal"
      child={
        <Stack spacing={2} direction="column" className="w3-margin">
          <Field
            label="Your Name"
            handleChange={handleChange}
            name="name"
            value={state.name}
          />
          <Field
            label="Organisation"
            handleChange={handleChange}
            name="organisation"
            value={state.organisation}
          />
          <Button
            onClick={() =>
              (window.location = `mailto:ben@thesweeps.io,conor@thesweeps.io
            ?subject=Sweeps for ${state.organisation}!
            &body=Hi Conor, %0D%0A%0D%0AI'm interested in running a sweeps for ${state.organisation}. Can we setup a call to discuss? %0D%0A%0D%0AThanks,  %0D%0A${state.name}`)
            }
            variant="contained"
            className="w3-teal"
          >
            Get in Touch
          </Button>
        </Stack>
      }
    />
  );
};

export { ContactModal };
