/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Textfit } from 'react-textfit';
import DOMPurify from 'dompurify';

function PostCard(props) {
  const { post } = props;

  const navigate = useNavigate();

  const cleanHTML = DOMPurify.sanitize(post?.body, {
    USE_PROFILES: { html: true },
  });
  const matches = [];
  cleanHTML.replace(/<p>(.*?)<\/p>/g, function () {
    //use arguments[0] if you need to keep <p></p> html tags
    matches.push(arguments[1]);
  });

  return (
    <>
      <div className="w3-row w3-margin-top">
        <div className="h-100 w3-card w3-white">
          <div className="w3-third">
            <img
              src={post?.image_url}
              style={{ maxHeight: '300px', maxWidth: '100%' }}
              alt={post.title}
            />
          </div>

          <div className="w3-twothird w3-center">
            <Textfit
              mode="single"
              max={32}
              style={{ lineHeight: '48px' }}
              className="w3-text-sviolet sweeps-font-bold"
            >
              {post.title}
            </Textfit>
            <div
              className="w3-padding"
              dangerouslySetInnerHTML={{
                __html: matches.slice(0, 2).map((paragraph) => paragraph + ' '),
              }}
            ></div>
          </div>
          <button
            onClick={() => {
              navigate(`/${post.id}/post`);
            }}
            className="w3-center w3-button w3-block w3-teal"
          >
            View Post
          </button>
        </div>
      </div>
    </>
  );
}

export default PostCard;
