import React, { useEffect, useState } from 'react';
import Table from '../Layouts/Table';

import { fetchReferralCodes } from '../../Helpers/ReferralsHelper';

function ReferralCodes(props) {
  const { user, sweeps } = props;
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
    fetchReferralCodes(user, setCodes);
  }, []);

  function getColumns(sweeps) {
    return [
      {
        name: 'Email',
        selector: (row) => row.email,
        wrap: true,
      },
      {
        name: 'Sweep',
        selector: (row) => {
          const sweep = sweeps?.filter((sweep) => sweep.id === row.sweep_id)[0];
          return sweep?.organisation?.name + ' - ' + sweep?.competition.name;
        },
        wrap: true,
      },
      {
        name: 'Current Count',
        selector: (row) => row.usage_count,
        wrap: true,
      },
      {
        name: 'Total Usages',
        selector: (row) => row.total_count,
        wrap: true,
      },
    ];
  }

  return (
    <>
      <h3 className="sweeps-font-bold w3-text-teal">Referral Codes</h3>
      <Table data={codes} columns={getColumns(sweeps)} />
    </>
  );
}

export default ReferralCodes;
