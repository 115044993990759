import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const entrantsSlice = createSlice({
  name: 'entrants',
  initialState: {
    sweeps: {},
  },
  reducers: {
    entrantsCleared(state) {
      state.sweeps = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEntrants.pending, (state, action) => {
        state.sweeps[action.meta.arg.sweepId] = {
          status: 'loading',
        };
      })
      .addCase(fetchEntrants.fulfilled, (state, action) => {
        state.sweeps[action.meta.arg.sweepId] = {
          status: 'fulfilled',
          entrants: action.payload.entries,
        };
      })
      .addCase(fetchEntrants.rejected, (state, action) => {
        state.sweeps[action.meta.arg.sweepId] = {
          status: 'failed',
          entrants: [],
        };
      });
  },
});

export const fetchEntrants = createAsyncThunk(
  'sweeps/fetchEntrants',
  async ({ sweepId, user }) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        '/organisation/sweep/entries?sweep_id=' +
        sweepId,
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    );
    return response.data;
  },
);

export const getEntrantsForSweep = (state, sweepId) =>
  state.entrants.sweeps[sweepId]?.entrants;

export const getStatus = (state, sweepId) =>
  state.entrants.sweeps[sweepId]?.status;
export const getLastUpdated = (state, sweepId) =>
  state.entrants.sweeps[sweepId]?.lastUpdated;

export default entrantsSlice.reducer;
export const { entrantsCleared } = entrantsSlice.actions;
