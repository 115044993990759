import axios from 'axios';

export const uploadFile = async (response, localFiles, callback) => {
  const { files } = response.data;
  if (files.length > 0) {
    for (const file of files) {
      const { data, url, name } = file;
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      formData.append('file', localFiles[name]);
      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    callback(response);
  } else {
    callback(response);
  }
};
