import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSweeps,
  getStatus,
  getLastUpdated,
  fetchSweeps,
  getSweepByAlias,
  getSweepWithId,
  getSweepsForOrganisation,
} from './sweeps/sweepsSlice';

export const useLoadSweeps = () => {
  const sweeps = useSelector(getSweeps);
  const sweepsStatus = useSelector(getStatus);
  const lastUpdated = useSelector(getLastUpdated);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      sweepsStatus === 'idle' ||
      (sweepsStatus == 'succeeded' && shouldUpdateSweeps())
    ) {
      dispatch(fetchSweeps());
    }
  }, [sweepsStatus, dispatch]);

  const getSweepsArray = () => {
    const sweepsArray = [];
    Object.keys(sweeps).forEach((sweepId) => {
      sweepsArray.push(sweeps[sweepId]);
    });
    return sweepsArray;
  };

  const shouldUpdateSweeps = () => {
    return lastUpdated < new Date(Date.now() - 120000);
  };

  const getSweep = (alias) => {
    return useSelector((state) => getSweepByAlias(state, alias));
  };

  const getSweepById = (sweepId) => {
    return useSelector((state) => getSweepWithId(state, sweepId));
  };

  const getSweepsForOrg = (organisationId) => {
    return useSelector((state) =>
      getSweepsForOrganisation(state, organisationId),
    );
  };

  return {
    sweepsStatus,
    getSweepsArray,
    getSweep,
    getSweepById,
    getSweepsForOrg,
  };
};
