import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import CardError from './CardError';
import { Oval } from 'react-loader-spinner';

const PaymentForm = (props) => {
  const { submitSelections, paymentIntentId, previousSlide } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    // TODO: UPDATE URL
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
      redirect: 'if_required',
    });

    if (error) {
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      submitSelections(paymentIntentId, false);
    }
  };

  const spinner = loading ? (
    <Oval
      wrapperStyle={{ display: 'inline-block', marginLeft: '10px' }}
      ariaLabel="loading-indicator"
      height={40}
      width={40}
      strokeWidth={5}
      strokeWidthSecondary={1}
      color="teal"
      secondaryColor="white"
    />
  ) : (
    <></>
  );

  return (
    <>
      <CardError error={errorMessage} />
      <PaymentElement />
      {/* <button onClick={() => previousSlide()} className="w3-btn w3-margin-top w3-margin-bottom w3-teal">Back</button> */}
      <button
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        onClick={handleSubmit}
        disabled={!stripe}
      >
        Submit Team
      </button>
      {spinner}
    </>
  );
};

export default PaymentForm;
