/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function SuccessPanel(props) {
  const { message } = props;
  return (
    <React.Fragment>
      {message && (
        <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-green">
          <p className="w3-margin-top">
            <i className={`fa fa-check fa-fw w3-margin-right`} />
            {message}
          </p>
        </div>
      )}
    </React.Fragment>
  );
}

export default SuccessPanel;
