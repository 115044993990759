/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Modal from '../Layouts/Modal';
import EditCompetition from './EditCompetition';

function EditCompetitionModal(props) {
  const { competition, user, refreshCompetitions } = props;
  return (
    <React.Fragment>
      <Modal
        id={`editCompetition-${competition.id}`}
        child={
          <EditCompetition
            user={user}
            competition={competition}
            refreshCompetitions={refreshCompetitions}
          />
        }
      />
    </React.Fragment>
  );
}

export default EditCompetitionModal;
