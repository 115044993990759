import React from 'react';
import { getPrizeForKey } from '../../Helpers/PrizeHelper';

function FiftyFourtyTenFive(props) {
  const { prizeKey } = props;
  const prize = getPrizeForKey(prizeKey);
  return (
    <React.Fragment>
      <h6 className="w3-large sweeps-font-bold w3-text-sviolet">
        Prize Breakdown
      </h6>
      <ul
        style={{
          listStyle: 'none',
          marginBlockStart: '0',
          paddingInlineStart: '0',
        }}
      >
        <li>🥇 = {prize.prizes[0]}%</li>
        <li>🥈 = {prize.prizes[1]}%</li>
        <li>🥉 = {prize.prizes[2]}%</li>
        <li>4th = {prize.prizes[3]}%</li>
        <li>5th = {prize.prizes[4]}%</li>
        <li>Organisation: {prize.organisation}%</li>
        <li>Fees: {prize.sweeps}%</li>
      </ul>
    </React.Fragment>
  );
}

export default FiftyFourtyTenFive;
