import React from 'react';
import { CardContent, CardHeader, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Table from '../../Layouts/Table';

function EntriesView(props) {
  const { totalEntriesCount, allEntries } = props;

  const getEntriesColumns = () => {
    return [
      {
        name: '#',
        cell: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: 'Nick name',
        sortable: true,
        selector: (row) => row.nickName,
      },
      {
        name: 'Email',
        sortable: true,
        selector: (row) => row.email,
      },
      {
        name: 'Comp',
        sortable: true,
        selector: (row) => row?.competition,
      },
      {
        name: 'Org',
        sortable: true,
        selector: (row) => row?.organisation?.name,
      },
      {
        name: 'Date',
        selector: (row) => row.date_created,
        sortable: true,
      },
    ];
  };

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <h3 className="w3-text-teal sweeps-font-bold">Entries</h3>
            <span>Total entry count: {totalEntriesCount}</span> <br />
            <Table data={allEntries} columns={getEntriesColumns()} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default EntriesView;
