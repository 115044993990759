import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUrlAlias } from '../../Helpers/SweepsHelper';
import { getSharingText, isEntriesFinished } from '../../Helpers/MiscHelpers';
import { getReferralCode } from '../../Helpers/ReferralsHelper';
import { CopyReferralCode } from '../Referrals/CopyReferralCode';
import GolfEntry from './Golf/Entry';
import RugbyEntry from './Rugby/Entry';
import HorseAuctionEntry from './HorseAuction/Entry';
import { useLoadSweeps } from '../../features/useLoadSweeps';

function Entry(props) {
  const { user, entry, refreshEntries } = props;

  const navigate = useNavigate();
  const { getSweepById } = useLoadSweeps();
  const sweep = getSweepById(entry.sweepstake);
  const [editVisible, setEditVisible] = useState(false);

  const showEditModal = () => {
    document.getElementById('editEntry' + entry.id).style.display = 'block';
    setEditVisible(true);
  };

  const sweepstake =
    entry?.sweepstake === 'masters23'
      ? 'The Masters 2023'
      : sweep?.competition?.name;
  const editButton = isEntriesFinished(sweep?.competition) ? (
    <button
      className="w3-button w3-half w3-block w3-text-white w3-sviolet"
      style={{ borderRadius: '0px' }}
      disabled
    >
      + Edit
    </button>
  ) : (
    <button
      onClick={() => showEditModal()}
      className="w3-button w3-half w3-block w3-text-white w3-sviolet"
      style={{ borderRadius: '0px' }}
    >
      + Edit
    </button>
  );
  const leaderboardButton = isEntriesFinished(sweep?.competition) ? (
    <button
      onClick={() => {
        navigate(`/${getUrlAlias(sweep)}/leaderboard`);
      }}
      className="w3-button w3-half w3-block w3-text-white w3-sviolet"
      style={{ borderRadius: '0px' }}
    >
      + View Leaderboard
    </button>
  ) : (
    <button
      className="w3-button w3-half w3-block w3-text-white w3-sviolet"
      style={{ borderRadius: '0px' }}
      disabled
    >
      + View Leaderboard
    </button>
  );

  return (
    <>
      <div className="w3-third">
        <div className="w3-margin w3-card w3-white">
          <header>
            <img
              src={sweep?.image_url}
              style={{ width: '100%' }}
              alt={sweep?.organisation?.name}
            />
            <h3 className="w3-center w3-text-teal sweeps-font-bold w3-padding no-margin-top">
              {sweepstake}
            </h3>
            <h6 className="w3-center w3-text-teal sweeps-font-bold w3-padding">
              {' '}
              Supporting {sweep?.organisation?.name}
            </h6>
          </header>
          <div className="w3-container">
            <h4 className="w3-text-sviolet">
              Team: <span className="w3-text-black">{entry.nickName}</span>
            </h4>
            {sweep?.competition?.sport === 'golf' &&
              !isEntriesFinished(sweep?.competition) && (
                <GolfEntry
                  user={user}
                  entry={entry}
                  sweep={sweep}
                  refreshEntries={refreshEntries}
                  visible={editVisible}
                />
              )}
            {sweep?.competition?.sport === 'rugby' &&
              !isEntriesFinished(sweep?.competition) && (
                <RugbyEntry
                  user={user}
                  entry={entry}
                  sweep={sweep}
                  refreshEntries={refreshEntries}
                />
              )}
            {sweep?.competition?.sport === 'horse_auction' &&
              !isEntriesFinished(sweep?.competition) && (
                <HorseAuctionEntry
                  user={user}
                  entry={entry}
                  sweep={sweep}
                  refreshEntries={refreshEntries}
                />
              )}

            <i>
              Selected:{' '}
              {new Date(entry.date_created).toLocaleDateString('en-us', {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </i>
          </div>
          <div className="w3-container">
            <h6 className="sweeps-font-medium">Sharing</h6>
            <button className="w3-button w3-quarter w3-block">
              <a
                target="_blank"
                rel="noreferrer"
                style={{ all: 'unset', borderRadius: '0px' }}
                href={
                  'https://twitter.com/intent/tweet?text=' +
                  getSharingText(sweep, entry?.referral_code?.code)
                }
              >
                <i className="fa fa-twitter w3-margin-left w3-text-teal w3-center"></i>
              </a>
            </button>
            <button
              className="w3-button w3-quarter w3-block"
              style={{ borderRadius: '0px' }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                style={{ all: 'unset' }}
                href={
                  'https://wa.me/?text=' +
                  getSharingText(sweep, entry?.referral_code?.code)
                }
                data-action="share/whatsapp/share"
              >
                <i className="fa fa-whatsapp w3-margin-left  w3-text-teal w3-center"></i>
              </a>
            </button>
            {entry?.referral_code && (
              <CopyReferralCode
                referralCode={entry?.referral_code?.code}
                sweep={sweep}
                size="w3-half"
                unbold={true}
              />
            )}
          </div>

          {editButton}
          {leaderboardButton}
        </div>
      </div>
    </>
  );
}

export default Entry;
