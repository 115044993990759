import { useGridApiContext } from '@mui/x-data-grid';
import React from 'react';
import { ReactMultiEmail } from 'react-multi-email';

function EmailPicker(props) {
  const { value, setEmails, id, field } = props;

  let apiRef;
  if (!setEmails) {
    apiRef = useGridApiContext();
  } else {
    apiRef = React.useRef();
  }

  const handleChange = (emails) => {
    if (setEmails) {
      setEmails(emails);
    } else {
      apiRef.current.setEditCellValue({ id, field, value: emails });
    }
  };

  return (
    <ReactMultiEmail
      placeholder="Input emails"
      emails={value}
      onChange={(_emails) => {
        handleChange(_emails);
      }}
      autoFocus={true}
      getLabel={(email, index, removeEmail) => {
        return (
          <div data-tag key={index}>
            <div data-tag-item>{email}</div>
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        );
      }}
    />
  );
}

export default EmailPicker;
