import React from 'react';
import { CardContent, CardHeader, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CreatePost from '../Posts/CreatePost';
import Posts from '../Posts/Posts';

function View(props) {
  const { user } = props;

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <CreatePost user={user} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <Posts user={user} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default View;
