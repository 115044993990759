import React from 'react';
import Field from '../Form/Fields/Field';
import DatePicker from 'react-datepicker';
import CompetitionTypeField from '../Form/Fields/CompetitionTypeField';

function Form(props) {
  const {
    errors,
    onBlur,
    handleChange,
    setDate,
    name,
    tiebreaker_question,
    sport,
    url,
    makeCut,
    start_date,
    end_date,
    entry_open_date,
    entry_close_date,
    last_tee_time,
  } = props;

  return (
    <>
      <Field
        label="Name"
        errors={errors}
        onBlur={onBlur}
        handleChange={handleChange}
        name="name"
        value={name}
        placeholder="The Masters 2023"
      />
      <Field
        label="Tie Breaker Question (leaving blank will remove the option)"
        errors={errors}
        handleChange={handleChange}
        name="tiebreaker_question"
        value={tiebreaker_question}
        placeholder=""
      />
      <CompetitionTypeField
        errors={errors}
        handleChange={handleChange}
        name="sport"
        value={sport}
      />
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Start Date *</b>
      </label>
      <DatePicker
        selected={start_date}
        onChange={(date) => setDate('start_date', date)}
        showTimeSelect
        timeFormat="p"
        timeIntervals={15}
        dateFormat="Pp"
      />{' '}
      <br />
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>End Date *</b>
      </label>
      <DatePicker
        selected={end_date}
        onChange={(date) => setDate('end_date', date)}
        showTimeSelect
        timeFormat="p"
        timeIntervals={15}
        dateFormat="Pp"
      />
      <br />
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Entries Open Date *</b>
      </label>
      <DatePicker
        selected={entry_open_date}
        onChange={(date) => setDate('entry_open_date', date)}
        showTimeSelect
        timeFormat="p"
        timeIntervals={15}
        dateFormat="Pp"
      />
      <br />
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Entries Close Date *</b>
      </label>
      <DatePicker
        selected={entry_close_date}
        onChange={(date) => setDate('entry_close_date', date)}
        showTimeSelect
        timeFormat="p"
        timeIntervals={15}
        dateFormat="Pp"
      />
      <br />
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Image</b>
      </label>
      <br />
      <input
        label="Upload files"
        type="file"
        onChange={handleChange}
        name="file"
      />
      <br />
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Config json file</b>
      </label>
      <br />
      <input
        label="Upload files"
        type="file"
        onChange={handleChange}
        name="configFile"
      />
      <br />
      {sport === 'golf' && (
        <>
          <label className={`w3-large w3-text-teal w3-margin-top`}>
            <b>Last Thursday Tee Time *</b>
          </label>
          <DatePicker
            selected={last_tee_time}
            onChange={(date) => setDate('last_tee_time', date)}
            showTimeSelect
            timeFormat="p"
            timeIntervals={10}
            dateFormat="Pp"
          />
          <br />
          <Field
            label="Data URL"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="url"
            value={url}
            placeholder="https://www.espn.com/golf/leaderboard/_/tournamentId/401465508"
          />
          <Field
            label="How many make cut?"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="makeCut"
            value={makeCut}
            placeholder="50"
          />
        </>
      )}
    </>
  );
}

export default Form;
