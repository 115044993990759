import React from 'react';
import {
  fetchDataGolfRanking,
  fetchPlayerSkills,
} from '../../../Helpers/DataGolfHelper';
import { getSuffix } from '../../../Helpers/MiscHelpers';

function PlayerSkill(props) {
  const { player } = props;
  const skill = fetchPlayerSkills(player);
  const dataGolfRanking = fetchDataGolfRanking(player);

  return (
    <React.Fragment>
      {skill && (
        <>
          <ul className="w3-ul w3-medium">
            {/* {player?.odds ? (
              <li>
                <b>Odds:</b> {player.odds} / 1
              </li>
            ) : (
              <li>
                <b>Odds:</b> No odds available for player
              </li>
            )} */}
            <li>
              <b>World Ranking:</b>{' '}
              {getSuffix(dataGolfRanking?.owgr_rank || 'NA')}
            </li>
            <li>
              <b>Ameteur:</b> {dataGolfRanking?.am == 1 ? 'Yes' : 'No'}
            </li>
            <li>
              <b>Primary Tour:</b> {dataGolfRanking?.primary_tour}
            </li>
            <li>
              <b>SG approach:</b> {getSuffix(skill.sg_app)}
            </li>
            <li>
              <b>SG around green:</b> {getSuffix(skill.sg_arg)}
            </li>
            <li>
              <b>SG off the tee:</b> {getSuffix(skill.sg_ott)}
            </li>
            <li>
              <b>SG putting:</b> {getSuffix(skill.sg_putt)}
            </li>
            <li>
              <b>SG total:</b> {getSuffix(skill.sg_total)}
            </li>
            <li>
              <b>Driving distance:</b> {getSuffix(skill.driving_dist)}
            </li>
            <li>
              <b>Driving accuracy:</b> {getSuffix(skill.driving_acc)}
            </li>
          </ul>

          <p className="w3-small">SG - Strokes Gained</p>
        </>
      )}
      {!skill && (
        <>
          <ul className="w3-ul w3-medium">
            {/* {player?.odds ? (
              <li>
                <b>Odds:</b> {player.odds} / 1
              </li>
            ) : (
              <li>
                <b>Odds:</b> No odds available for player
              </li>
            )} */}
            <li>
              <b>No historical data available for {player?.name} </b>
            </li>
          </ul>
        </>
      )}
    </React.Fragment>
  );
}

export default PlayerSkill;
