import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styled, { keyframes } from 'styled-components';
import Filters from '../Shared/Filters';

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      minWidth: '90px',
    },
  },
};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid #006747;
  border-right: 2px solid #006747;
  border-bottom: 2px solid #006747;
  border-left: 4px solid #006747;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const CustomLoader = () => (
  <div style={{ padding: '24px' }}>
    <Spinner />
  </div>
);

function LeaderboardTable(props) {
  const {
    user,
    updatedScores,
    sortedEntries,
    loading,
    columns,
    expandedRow,
    expandableRowsComponentProps,
  } = props;

  const [state, setState] = React.useState({
    name: '',
  });

  let filteredMappedData;
  const mappedData = sortedEntries.map((item, index) => ({
    postion: index,
    ...item,
  }));

  if (state.name === '') {
    filteredMappedData = mappedData;
  } else {
    filteredMappedData = getFilteredData(mappedData);
  }

  const conditionalRowStyles = [
    {
      when: (row) => user && row.user_id === user.userId,
      style: {
        backgroundColor: 'rgba(91, 97, 138, 0.6)',
      },
    },
  ];

  function getFilteredData(mappedData) {
    return mappedData.filter(function (sweepstakeEntry) {
      if (state.name !== '') {
        return isNameInSweepEntryName(sweepstakeEntry?.nickName);
      }
    });
  }

  function isNameInSweepEntryName(entryName) {
    return entryName
      ?.toString()
      .toUpperCase()
      .includes(state.name?.toUpperCase());
  }

  function updateFilters(name) {
    setState({
      ...state,
      name,
    });
  }

  return (
    <>
      <div className="w3-row">
        <div className="w3-third">
          <Filters name={state.name} updateFilters={updateFilters} />
        </div>
        <div className="w3-twothird">
          <p className="w3-margin-top" style={{ float: 'right' }}>
            <i
              className={`fa fa-clock-o fa-fw w3-margin-right w3-large w3-text-teal`}
            />
            Last Updated at {updatedScores.getHours()}:
            {updatedScores.getMinutes()}:{updatedScores.getSeconds()}
          </p>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={filteredMappedData}
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={<CustomLoader />}
        expandableRows={true}
        expandOnRowClicked={true}
        expandableRowsHideExpander={true}
        expandableRowsComponent={expandedRow}
        conditionalRowStyles={conditionalRowStyles}
        expandableRowsComponentProps={expandableRowsComponentProps}
        expandableRowExpanded={(row) => row.defaultExpanded}
      />
    </>
  );
}

export default LeaderboardTable;
