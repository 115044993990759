import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import View from './View';

function Container(props) {
  const { user } = props;

  const navigate = useNavigate('');

  useEffect(() => {
    if (!user?.adminAccess) {
      navigate('/');
    }
  }, [user]);

  return <View user={user} />;
}

export default Container;
