/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Slideshow(props) {
  const { data } = props;
  const navigate = useNavigate();

  function itemClicked(index, item) {
    const dataItem = data[index];

    navigate(dataItem?.link);
  }

  return (
    <div className="w3-card w3-white">
      <Carousel
        dynamicHeight={true}
        autoPlay={true}
        onClickItem={(index, item) => itemClicked(index, item)}
        infiniteLoop={true}
        interval={3500}
        showThumbs={false}
        showStatus={false}
      >
        {data.map((slide) => {
          return <div className="pointer">{slide.component}</div>;
        })}
      </Carousel>
    </div>
  );
}

export default Slideshow;
