/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Error from '../Error';
import {
  getPrizeOptions,
  getPrizeDisplayName,
} from '../../../Helpers/PrizeHelper';

function PrizeField(props) {
  const { errors, handleChange, value } = props;
  const prizeOptions = getPrizeOptions();
  const input = (
    <select
      defaultValue={''}
      onChange={handleChange}
      className="w3-select"
      name="prize_breakdown"
    >
      {value && value !== 'none' && (
        <option value={value} selected>
          {getPrizeDisplayName(value)}
        </option>
      )}
      <option value="none">Select Prize</option>
      {Object.keys(prizeOptions).map((prizeKey) => {
        return (
          <option
            key={prizeOptions[prizeKey].id}
            value={prizeOptions[prizeKey].id}
          >
            {getPrizeDisplayName(prizeKey)}
          </option>
        );
      })}
    </select>
  );

  return (
    <React.Fragment>
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Select Prize</b>
      </label>
      {errors && errors['prize_breakdown'] && (
        <Error error={errors['prize_breakdown']} />
      )}
      {input}
    </React.Fragment>
  );
}

export default PrizeField;
