/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import { fetchPosts } from '../../Helpers/BlogHelper';
import PostCard from './Posts/Card';
import { Link } from 'react-router-dom';

function BlogGallery(props) {
  const { limit, title, showLink } = props;

  const [state, setState] = React.useState({
    posts: [],
  });

  useEffect(() => {
    fetchPosts(updateState);
  }, []);

  function updateState(response) {
    if (response.data) {
      setState({
        ...state,
        posts: response.data['posts'],
      });
    } else if (response.response) {
      setState({
        ...state,
        posts: [],
      });
    }
  }

  const postCards = state.posts
    .sort(function (a, b) {
      return new Date(b.date_created) - new Date(a.date_created);
    })
    .map((post) => <PostCard key={post.id} post={post} />);

  return (
    <>
      <div className="w3-row">
        <h2 className="sweeps-font-bold w3-text-sviolet w3-center">
          {title || 'Posts'}
        </h2>
        {showLink && (
          <div className="w3-center">
            <p className="w3-center">
              <Link to="/posts">all posts</Link>
            </p>
          </div>
        )}
        <hr />
      </div>
      <div className="w3-row-padding w3-row">
        {postCards.length > 0 && postCards.slice(0, limit || 1000)}
      </div>
    </>
  );
}

export default BlogGallery;
