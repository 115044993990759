/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import CreateLicense from './CreateLicense';

function LottoLicenseContainer(props) {
  const { user, organisation } = props;

  return <CreateLicense user={user} organisationId={organisation?.id} />;
}

export default LottoLicenseContainer;
