/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Error from '../Error';

function CheckboxField(props) {
  const { label, handleChange, onBlur, name, value, type } = props;
  const actualType = type ? type : 'text';

  let input = (
    <input
      onChange={handleChange}
      onBlur={onBlur}
      name={name}
      value={value}
      className="w3-check small-check"
      type={actualType}
      checked={value}
    />
  );

  return (
    <React.Fragment>
      <div>
        <label className={`w3-large w3-text-teal`}>
          <b>
            {label} *<span className="w3-margin-left">{input}</span>
          </b>
        </label>
      </div>
    </React.Fragment>
  );
}

export default CheckboxField;
