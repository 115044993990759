import React, { useState } from 'react';
import { addReferralLinkToClipboard } from '../../Helpers/ReferralsHelper';

const CopyReferralCode = (props) => {
  const { referralCode, sweep, size, unbold } = props;

  const [clicked, setClicked] = useState(false);

  const onClick = () => {
    setClicked(true);
    addReferralLinkToClipboard(sweep, referralCode);
  };

  return (
    <button
      className={`w3-button  w3-block  ${size}`}
      onClick={() => referralCode && onClick()}
    >
      <i className="fa fa-copy w3-margin-left  w3-text-teal w3-center"></i>
      <span className={`w3-margin-left ${unbold ? '' : 'bold'} `}>
        {clicked ? 'Copied Link' : 'Copy Referral Link'}
      </span>
    </button>
  );
};

export { CopyReferralCode };
