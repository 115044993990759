import React, { useEffect } from 'react';
import Rules from './Components/SweepsSignup/Golf/Rules';
import RugbyRules from './Components/SweepsSignup/Rugby/Rules';
import { Link } from 'react-router-dom';

function FAQ() {
  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="w3-row-padding w3-margin-top">
        <div className="w3-row">
          <div className="w3-container w3-card w3-white">
            <div className="w3-margin">
              <h3 className=" w3-text-teal sweeps-font-bold">
                Frequently Asked Questions:
              </h3>
              <br />
              <h4 className=" w3-text-sviolet sweeps-font-bold">
                I can't find the signup email?
              </h4>
              <p>
                Make sure to double check your spam, junk, promotions and social
                folders.
              </p>
              <hr />
              <h4 className=" w3-text-sviolet sweeps-font-bold">
                I entered the a sweep with us before but never signed in?
              </h4>
              <p>
                You should find an email in your inbox from
                `no-reply@thesweeps.io` with a title of eithe `Sweeps |
                Temporary Password` or `Sweeps | Confirm Email`. You should then
                be able to use this temporary password or link in the email to
                sign in and subsequently change your password.
              </p>
              <hr />
              <h4 className=" w3-text-sviolet sweeps-font-bold">
                How do I signup?
              </h4>
              <p>
                Watch our tutorial video{' '}
                <a
                  href="https://www.youtube.com/watch?v=_0SUdwHOjqM&t=15s"
                  target="_blank"
                >
                  here
                </a>
                , which will walk you through the steps. The process is very
                similar for non golf sweeps.
              </p>
              <hr />
              <h4 className=" w3-text-sviolet sweeps-font-bold">
                How do I edit my team?
              </h4>
              <p>
                You can only edit your team while entries are still open. Watch
                a tutorial how to edit{' '}
                <a
                  href="https://www.youtube.com/watch?v=0dSb4wl2OcI"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>
              <hr />
              <h4 className=" w3-text-sviolet sweeps-font-bold">
                When can I access the leaderboard?
              </h4>
              <p>
                Leaderboard access will open as soon as entries close, the
                leaderboards will be available <Link to="/">here</Link>
              </p>
              <hr />
              <h4 className=" w3-text-sviolet sweeps-font-bold">
                What are the rules?
              </h4>
              <div className="w3-row">
                <div className="w3-half">
                  <div className="w3-container w3-padding">
                    <h5 className=" w3-text-teal sweeps-font-bold">
                      Golf Competitions
                    </h5>
                    <Rules />
                  </div>
                </div>
                <div className="w3-half">
                  <div className="w3-container w3-padding">
                    <h5 className=" w3-text-teal sweeps-font-bold">
                      Rugby Competitions
                    </h5>
                    <RugbyRules />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
