import React from 'react';
import { Button, Grid, List, ListItem } from '@mui/material';
import { prettyDate } from '../../../Helpers/MiscHelpers';
import { useNavigate } from 'react-router-dom';
import EntrantsDataTable from '../../Sweeps/EntrantsDataTable';
import IndividualLicenses from '../Licenses/IndividualLicenses';
import ReferralsDataTable from '../../Referrals/ReferralsDataTable';

function IndividualSweep(props) {
  const { sweep, user } = props;

  const navigate = useNavigate('');

  return (
    <>
      <h4 className="sweeps-font-bold w3-text-teal">
        {sweep?.competition?.name}
      </h4>
      <Grid container>
        <Grid item md={5}>
          <img
            src={sweep?.image_url}
            style={{ width: '100%' }}
            alt={sweep?.organisation?.name}
          />
        </Grid>
        <Grid item md={7}>
          <List>
            <ListItem>
              Current Entry Count: {sweep?.entry_count} entries
            </ListItem>
            <ListItem>Sport: {sweep?.competition?.sport}</ListItem>
            <ListItem>
              Entries Open: {prettyDate(sweep?.competition?.entry_open_date)}
            </ListItem>
            <ListItem>
              Entries Close: {prettyDate(sweep?.competition?.entry_close_date)}
            </ListItem>
            <ListItem>
              <Button onClick={() => navigate(`/${sweep?.alias}`)}>
                Sweep
              </Button>
              <Button onClick={() => navigate(`/${sweep?.alias}/leaderboard`)}>
                Leaderboard
              </Button>
            </ListItem>
          </List>
        </Grid>
        <Grid item md={12} sx={{ marginBottom: '30px' }}>
          <EntrantsDataTable sweep={sweep} user={user} />
        </Grid>
        <Grid item md={12} sx={{ marginBottom: '30px' }}>
          <ReferralsDataTable sweep={sweep} user={user} />
        </Grid>
        <Grid item md={12}>
          <IndividualLicenses licenses={sweep?.licenses} />
        </Grid>
      </Grid>
    </>
  );
}

export default IndividualSweep;
