import React from 'react';

function PlayerName(props) {
  const { player, liveScores } = props;
  let position = '';
  if (liveScores !== undefined && player !== undefined) {
    position = getPlayerPosition(player, liveScores);
  }
  return (
    <>
      <span>
        {player} ({position})
      </span>
    </>
  );
}

function getPlayerPosition(player, liveScores) {
  let foundPlayer = liveScores.find(
    (score) => score.player.toLowerCase() === player?.toLowerCase(),
  );
  if (foundPlayer !== undefined) {
    return foundPlayer.position;
  } else {
    // try find ameteur
    let playerName = player?.toLowerCase() + ' (a)';
    foundPlayer = liveScores.find(
      (score) => score.player.toLowerCase() === playerName,
    );
    if (foundPlayer === undefined) {
      console.info('Not found');
    } else {
      return foundPlayer.position;
    }
  }
  return 'Not Found';
}

export default PlayerName;
