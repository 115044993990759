import React from 'react';
import { useNavigate } from 'react-router-dom';
import { editSweep } from '../../Helpers/SweepsHelper';

import SuccessPanel from '../Form/SuccessPanel';
import Form from './Form';
import QRCode from 'react-qr-code';
import { getUrlAlias } from '../../Helpers/SweepsHelper';

function EditSweep(props) {
  const { user, sweep } = props;

  const navigate = useNavigate('');
  if (!user || !user?.adminAccess) {
    navigate('/');
  }

  const [state, setState] = React.useState({
    id: sweep.id,
    organisation_id: sweep?.organisation_id || '',
    the_sweep: sweep?.the_sweep,
    competition_id: sweep.competition_id,
    prize_breakdown: sweep.prize_breakdown,
    connected_account_id: sweep.connected_account_id,
    alias: sweep.alias,
    entry_fee: sweep.entry_fee || '1000',
    platform_fee: sweep.platform_fee || '100',
    private_sweep: sweep.private_sweep || false,
    user_id: user?.userId,
    failedCreate: false,
    file: undefined,
    successfulCreate: false,
    admins: [],
    errors: {},
  });

  function onBlur(evt) {
    const value = evt.target.value;
    let messages = [];
    let addErrors = false;

    if (!value || !/\S/.test(value)) {
      addErrors = true;
      messages.push('Mandatory field, please enter a value');
    }

    if (addErrors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: messages,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
          form: undefined,
        },
      });
    }
  }

  function handleChange(evt) {
    let value;
    if (evt.target.type === 'checkbox') {
      value = evt.target.checked;
    } else if (evt.target.type === 'file') {
      value = evt.target.files[0];
    } else {
      value = evt.target.value;
    }

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function handleEditSweepResponse(response) {
    if (response.data) {
      if (response.status === 200) {
        setState({
          ...state,
          failedCreate: false,
          successfulCreate: true,
        });
      }
    }
    if (response.response) {
      setState({
        ...state,
        successfulCreate: false,
        failedCreate: true,
      });
    }
  }

  return (
    <div className="w3-padding">
      {state.successfulCreate && (
        <SuccessPanel message="Successfully edited sweep" />
      )}
      {state.failedCreate && 'Failed to create competition'}
      <h3 className="sweeps-font-bold w3-text-teal">Edit Sweep</h3>
      <hr />
      <div className="w3-container">
        <div className="w3-row">
          <div className="w3-half">
            <img
              className="center"
              style={{ maxHeight: '300px' }}
              src={sweep.image_url}
            />
          </div>
          <div className="w3-half">
            <QRCode value={`${window.location.host}/${getUrlAlias(sweep)}`} />
          </div>
        </div>
      </div>
      <hr />

      <Form
        errors={state.errors}
        onBlur={onBlur}
        handleChange={handleChange}
        organisation_id={state.organisation_id}
        the_sweep={state.the_sweep}
        competition_id={state.competition_id}
        prize_breakdown={state.prize_breakdown}
        entry_fee={state.entry_fee}
        platform_fee={state.platform_fee}
        private_sweep={state.private_sweep}
        file={state.file}
        user={user}
        alias={state.alias}
      />

      <button
        onClick={() => editSweep(user, state, handleEditSweepResponse)}
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Edit
      </button>
    </div>
  );
}

export default EditSweep;
