import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadSweeps } from '../../../features/useLoadSweeps';
import ReferralCodes from '../ReferralCodes';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';

function ReferralsContainer(props) {
  const { user } = props;
  const { getSweepsArray } = useLoadSweeps();
  let sweeps = getSweepsArray();

  const navigate = useNavigate('');

  useEffect(() => {
    if (!user?.adminAccess) {
      navigate('/');
    }
  }, [user]);

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item sm={12}>
          <Card>
            <CardHeader className="w3-text-teal sweeps-font-bold" />
            <CardContent>
              <ReferralCodes user={user} sweeps={sweeps} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ReferralsContainer;
