import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPost } from '../../../Helpers/BlogHelper';

import SuccessPanel from '../../Form/SuccessPanel';
import Form from './Form';

function CreatePost(props) {
  const { user } = props;

  const navigate = useNavigate('');
  if (!user?.adminAccess) {
    navigate('/');
  }

  const [state, setState] = React.useState({
    title: '',
    competition_id: '',
    body: '',
    user_id: user?.userId,
    file: undefined,
    errors: {},
    successfulCreate: false,
    failedCreate: false,
  });

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
  }, []);

  function handleChange(evt) {
    const value =
      evt.target.name === 'file' ? evt.target.files[0] : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const createCallback = (success) => {
    setState({
      ...state,
      failedCreate: !success,
      successfulCreate: success,
    });
  };

  return (
    <>
      {state.successfulCreate && (
        <SuccessPanel message="Successfully created competition" />
      )}
      {state.failedCreate && 'Failed to create competition'}
      <h3 className="sweeps-font-bold w3-text-teal">Create Blog</h3>
      <Form
        user={user}
        errors={state.errors}
        handleChange={handleChange}
        title={state.title}
        body={state.body}
        competition_id={state.competition_id}
        file={state.file}
      />

      <button
        onClick={() => createPost(user, state, createCallback)}
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Create
      </button>
    </>
  );
}

export default CreatePost;
