import React from 'react';

const HorseOptionResult = (props) => {
  const { option, result } = props;
  return (
    <div
      className="w3-col w3-mobile"
      data-testid="horse-option-result"
      style={{ width: '20%' }}
    >
      <div className="w3-center">
        <ul
          style={{ listStyleType: 'none', paddingInlineStart: '0px' }}
          data-testid="yearling-information"
        >
          <li style={{ marginBottom: '5px' }}>Lot: {option?.optionId}</li>
        </ul>
        <h6 className={'sweeps-font-medium w3-center w3-text-teal'}>
          {option?.stallion}
        </h6>
        <h6 className={'sweeps-font-medium w3-center w3-text-teal'}>
          {option?.dam}
        </h6>
        <ul
          style={{ listStyleType: 'none', paddingInlineStart: '0px' }}
          data-testid="yearling-information"
        >
          <li style={{ marginBottom: '5px' }}>
            Sold for: <b>€{result?.price}</b>
          </li>
          <li>{option?.consignor}</li>
        </ul>
      </div>
    </div>
  );
};

export default HorseOptionResult;
