/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { getEntryFee } from '../../../Helpers/SweepsHelper';
import Match from './Match';

function Rules(props) {
  const { sweep } = props;

  const [state, setState] = useState({
    picks: {},
  });

  function handleMatchPicked(
    matchId,
    optionId,
    lowerBound,
    upperBound,
    teamId,
    draw,
  ) {
    setState({
      ...state,
      picks: {
        ...state.picks,
        [matchId]: {
          matchId,
          optionId,
          teamId,
          lowerBound,
          upperBound,
          draw,
        },
      },
    });
  }

  return (
    <div data-testid="rugbyRules">
      <p className="w3-center">Here's how it works:</p>
      <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
        <i className="fa-solid fa-people-group w3-padding-right w3-large"></i>
        Selecting your results
      </h5>

      <p className="w3-center">
        You must predict the winner and the score of each match listed in the
        signup process.
      </p>

      {!sweep?.private_sweep && (
        <>
          <h5 className="w3-large sweeps-font-bold w3-text-sviolet w3-center">
            <i className="fa-solid fa-sack-dollar  w3-padding-right w3-large"></i>
            Entry Cost
          </h5>
          <p className="w3-center">{getEntryFee(sweep)} per entry.</p>
        </>
      )}

      <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
        <i className="fa-solid fa-trophy  w3-padding-right w3-large"></i>
        The Scoring
      </h5>
      {/* TODO: remove this after RWC Giveaway */}
      {sweep?.alias === 'rwc' && (
        <>
          <p className="w3-center">
            An entrant who picks the correct scoring bracket will be choosen at
            random to win the prize.
          </p>

          <p className="w3-center">
            The random draw will be recorded and shared on our social channels.
          </p>

          <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
            Who are we?
          </h5>
          <p className="w3-center">
            Sweeps is dedicated to revolutionizing the way clubs and charities
            raise funds by providing engaging and effortless online sweepstakes
            for their members and friends alike.
          </p>
          <p className="w3-center">
            At Sweeps, our mission is simple yet powerful: to build engaging and
            fun new ways for clubs and charities to fundraise with ease. We
            understand the importance of creating an enjoyable and seamless
            fundraising experience for both participants and organizers alike.
          </p>
        </>
      )}
      {sweep?.alias != 'rwc' && (
        <>
          <p className="w3-center">
            If you predict the correct <b>winning team</b> but the wrong score
            bracket, you will receive <b>8 points</b>.
          </p>
          <p className="w3-center">
            If you predict the <b>correct winning team</b> and the{' '}
            <b>correct score bracket</b> of the match, you will receive{' '}
            <b>13 points</b>.
          </p>
          <p className="w3-center">
            If you predict a draw and the result is indeed a <b>draw</b>, you
            will receive
            <b> 30 points</b>.
          </p>

          <p className="w3-center">
            The participant with the highest total points at the end of the
            tournament wins the sweepstake. In the case of a tie, the
            participant who predicted the tiebreaker question closest to the
            actual value will be declared the winner. If there is still a tie
            after considering the tiebreaker question, the prize may be shared
            among the tied participants.
          </p>
          <h5 className="w3-large w3-center sweeps-font-bold w3-text-sviolet">
            <i className="fa-solid fa-magnifying-glass  w3-padding-right w3-large"></i>
            Example
          </h5>

          <p className="w3-center">
            Below, is an example of how you will pick for each match.
          </p>

          <Match
            className="w3-center"
            key="123"
            size="w3-row"
            handleMatchPicked={handleMatchPicked}
            selection={state.picks[123]?.optionId}
            match={{
              gameId: 123,
              leagueId: 164205,
              teams: [3, 2],
              group: 1,
              date: '2023-09-14',
              options: [
                {
                  id: 1,
                  team: 3,
                  lowerBound: 1,
                  upperBound: 4,
                },
                {
                  id: 2,
                  team: 3,
                  lowerBound: 5,
                  upperBound: 9,
                },
                {
                  id: 3,
                  team: 3,
                  lowerBound: 10,
                  upperBound: 15,
                },
                {
                  id: 4,
                  team: 3,
                  lowerBound: 16,
                  upperBound: 22,
                },
                {
                  id: 5,
                  team: 3,
                  lowerBound: 23,
                  upperBound: 1000,
                },
                {
                  id: 6,
                  team: 2,
                  lowerBound: 1,
                  upperBound: 4,
                },
                {
                  id: 7,
                  team: 2,
                  lowerBound: 5,
                  upperBound: 9,
                },
                {
                  id: 8,
                  team: 2,
                  lowerBound: 10,
                  upperBound: 15,
                },
                {
                  id: 9,
                  team: 2,
                  lowerBound: 16,
                  upperBound: 22,
                },
                {
                  id: 10,
                  team: 2,
                  lowerBound: 23,
                  upperBound: 1000,
                },
              ],
            }}
            id={123}
          />
        </>
      )}

      <p className="w3-center">
        Please find the rules{' '}
        <a
          className="pointer"
          onClick={() =>
            window.open(`${window.location.origin}/terms`, '_blank')
          }
        >
          here
        </a>{' '}
        to understand exactly what happens in exception events.
      </p>
    </div>
  );
}

export default Rules;
