import importedSkillsData from '../Data/DataGolf/skillsRating.json';
import importedDataGolfRankings from '../Data/DataGolf/dataGolfRankings.json';

export function fetchPlayerSkills(player) {
  return importedSkillsData.players.filter(
    (skillsPlayer) => skillsPlayer.dg_id === player?.dg_id,
  )[0];
}

export function fetchDataGolfRanking(player) {
  return importedDataGolfRankings.rankings.filter(
    (skillsPlayer) => skillsPlayer.dg_id === player?.dg_id,
  )[0];
}
