/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Field from '../Form/Fields/Field';

function NickNameSlide(props) {
  const {
    nickName,
    errors,
    onBlur,
    handleChange,
    previousSlide,
    nextSlide,
    lastStep,
    hidePrevious,
  } = props;
  const progressButton =
    nickName !== '' && /\S/.test(nickName) && !errors ? (
      <button
        onClick={() => nextSlide()}
        data-testid="nextButton"
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
      >
        {lastStep ? 'Edit' : 'Next'}
      </button>
    ) : (
      <button
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
        data-testid="disabledNextButton"
        disabled
      >
        {lastStep ? 'Edit' : 'Next'}
      </button>
    );

  return (
    <div className="w3-padding">
      <Field
        label="Nick Name"
        errors={errors}
        onBlur={onBlur}
        handleChange={handleChange}
        name="nickName"
        value={nickName}
      />
      {!hidePrevious && (
        <button
          onClick={() => previousSlide()}
          className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        >
          Back
        </button>
      )}

      {progressButton}
    </div>
  );
}

export default NickNameSlide;
