import React from 'react';
import SmallMatch from './SmallMatch';
const ExpandedRow = (props) => {
  const { data, expandedMatches } = props;

  return (
    <>
      <div className="w3-row" style={{ maxWidth: '100vw' }}>
        {Object.keys(data.picks).map((key) => (
          <SmallMatch
            key={key}
            pick={data.picks[key]}
            match={expandedMatches.filter((match) => match.gameId == key)[0]}
            result={data.results[key]}
          />
        ))}
      </div>
    </>
  );
};

export default React.memo(ExpandedRow);
