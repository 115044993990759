/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect } from 'react';
import Group from '../../SweepsSignup/Rugby/Group';
import Tiebreak from '../../SweepsSignup/Tiebreak';
import NickNameSlide from '../../SweepsSignup/NickNameSlide';
import { logMetric } from '../../../Helpers/AnalyticsHelper';
import {
  getMatchesDataForSweep,
  getNumberOfGroups,
} from '../../../Helpers/RugbyMatchesHelper';
import { editEntry } from '../../../Helpers/EntriesHelper';
import { hasTiebreakQuestion } from '../../../Helpers/SweepsHelper';
import { toast } from 'react-toastify';

const TIEBREAK_SLIDE = 4;
const NICK_NAME_SLIDE = 5;
const ERROR_SLIDE = 12;

function EditEntry(props) {
  const { entry, refreshEntries, sweep } = props;

  const scrollToReference = useRef(null);
  const executeScroll = () => scrollToReference.current.scrollIntoView();

  const [state, setState] = React.useState({
    picks: entry.picks,
    tieBreaker: entry.tie_breaker,
    activeSlide: 0,
    errors: {},
    matches: [],
    nickName: entry.nickName,
    successfulSignup: false,
    editAgain: false,
  });

  useEffect(() => {
    if (sweep) {
      getMatchesDataForSweep(sweep, updateMatches);
    }
  }, [sweep]);

  const numberOfGroups = getNumberOfGroups(state.matches) - 1;

  const updateMatches = (matchesData) => {
    setState({
      ...state,
      matches: matchesData,
    });
  };

  const nextSlide = () => {
    executeScroll();
    if (state.activeSlide === numberOfGroups) {
      const slide = hasTiebreakQuestion(sweep)
        ? TIEBREAK_SLIDE
        : NICK_NAME_SLIDE;
      setState({
        ...state,
        activeSlide: slide,
      });
      return;
    }
    setState({
      ...state,
      activeSlide: state.activeSlide + 1,
    });
  };

  const previousSlide = (slide) => {
    executeScroll();
    if (slide) {
      setState({
        ...state,
        activeSlide: slide,
      });
      return;
    }
    let previousSlide = 1000;
    if (state.activeSlide === NICK_NAME_SLIDE) {
      previousSlide = hasTiebreakQuestion(sweep)
        ? TIEBREAK_SLIDE
        : numberOfGroups;
      setState({
        ...state,
        activeSlide: previousSlide,
      });
      return;
    }
    previousSlide =
      state.activeSlide === TIEBREAK_SLIDE
        ? numberOfGroups
        : state.activeSlide - 1;
    setState({
      ...state,
      activeSlide: previousSlide,
    });
  };

  function handleMatchPicked(matchId, lowerBound, upperBound, teamId, draw) {
    setState({
      ...state,
      picks: {
        ...state.picks,
        [matchId]: {
          matchId,
          teamId,
          lowerBound,
          upperBound,
          draw,
        },
      },
    });
  }

  function editAgain() {
    setState({
      ...state,
      successfulSignup: false,
      editAgain: true,
      activeSlide: 0,
    });
  }

  const handleSubmissionResponse = (response) => {
    if (response.data.message.includes('Successfully edited entry')) {
      logMetric('entries', 'successfulEdit');
      setState({
        ...state,
        successfulSignup: true,
      });
      refreshEntries();
    } else {
      logMetric('entries', 'failedEdit');
      setState({
        ...state,
        activeSlide: 12,
        errors: {
          ...state.errors,
          editEntry: {
            messages: [response.response.data.message],
          },
        },
      });
    }
  };

  const submitSelections = () => {
    if (sweep) {
      const newEntry = {
        id: entry.id,
        nickName: state.nickName,
        picks: state.picks,
        sweep_id: sweep.id,
        tie_breaker: state.tieBreaker,
      };
      editEntry(newEntry, handleSubmissionResponse);
    }
  };

  function handleChange(evt) {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function onBlur(evt) {
    const value = evt.target.value;
    let messages = [];
    let addErrors = false;

    if (!value || !/\S/.test(value)) {
      addErrors = true;
      toast.error('Mandatory field, please enter a value', {
        toastId: 'mandatory_field',
      });
      messages.push('Mandatory field, please enter a value');
    }

    if (addErrors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: messages,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
        },
      });
    }
  }

  return (
    <React.Fragment>
      <>
        <div id={'editEntry' + entry.id} className="w3-modal w3-medium">
          <div
            className="w3-modal-content"
            style={{ backgroundColor: 'transparent' }}
          >
            <span
              onClick={() => {
                document.getElementById(`editEntry${entry.id}`).style.display =
                  'none';
              }}
              className="pointer w3-button w3-xlarge w3-display-topright times"
            >
              &times;
            </span>

            <div className="w3-row-padding w3-margin-top">
              <div ref={scrollToReference}></div>
              <div className="w3-row">
                <div className="w3-container w3-card w3-white">
                  {state.successfulSignup && (
                    <>
                      <h3 className="sweeps-font-bold w3-text-teal w3-margin-top">
                        Entry Successfully Edited
                      </h3>
                      <button
                        onClick={() => editAgain()}
                        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
                      >
                        Update your entry
                      </button>
                      <button
                        onClick={() => {
                          document.getElementById(
                            `editEntry${entry.id}`,
                          ).style.display = 'none';
                        }}
                        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
                      >
                        Return to dashboard
                      </button>
                    </>
                  )}
                  {!state.successfulSignup && (
                    <form
                      className="w3-container"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      {state.activeSlide === 0 && (
                        <Group
                          matches={state.matches.filter(
                            (match) => match.group === 1,
                          )}
                          picks={state.picks}
                          groupTitle="Group 1"
                          handleMatchPicked={handleMatchPicked}
                          nextSlide={nextSlide}
                          hidePrevious={true}
                        />
                      )}

                      {state.activeSlide === 1 && (
                        <Group
                          matches={state.matches.filter(
                            (match) => match.group === 2,
                          )}
                          picks={state.picks}
                          groupTitle="Group 2"
                          handleMatchPicked={handleMatchPicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}

                      {state.activeSlide === 2 && (
                        <Group
                          matches={state.matches.filter(
                            (match) => match.group === 3,
                          )}
                          picks={state.picks}
                          groupTitle="Group 3"
                          handleMatchPicked={handleMatchPicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}

                      {state.activeSlide === 3 && (
                        <Group
                          matches={state.matches.filter(
                            (match) => match.group === 4,
                          )}
                          picks={state.picks}
                          groupTitle="Group 4"
                          handleMatchPicked={handleMatchPicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}

                      {state.activeSlide === TIEBREAK_SLIDE && (
                        <Tiebreak
                          onBlur={onBlur}
                          handleChange={handleChange}
                          nextSlide={nextSlide}
                          previousSlide={previousSlide}
                          tieBreaker={state.tieBreaker}
                          label={
                            'Tiebreaker:' +
                            sweep.competition.tiebreaker_question
                          }
                        />
                      )}

                      {state.activeSlide === NICK_NAME_SLIDE && (
                        <NickNameSlide
                          onBlur={onBlur}
                          handleChange={handleChange}
                          nextSlide={submitSelections}
                          lastStep={true}
                          previousSlide={previousSlide}
                          nickName={state.nickName}
                        />
                      )}

                      {state.activeSlide === ERROR_SLIDE &&
                        state.errors['editEntry'] && (
                          <>
                            <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-red">
                              <p className="w3-margin-top">
                                <i
                                  className={`fa fa-warning fa-fw w3-margin-right`}
                                />
                                {state.errors['editEntry'].messages[0]}
                              </p>
                            </div>
                            <button
                              onClick={() => previousSlide(0)}
                              className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
                            >
                              Back
                            </button>
                          </>
                        )}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}

export default EditEntry;
