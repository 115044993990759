/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function FourOhFour(props) {
  useEffect(() => {
    document.body.style.backgroundColor = '#006747';
  }, []);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="w3-container  w3-margin-top w3-animate-opacity w3-text-white">
        <div className="w3-container">
          <h1 className="sweeps-font-bold w3-jumbo w3-animate-top w3-center">
            Lost?{' '}
          </h1>
          <div className="w3-container w3-center">
            <img
              src="https://golf.com/wp-content/uploads/2020/11/bryson-dechambeau-lost-ball-1024x576.jpg"
              className="w3-margin-top center"
              style={{ width: '70%' }}
              alt="Lost Ball"
            />
            <button
              onClick={() => navigate('/')}
              className="w3-btn w3-center w3-margin-top w3-white w3-text-teal"
            >
              Return to Safety
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FourOhFour;
