import React from 'react';
import Header from '../Layouts/Header';
import { Oval } from 'react-loader-spinner';
import Footer from '../Layouts/Footer';
import { ToastContainer } from 'react-toastify';
import Dashboard from '../Dashboard/Dashboard';

function AdminFullPageWrapper(props) {
  const { fetchUserDetails, handleLogout, loading, user, child } = props;

  return (
    <>
      <div className="fullPageWrapper">
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={true}
          closeOnClick
          pauseOnHover
          theme="light"
        />
        {loading && (
          <>
            <Oval
              wrapperStyle={{
                margin: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '200px',
              }}
              ariaLabel="loading-indicator"
              height={100}
              width={100}
              strokeWidth={5}
              strokeWidthSecondary={1}
              color="teal"
              secondaryColor="white"
            />
          </>
        )}
        {!loading && (
          <>
            <Header
              fetchUserDetails={fetchUserDetails}
              handleLogout={handleLogout}
              loggedInUser={user}
            />
            <Dashboard user={user} />
            {child}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default AdminFullPageWrapper;
