import React from 'react';

function RiskColour(props) {
  const { row } = props;
  return (
    <React.Fragment>
      {row.riskColor !== '' && (
        <i
          className="fa fa-circle w3-center"
          style={{ color: `${row.riskColor}`, margin: 'auto' }}
        ></i>
      )}
    </React.Fragment>
  );
}

export default RiskColour;
