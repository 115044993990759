import dateFormat from 'dateformat';
import { getReferralLink } from './ReferralsHelper';

export function getSuffix(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function isEntriesOpen(competition) {
  return (
    Date.now() > Date.parse(competition?.entry_open_date) &&
    Date.now() < Date.parse(competition?.entry_close_date)
  );
}

export function isEntriesFinished(competition) {
  if (!competition || Object.keys(competition).length === 0) {
    return false;
  }
  return Date.now() > Date.parse(competition?.entry_close_date);
}

export function isEntriesStarted(competition) {
  if (!competition || Object.keys(competition).length === 0) {
    return false;
  }
  return Date.now() > Date.parse(competition?.entry_open_date);
}

export function isTournamentFinished(competition) {
  if (!competition || Object.keys(competition).length === 0) {
    return false;
  }
  return Date.now() > Date.parse(competition?.end_date);
}

export function getSharingText(sweep, code) {
  return encodeURIComponent(`I've picked my team for the ${
    sweep?.organisation?.name
  } sweep!
  
There's great prizes and it's supporting the club. 
  
Get involved and enter your team at ${getReferralLink(sweep, code)}

@thesweeps_io`);
}

export function prettyDate(date) {
  return dateFormat(date, 'mmmm dS, h:MM TT');
}
