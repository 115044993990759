/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function CardError(props) {
  const { error } = props;
  return (
    <React.Fragment>
      {error && (
        <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-red">
          <p className="w3-margin-top">
            <i className={`fa fa-warning fa-fw w3-margin-right`} />
            {error} Your team has not been submitted. Please try again.
          </p>
        </div>
      )}
    </React.Fragment>
  );
}

export default CardError;
