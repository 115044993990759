import React, { useEffect } from 'react';
import Contact from '../Contact/Contact';
import AcceptingEntries from './AcceptingEntries';
import { Link } from 'react-router-dom';
import TestimonialsWrapper from './TestimonialsWrapper';

function ClubsInformation() {
  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
  }, []);

  return (
    <div className="w3-container">
      <h2 className="w3-margin sweeps-font-bold w3-text-teal">
        Want to run a sweep as a club / charity?
      </h2>
      <div className="w3-row">
        <div className="w3-quarter">
          <div className="w3-margin w3-card">
            <img src="/club-info.png" style={{ width: '100%' }} />
          </div>
        </div>
        <div className="w3-threequarter">
          <div className="w3-card w3-margin w3-teal w3-padding">
            <p>
              At Sweeps, we provide clubs and charities with software to run fun
              and engaging competitions for members to enjoy, to add an extra
              level of excitement to major sporting events, and raise much
              needed funds (without it feeling like fundraising).
            </p>

            <p>
              Helping the likes of Louth Ladies Gaelic Football raise €5,600
              during The Masters 2023 golf tournament, we can add a handful of
              sweeps a year to a club’s fundraising efforts and help reduce the
              financial burden most clubs battle on an annual basis.
            </p>
          </div>
          <div className="w3-card w3-margin w3-white w3-padding">
            <h3 className="w3-margin sweeps-font-bold w3-text-teal">
              The Three things we’ll need you to do:
            </h3>
            <ul>
              <li>
                <b>Secure Lotto Licence</b>
              </li>
              <li>
                <b>Setup Stripe</b>
              </li>
              <li>
                <b>Drive Entries</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w3-row w3-margin-bottom ">
        <div className="w3-container w3-margin-top">
          <div className="w3-card w3-white">
            <AcceptingEntries />
          </div>
        </div>
      </div>
      <div className="w3-row">
        <h2 className="w3-margin sweeps-font-bold w3-text-teal">
          Selection of who we've worked with:
        </h2>
        <div className="w3-quarter">
          <div className="w3-card w3-margin">
            <Link to="/masters23/leaderboard">
              <img src="/louth_gaa.jpeg" style={{ width: '100%' }} />
            </Link>
          </div>
        </div>
        <div className="w3-quarter">
          <div className="w3-card w3-margin">
            <Link to="https://thesweeps.io/belmulletgc/leaderboard">
              <img src="/belmullet.png" style={{ width: '100%' }} />
            </Link>
          </div>
        </div>
        <div className="w3-quarter">
          <div className="w3-card w3-margin">
            <Link to="https://thesweeps.io/ymcacc/leaderboard">
              <img src="/ymca.png" style={{ width: '100%' }} />
            </Link>
          </div>
        </div>
        <div className="w3-quarter">
          <div className="w3-card w3-margin">
            <Link to="https://thesweeps.io/gersgaa/leaderboard">
              <img src="/gers.png" style={{ width: '100%' }} />
            </Link>
          </div>
        </div>
      </div>
      <TestimonialsWrapper />
      <div className="w3-row">
        <h2 className="w3-margin sweeps-font-bold w3-text-teal">
          Types of Events we support:
        </h2>
        <div className="w3-third">
          <div className="w3-card w3-margin">
            <Link to="https://thesweeps.io/masters23/leaderboard">
              <img src="/golf.png" style={{ width: '100%' }} />
            </Link>
          </div>
        </div>
        <div className="w3-third">
          <div className="w3-card w3-margin">
            <img src="/rugby.png" style={{ width: '100%' }} />
          </div>
        </div>
        <div className="w3-third">
          <div className="w3-card w3-margin">
            <img src="/more.png" style={{ width: '100%' }} />
          </div>
        </div>
      </div>
      <h2 className="w3-margin sweeps-font-bold w3-text-teal">
        The Process of Running Your Own Sweep:
      </h2>
      <div className="w3-row">
        <div className="w3-card w3-margin w3-white w3-padding">
          <p>
            We will only require 3 calls with one person from your club/ charity
            to get set up, and provide everything else you need to drive entries
            from your club and make it a success.
          </p>

          <p>Here’s how it will look:</p>
          <ul>
            <li>You register interest in running a sweeps.</li>
            <li>We reach out, confirm, and get things in motion.</li>
            <li>
              You will need to apply for a lotto permit (details will shared via
              email).
            </li>
            <li>
              We will then get in touch and discuss the goals of the fundraiser:
              How much you want to raise, what the prizes should be (matrix
              guideline can be provided), and how many entries you need to
              achieve these numbers.
            </li>
            <li>
              If you don’t have a Stripe account already, you will register for
              one and connect it to ours (details will be given & we can help
              set this up).
            </li>
            <li>
              Your very own sweep will be generated and dates for entries to
              open will be set.
            </li>
            <li>
              We jump on our second call to show you your sweep, make sure the
              branding is up to par (wording across the site, logos are correct,
              etc). Here, we will also give you the unique website your sweeps
              will sit on (ie: www.thesweeps.io/yourclub). We will also share
              the media kit.
            </li>
            <li>
              Before the event, we will have one last call to make sure you have
              everything you need to promote your sweep and enable your club to
              achieve the fundraising target. When you’re ready to go, spread
              the good word, share it to your member base, and allow it to take
              flight!
            </li>
            <li>
              When entries open, we will keep in touch to make sure things are
              trending in the right direction, but we also directly handle any
              and all queries or issues your users are having.
            </li>
            <li>
              {' '}
              When entries close, we will reach out and talk about how
              everything went, hopefully celebrating a great turnout!
            </li>
            <li>
              For the duration of the tournament, your entrants will have access
              to your own branded live sweeps leaderboard to show them how
              they’re doing in real-time. We will also share emails of the
              tournament with your entrants, keeping them engaged and adding an
              extra level of excitement in what will hopefully be a great
              tournament.
            </li>
            <li>
              At the conclusion of the tournament, you will contact the winners
              and send them their winnings within 7 days of the sweeps ending.
            </li>
            <li>
              We will hopefully have achieved the fundraising target, discuss
              what we did well/ could have done better, and let you know when
              our next sweeps will be so that we can do it all over again!
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ClubsInformation;
