import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useOrganisations } from '../../../features/useOrganisations';

export default function SelectOrganisation(props) {
  const { id, handleChange } = props;

  const { getOrganisationsArray } = useOrganisations();
  const organisations = getOrganisationsArray();

  const handleLocalChange = (event) => {
    handleChange(event);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Organisation</InputLabel>
        <Select
          labelId="organisation-select-label"
          id="organisation-simple-select"
          value={id}
          defaultValue={id}
          label="Organisation"
          name="organisation_id"
          onChange={handleLocalChange}
        >
          {organisations.map((org) => {
            return (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
