/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function Card(props) {
  const { children } = props;

  return <div className="w3-card w3-white w3-margin">{children}</div>;
}

export default Card;
