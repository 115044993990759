import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTransformationView from './DataTransformationView';

function DataTransformationContainer(props) {
  const { user } = props;

  const navigate = useNavigate('');

  useEffect(() => {
    if (!user?.adminAccess) {
      navigate('/');
    }
  }, [user]);

  return <DataTransformationView user={user} />;
}

export default DataTransformationContainer;
