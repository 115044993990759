/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Card from '../Layouts/Card';
import { getUrlAlias } from '../../Helpers/SweepsHelper';
import { getReferralLink } from '../../Helpers/ReferralsHelper';
import { CopyReferralCode } from '../Referrals/CopyReferralCode';
import { Link } from 'react-router-dom';

function Success(props) {
  const { sweep, getTwitterShareText, myReferralCode, clearState } = props;
  return (
    <Card>
      <div className="w3-container">
        <>
          <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
            Entry Confirmed
          </h3>

          <h4 className="w3-center">
            <i className="fa fa-check w3-jumbo w3-text-teal w3-center"></i>
          </h4>

          {!sweep?.private_sweep && (
            <>
              <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
                Fancy a free entry?
              </h3>
              <p className="w3-center">
                Invite friends to get yourself a free entry! Share{' '}
                <a href={getReferralLink(sweep, myReferralCode)}>
                  your referral link
                </a>{' '}
                with your friends.
              </p>
              <p className="w3-center">
                Every 5th team entered using your link will earn you a free
                entry to this sweep.
              </p>
            </>
          )}

          <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
            Leaderboard
          </h3>
          <p className="w3-center">
            The leaderboard will be available{' '}
            <Link to={'/' + getUrlAlias(sweep) + '/leaderboard'}>here</Link>
          </p>

          {/* TODO: clean this up after RWC giveaway */}
          {sweep?.alias != 'rwc' && (
            <>
              <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
                Next Steps
              </h3>
              <ul className="w3-center" style={{ listStyleType: 'none' }}>
                <li>
                  You can edit your picks until the entries close on
                  {new Date(sweep?.competition?.entry_close_date).toUTCString()}
                  , by logging in and selecting edit against your entry.
                </li>
                <li>
                  The Leaderboard will live on the{' '}
                  {new Date(sweep?.competition?.entry_close_date).toUTCString()}
                </li>
                <li>
                  When the Live Leaderboard is available you will be able to see
                  your selections, and more importantly, how you're doing
                  throughout the competition in real-time as it updates
                  automatically.
                </li>
              </ul>

              <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
                Sharing
              </h3>
              <h4 className="w3-center">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    'https://twitter.com/intent/tweet?text=' +
                    getTwitterShareText(sweep, myReferralCode)
                  }
                >
                  <i className="fa fa-twitter  w3-jumbo w3-text-teal w3-center"></i>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    'https://wa.me/?text=' +
                    getTwitterShareText(sweep, myReferralCode)
                  }
                  data-action="share/whatsapp/share"
                >
                  <i className="fa fa-whatsapp w3-margin-left w3-jumbo w3-text-teal w3-center"></i>
                </a>
              </h4>

              <CopyReferralCode sweep={sweep} referralCode={myReferralCode} />
            </>
          )}

          {sweep?.alias == 'rwc' && (
            <>
              <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
                Prize
              </h3>
              <p className="w3-center">
                One winner with the correct score bracket will be picked at
                random. The random selection will be recorded and shared on the
                sweeps socials feeds!
              </p>
              <h3 className="sweeps-font-bold w3-text-teal w3-margin-top w3-center">
                About Sweeps
              </h3>
              <p className="w3-center">
                Welcome to Sweeps, where fundraising meets excitement and
                community spirit! We are dedicated to revolutionizing the way
                clubs and charities raise funds by providing engaging and
                effortless online sweepstakes for their members and friends
                alike.
              </p>
              <p className="w3-center">
                At Sweeps, our mission is simple yet powerful: to build engaging
                and fun new ways for clubs and charities to fundraise with ease.
                We understand the importance of creating an enjoyable and
                seamless fundraising experience for both participants and
                organizers alike.
              </p>

              <p className="w3-center">
                <Link to="how-it-works">Find out more about how it works?</Link>
              </p>
            </>
          )}

          {clearState && (
            <button
              onClick={() => clearState()}
              className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
            >
              Enter Again
            </button>
          )}
        </>
      </div>
    </Card>
  );
}

export default Success;
