import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const editEntry = (newEntry, callback) => {
  let user;
  const localStorageUser = localStorage.getItem('user');
  if (localStorageUser) {
    user = JSON.parse(localStorageUser);
  }
  if (user) {
    axios
      .post(
        API_URL + '/entries/edit',
        { entry: newEntry },
        {
          headers: {
            Authorization: `Bearer ${user.id_token}`,
          },
        },
      )
      .then(function (response) {
        callback(response);
      })
      .catch(function (error) {
        callback(error);
      });
  }
};
