import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchReferralCodes,
  getCodes,
  getCodeStatus,
} from './referrals/referralsSweepSlice';

export const useLoadSweepsReferralCodes = (sweep, user) => {
  const codeStatus = useSelector((state) => getCodeStatus(state, sweep?.id));

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (!codeStatus || codeStatus === 'idle' || codeStatus === undefined) &&
      user != undefined &&
      sweep?.id != undefined
    ) {
      dispatch(fetchReferralCodes({ user, sweepId: sweep?.id }));
    }
  }, [codeStatus, sweep?.id, user]);

  const getReferralCodes = () => {
    return useSelector((state) => getCodes(state, sweep?.id));
  };

  return {
    getReferralCodes,
  };
};
