import React from 'react';
import Testimonial from './Testimonial';
import Slideshow from '../Layouts/Slideshow';

function TestimonialsWrapper() {
  return (
    <div className="w3-row-padding w3-margin-bottom w3-margin-top">
      <h2 className="sweeps-font-bold w3-text-sviolet w3-center">
        Testimonials
      </h2>
      <div className="w3-container w3-margin-top">
        <div className="w3-card w3-white">
          <Slideshow
            data={[
              {
                component: (
                  <Testimonial
                    organisation="John Temple, Louth LGFA"
                    raised={5600}
                    logo={'/louth_gaa.jpeg'}
                    text="“We used the services of Sweeps in a recent fundraising drive.
                            We found it very easy to promote and very easy to operate from
                            our viewpoint. It was also a very successful fundraiser which
                            our supporters enjoyed.”"
                  />
                ),
                link: '/masters23/leaderboard',
              },
              {
                component: (
                  <Testimonial
                    organisation="Eugene Galligan, Belmullet GC"
                    raised={1952}
                    logo={'/louth_gaa.jpeg'}
                    text="“Conor and the team were very professional and made the entire process
                            simple and easy to operate, with continuous updates throughout the tournament
                            making a truly live event, and we surpassed our target for raising funds for 
                            our centenary year.”"
                  />
                ),
                link: '/belmulletgc/leaderboard',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default TestimonialsWrapper;
