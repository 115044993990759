/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import Group from '../../SweepsSignup/Golf/Group';
import { toast } from 'react-toastify';
import SelectionsContainer from '../../SweepsSignup/Golf/SelectionsContainer';
import Tiebreak from '../../SweepsSignup/Tiebreak';
import NickNameSlide from '../../SweepsSignup/NickNameSlide';
import { getPlayersDataForSweep } from '../../../Helpers/PlayerHelper';
import { logMetric } from '../../../Helpers/AnalyticsHelper';
import { editEntry } from '../../../Helpers/EntriesHelper';

function EditEntry(props) {
  const { entry, refreshEntries, sweep, visible } = props;

  const scrollToReference = useRef(null);
  const executeScroll = () => scrollToReference.current.scrollIntoView();

  const [playersFileLoaded, setPlayersFileLoaded] = useState(false);
  const [state, setState] = React.useState({
    group_1: entry.group_1,
    group_2: entry.group_2,
    group_3: entry.group_3,
    group_4: entry.group_4,
    group_5: entry.group_5,
    players: [],
    groupOnePlayers: [],
    groupTwoPlayers: [],
    groupThreePlayers: [],
    groupFourPlayers: [],
    groupFivePlayers: [],
    tieBreaker: entry.tie_breaker,
    activeSlide: 0,
    errors: {},
    nickName: entry.nickName,
    successfulSignup: false,
    editAgain: false,
  });

  useEffect(() => {
    if (sweep && visible && !playersFileLoaded) {
      getPlayersDataForSweep(sweep, updateGroupsState);
      setPlayersFileLoaded(true);
    }
  }, [sweep, visible]);

  const updateGroupsState = (playersData) => {
    setState({
      ...state,
      players: playersData,
      groupOnePlayers: playersData.filter((player) => player.group === 1),
      groupTwoPlayers: playersData.filter((player) => player.group === 2),
      groupThreePlayers: playersData.filter((player) => player.group === 3),
      groupFourPlayers: playersData.filter((player) => player.group === 4),
      groupFivePlayers: playersData.filter((player) => player.group === 5),
    });
  };

  const nextSlide = () => {
    executeScroll();
    setState({
      ...state,
      activeSlide: state.activeSlide + 1,
    });
  };

  const previousSlide = (slide) => {
    executeScroll();
    const slideNumber = slide ? slide : state.activeSlide - 1;
    setState({
      ...state,
      activeSlide: slideNumber,
    });
  };

  function handlePlayerPicked(id, group) {
    setState({
      ...state,
      [group]: id,
    });
  }

  function editAgain() {
    setState({
      ...state,
      successfulSignup: false,
      editAgain: true,
      activeSlide: 0,
    });
  }

  const handleSubmissionResponse = (response) => {
    if (response.data.message.includes('Successfully edited entry')) {
      logMetric('entries', 'successfulEdit');
      setState({
        ...state,
        successfulSignup: true,
      });
      refreshEntries();
    } else {
      logMetric('entries', 'failedEdit');
      setState({
        ...state,
        activeSlide: 12,
        errors: {
          ...state.errors,
          editEntry: {
            messages: [response.response.data.message],
          },
        },
      });
    }
  };

  const submitSelections = () => {
    if (sweep) {
      const newEntry = {
        id: entry.id,
        nickName: state.nickName,
        group_1: state.group_1,
        group_2: state.group_2,
        group_3: state.group_3,
        group_4: state.group_4,
        group_5: state.group_5,
        sweep_id: sweep.id,
        tie_breaker: state.tieBreaker,
      };
      editEntry(newEntry, handleSubmissionResponse);
    }
  };

  function handleChange(evt) {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function onBlur(evt) {
    const value = evt.target.value;
    let messages = [];
    let addErrors = false;

    if (!value || !/\S/.test(value)) {
      addErrors = true;
      toast.error('Mandatory field, please enter a value', {
        toastId: 'mandatory_field',
      });
      messages.push('Mandatory field, please enter a value');
    }

    if (addErrors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: messages,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
        },
      });
    }
  }

  return (
    <React.Fragment>
      <>
        <div id={'editEntry' + entry.id} className="w3-modal w3-medium">
          <div
            className="w3-modal-content"
            style={{ backgroundColor: 'transparent' }}
          >
            <span
              onClick={() => {
                document.getElementById(`editEntry${entry.id}`).style.display =
                  'none';
              }}
              className="pointer w3-button w3-xlarge w3-display-topright times"
            >
              &times;
            </span>
            <SelectionsContainer
              sweep={sweep}
              group_1={state.group_1}
              group_2={state.group_2}
              group_3={state.group_3}
              group_4={state.group_4}
              group_5={state.group_5}
            />

            <div className="w3-row-padding w3-margin-top">
              <div ref={scrollToReference}></div>
              <div className="w3-row">
                <div className="w3-container w3-card w3-white">
                  {state.successfulSignup && (
                    <>
                      <h3 className="sweeps-font-bold w3-text-teal w3-margin-top">
                        Entry Successfully Edited
                      </h3>
                      <button
                        onClick={() => editAgain()}
                        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
                      >
                        Update your entry
                      </button>
                      <button
                        onClick={() => {
                          document.getElementById(
                            `editEntry${entry.id}`,
                          ).style.display = 'none';
                        }}
                        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
                      >
                        Return to dashboard
                      </button>
                    </>
                  )}
                  {!state.successfulSignup && (
                    <form
                      className="w3-container"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      {state.activeSlide === 0 && (
                        <Group
                          allPlayersData={state.players}
                          players={state.groupOnePlayers}
                          selected={state.group_1}
                          groupTitle="Group 1"
                          groupKey="group_1"
                          handlePlayerPicked={handlePlayerPicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                          hidePrevious={true}
                        />
                      )}

                      {state.activeSlide === 1 && (
                        <Group
                          allPlayersData={state.players}
                          players={state.groupTwoPlayers}
                          selected={state.group_2}
                          groupTitle="Group 2"
                          groupKey="group_2"
                          handlePlayerPicked={handlePlayerPicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}

                      {state.activeSlide === 2 && (
                        <Group
                          allPlayersData={state.players}
                          players={state.groupThreePlayers}
                          selected={state.group_3}
                          groupTitle="Group 3"
                          groupKey="group_3"
                          handlePlayerPicked={handlePlayerPicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}

                      {state.activeSlide === 3 && (
                        <Group
                          allPlayersData={state.players}
                          players={state.groupFourPlayers}
                          selected={state.group_4}
                          groupTitle="Group 4"
                          groupKey="group_4"
                          handlePlayerPicked={handlePlayerPicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}

                      {state.activeSlide === 4 && (
                        <>
                          <Group
                            allPlayersData={state.players}
                            players={state.groupFivePlayers}
                            selected={state.group_5}
                            groupTitle="Group 5"
                            groupKey="group_5"
                            handlePlayerPicked={handlePlayerPicked}
                            previousSlide={previousSlide}
                            nextSlide={nextSlide}
                          />
                        </>
                      )}

                      {state.activeSlide === 5 && (
                        <Tiebreak
                          onBlur={onBlur}
                          handleChange={handleChange}
                          nextSlide={nextSlide}
                          previousSlide={previousSlide}
                          tieBreaker={state.tieBreaker}
                          label="Tiebreaker: The winning score of the tournament will be [ ] Under Par:"
                        />
                      )}

                      {state.activeSlide === 6 && (
                        <NickNameSlide
                          onBlur={onBlur}
                          handleChange={handleChange}
                          nextSlide={submitSelections}
                          lastStep={true}
                          previousSlide={previousSlide}
                          nickName={state.nickName}
                        />
                      )}

                      {state.activeSlide === 12 &&
                        state.errors['editEntry'] && (
                          <>
                            <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-red">
                              <p className="w3-margin-top">
                                <i
                                  className={`fa fa-warning fa-fw w3-margin-right`}
                                />
                                {state.errors['editEntry'].messages[0]}
                              </p>
                            </div>
                            <button
                              onClick={() => previousSlide(5)}
                              className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
                            >
                              Back
                            </button>
                          </>
                        )}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}

export default EditEntry;
