/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Modal from '../../Layouts/Modal';
import EditPost from './EditPost';

function EditPostModal(props) {
  const { post, user } = props;
  return (
    <React.Fragment>
      <Modal
        id={`editPost-${post.id}`}
        child={<EditPost user={user} post={post} />}
      />
    </React.Fragment>
  );
}

export default EditPostModal;
