import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid';
import { useLoadSweepsReferralCodes } from '../../features/useLoadSweepsReferralCodes';

const columns = [
  {
    field: 'email',
    headerName: 'Email',
    width: 400,
  },
  {
    field: 'usage_count',
    headerName: 'Usage Count',
    width: 150,
  },
  {
    field: 'total_count',
    headerName: 'Total Count',
    width: 150,
  },
];

export default function ReferralsDataTable(props) {
  const { sweep, user } = props;
  const { getReferralCodes } = useLoadSweepsReferralCodes(sweep, user);
  const codes = getReferralCodes();

  return (
    <Box sx={{ height: 400, width: '100%', marginBottom: '40px' }}>
      <h5 className="sweeps-font-bold">Used Referral Codes:</h5>
      <hr />
      <DataGrid
        rows={codes || []}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
        }}
        getRowId={(row) => row.sweep_id + row.code}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50, 100]}
      />
    </Box>
  );
}
