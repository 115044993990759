import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Form from './Form';
import { editCompetition } from '../../Helpers/CompetitionHelper';
import SuccessPanel from '../Form/SuccessPanel';

function EditCompetition(props) {
  const { user, competition, refreshCompetitions } = props;

  const navigate = useNavigate('');
  if (!user || !user?.adminAccess) {
    navigate('/');
  }

  const existingLatestTeeTime = competition.last_tee_time
    ? new Date(competition.last_tee_time)
    : new Date();

  const [state, setState] = React.useState({
    id: competition.id,
    name: competition.name,
    tiebreaker_question: competition.tiebreaker_question || '',
    sport: competition.sport,
    format: competition.format,
    start_date: new Date(competition.start_date),
    end_date: new Date(competition.end_date),
    entry_open_date: new Date(competition.entry_open_date),
    entry_close_date: new Date(competition.entry_close_date),
    last_tee_time: existingLatestTeeTime,
    url: competition.url,
    makeCut: competition.makeCut,
    file: undefined,
    configFile: undefined,
    successfulCreate: false,
    failedCreate: false,
    errors: {},
  });

  function setDate(key, date) {
    setState({
      ...state,
      [key]: date,
    });
  }

  function onBlur(evt) {
    const value = evt.target.value;
    let messages = [];
    let addErrors = false;

    if (!value || !/\S/.test(value)) {
      addErrors = true;
      messages.push('Mandatory field, please enter a value');
    }

    if (addErrors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: messages,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
          form: undefined,
        },
      });
    }
  }

  function handleChange(evt) {
    const value =
      evt.target.type === 'file' ? evt.target.files[0] : evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function handleEditCompetitionResponse(response) {
    if (response.data) {
      if (response.status === 200) {
        setState({
          ...state,
          failedCreate: false,
          successfulCreate: true,
        });
        refreshCompetitions();
      }
    }
    if (response.response) {
      setState({
        ...state,
        successfulCreate: false,
        failedCreate: true,
      });
    }
  }

  return (
    <div className="w3-padding">
      {state.successfulCreate && (
        <SuccessPanel message="Successfully edited competition" />
      )}
      {state.failedCreate && 'Failed to edit competition'}
      <h3 className="sweeps-font-bold w3-text-teal">Edit Competition</h3>
      <hr />
      <img
        className="center"
        style={{ maxHeight: '300px' }}
        src={competition.image_url}
      />
      <hr />
      <hr />
      {competition.config_file}
      <hr />
      <Form
        errors={state.errors}
        onBlur={onBlur}
        handleChange={handleChange}
        setDate={setDate}
        name={state.name}
        tiebreaker_question={state.tiebreaker_question}
        sport={state.sport}
        format={state.format}
        url={state.url}
        start_date={state.start_date}
        end_date={state.end_date}
        entry_open_date={state.entry_open_date}
        entry_close_date={state.entry_close_date}
        last_tee_time={state.last_tee_time}
        makeCut={state.makeCut}
      />

      <button
        onClick={() =>
          editCompetition(user, state, handleEditCompetitionResponse)
        }
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Edit
      </button>
    </div>
  );
}

export default EditCompetition;
