import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

function OrganisationsDetails(props) {
  const { organisation } = props;

  const admins = organisation?.admins.map((admin) => ` ${admin}`).join();
  return (
    <>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem>
          <ListItemText primary={admins} secondary="Admins" />
        </ListItem>
        <ListItem>
          <ListItemText primary={organisation?.location} secondary="Location" />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={organisation?.primarySport}
            secondary="Sport"
          />
        </ListItem>
      </List>
    </>
  );
}

export default OrganisationsDetails;
