import React, { useEffect, useRef, useState } from 'react';

import TournamentLeaderboard from './TournamentLeaderboard';
import GolfLeaderboardTable from './GolfLeaderboardTable';

import DreamTeam from './DerivedMetadata/DreamTeam';
import MostPicked from './DerivedMetadata/MostPicked';

import { getGroups } from '../../../Helpers/PlayerHelper';
import Supporting from '../Shared/Supporting';
import OwnSweep from '../Shared/OwnSweep';
import Card from '../../Layouts/Card';
import { fetchLeaderboard } from '../../../Helpers/LeaderboardHelper';
import { isTournamentFinished } from '../../../Helpers/MiscHelpers';
import { getPlayersDataForSweep } from '../../../Helpers/PlayerHelper';
import SmallPostsList from '../../Blog/SmallPostsList';
import PrizeWrapper from '../../Prizes/Wrapper';

const masters = true;
const color = masters ? 'w3-teal' : 'w3-indigo';
const textColor = masters ? 'w3-text-teal' : 'w3-text-indigo';

function GolfLeaderboard(props) {
  const mountedRef = useRef();
  const [liveScores, setLiveScores] = useState([]);
  const [results, setResults] = useState([]);
  const [stats, setStats] = useState({});
  const [updatedScores, setUpdatedScores] = useState(new Date());
  const [playersData, setPlayersData] = useState([]);
  const [dreamTeam, setDreamTeam] = useState({});
  const [mostPicked, setMostPicked] = useState([]);
  const { user, sweep } = props;

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  const updatePlayersState = (playersData) => {
    setPlayersData(playersData);
  };

  useEffect(() => {
    let stopFetchingId;
    if (mountedRef.current && sweep) {
      getPlayersDataForSweep(sweep, updatePlayersState);
      fetchLeaderboard(sweep.id, updateGolfScoresState);
      stopFetchingId = setInterval(
        () => fetchLeaderboard(sweep.id, updateGolfScoresState),
        180000,
      ); //180000
    }
    return () => {
      clearInterval(stopFetchingId);
    };
  }, [sweep]);

  function updateGolfScoresState(response) {
    if (response.data) {
      setUpdatedScores(new Date());
      setLiveScores(response.data.players);
      setResults(response.data.results);
      setStats(response.data.stats);
      setDreamTeam(response.data.dream_team);
      setMostPicked(response.data.most_picked);
    }
  }

  function hasAllStarted() {
    if (sweep) {
      return new Date() > new Date(sweep?.competition?.last_tee_time);
    }
    return false;
  }

  let date = new Date();
  let missedCuts;
  if (
    date.getDay() > 5 ||
    date.getDay() === 0 ||
    isTournamentFinished(sweep.competition)
  ) {
    missedCuts = (
      <>
        <p>% Entries Missed Cut</p>
        <div className="w3-light-grey w3-round-xlarge w3-small">
          <div
            className={`w3-container w3-center w3-round-xlarge ${color}`}
            style={{ width: stats.missed_cuts_percent }}
          >
            {stats.missed_cuts_percent}
          </div>
        </div>
      </>
    );
  } else if (hasAllStarted()) {
    missedCuts = (
      <>
        <p>% Entries At risk of missing cut</p>
        <div className="w3-light-grey w3-round-xlarge w3-small">
          <div
            className={`w3-container w3-center w3-round-xlarge ${color}`}
            style={{ width: stats.percent_at_risk }}
          >
            {stats.percent_at_risk}
          </div>
        </div>
      </>
    );
  }

  const groupsData = getGroups(playersData);

  return (
    <>
      <div className="w3-row-padding">
        <div className="w3-twothird">
          <div className="w3-container w3-card w3-margin-top w3-white">
            <GolfLeaderboardTable
              sweep={sweep}
              textColor={textColor}
              updatedScores={updatedScores}
              liveScores={liveScores}
              user={user}
              results={results}
            />
          </div>
        </div>
        <div className="w3-third">
          <div className="w3-container w3-text-grey">
            <Card>
              <Supporting sweep={sweep} entryCount={sweep?.entry_count} />
            </Card>
            <Card>
              <div className="w3-padding">
                <PrizeWrapper sweep={sweep} />
              </div>
            </Card>
            <Card>
              <div className="w3-padding">
                {missedCuts}
                {missedCuts && <hr />}
                <DreamTeam textColor={textColor} team={dreamTeam} />
                <hr />
                <MostPicked
                  textColor={textColor}
                  sweepstakesData={results}
                  mostPicked={mostPicked}
                />
              </div>
            </Card>
            <Card>
              <div className="w3-padding">
                <TournamentLeaderboard
                  groupsData={groupsData}
                  competition={sweep.competition}
                  textColor={textColor}
                  liveScores={liveScores}
                />
              </div>
            </Card>
            <Card>
              <SmallPostsList competitionId={sweep?.competition?.id} />
            </Card>
            <Card>
              <div>
                <OwnSweep />
                <hr />
                <section className="w3-container">
                  <p className={`w3-large sweeps-font-bold ${textColor}`}>
                    <b>The Unplayable</b>
                  </p>
                  <iframe
                    src="https://open.spotify.com/embed/show/5OsOHwevnI6UhwTgxeYDTQ"
                    width="100%"
                    height="232"
                    frameBorder="0"
                    allowtransparency="true"
                    title="Unplayable Podcast"
                    allow="encrypted-media"
                  ></iframe>
                </section>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default GolfLeaderboard;
