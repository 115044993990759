/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { getPlayerFromKey } from '../../Helpers/PlayerHelper';

function PlayerAvatar(props) {
  const { allPlayersData, handlePlayerPicked, group, id, active, jumbo } =
    props;

  const canClick = handlePlayerPicked ? true : false;
  const textSize = jumbo ? 'w3-xlarge sweeps-font-bold' : '';
  const player = getPlayerFromKey(id, allPlayersData);
  const border = active
    ? 'w3-bar w3-border w3-round w3-teal w3-opacity pointer h-100'
    : 'w3-bar pointer h-100';
  return (
    <React.Fragment>
      <li
        className={border}
        onClick={canClick ? () => handlePlayerPicked(id, group) : undefined}
      >
        <img
          src={player?.avatar ? player.avatar : '/avatar.webp'}
          style={{ aspectRatio: '4.5/3.7' }}
          className={'w3-circle centeredImage'}
        />
        <div
          className="w3-bar-item"
          style={{ width: '100%', textAlign: 'center' }}
        >
          <span className={'w3-large w3-center ' + textSize}>
            {player?.name}
          </span>
        </div>
      </li>
    </React.Fragment>
  );
}

export default PlayerAvatar;
