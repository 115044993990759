import {
  Button,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ContactModal } from './ContactModal';

function SalesLanding() {
  const navigate = useNavigate('');
  return (
    <>
      <div className="w3-row">
        <div className="w3-container">
          <h1
            className="w3-text-teal sweeps-font-medium"
            style={{ fontSize: '55px' }}
          >
            The Future of Fundraising
          </h1>

          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Typography variant="p" className="center">
                Sweeps offer thrilling prediction games for top sporting events,
                enabling clubs and charities to raise vital funds. Join us for a
                unique blend of entertainment and social impact, where
                predicting outcomes becomes a rewarding experience for sports
                fans and a meaningful contribution to community causes.
              </Typography>
            </Grid>
            <Grid
              container
              xs={12}
              md={8}
              sx={{
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid item sm={12} md={4}>
                <div
                  className=""
                  style={{
                    margin: 'auto',
                    borderRadius: '100%',
                    height: '200px',
                    width: '200px',
                    border: '3px solid',
                    backgroundColor: 'white',
                    borderColor: '#006747',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <h4 className="w3-text-sviolet sweeps-font-bold w3-center">
                    €1498 Average Rasied <i>per sweep</i>
                  </h4>
                </div>
              </Grid>
              <Grid item md={8} xs={12}>
                <List
                  className="sweeps-font-bold w3-text-sviolet"
                  sx={{ width: '100%' }}
                >
                  <ListItem className="sweeps-font-bold">
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="h5"
                          className="sweeps-font-bold"
                          style={{ fontFamily: 'hattonBold' }}
                        >
                          Interactive Sports Games
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem className="sweeps-font-bold">
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="h5"
                          className="sweeps-font-bold"
                          style={{ fontFamily: 'hattonBold' }}
                        >
                          Free to use
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem className="sweeps-font-bold">
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="h5"
                          className="sweeps-font-bold"
                          style={{ fontFamily: 'hattonBold' }}
                        >
                          Infinately repeatable
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={8} xs={12}>
                <Typography
                  variant="h5"
                  className="sweeps-font-bold center w3-text-teal"
                  style={{ fontFamily: 'hattonBold' }}
                >
                  "Sweeps took the admin headaches away, <br /> all we had to do
                  was get people involved"
                </Typography>
                <Typography
                  variant="body2"
                  className="center"
                  sx={{ marginBottom: '10px' }}
                >
                  Jack Tector, YMCA Cricket Club
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={() => navigate('/how-it-works')}
                  sx={{
                    width: '95%',
                    borderRadius: '10px',
                    backgroundColor: '#006747',
                  }}
                  className="hover-white"
                >
                  <Typography>Run your own Sweep</Typography>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{
                    width: '95%',
                    borderRadius: '10px',
                    backgroundColor: '#006747',
                  }}
                  className="hover-white"
                  onClick={() => {
                    document.getElementById('contactModal').style.display =
                      'block';
                  }}
                >
                  <Typography>Schedule a Call</Typography>
                </Button>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid md={4} xs={'none'}>
                <img src={`HandSweeps.png`} alt={'Sweeps Hand'} />
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </div>
      <ContactModal />
    </>
  );
}

export default SalesLanding;
