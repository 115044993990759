/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Error from '../../Form/Error';
import Field from '../../Form/Fields/Field';

function ResetPassword(props) {
  const {
    email,
    newPassword,
    confirmNewPassword,
    verificationCode,
    errors,
    forgotPasswordCodeSent,
    handleChange,
    onBlur,
    setResetPassword,
    requestForgottenPasswordCode,
    verifyForgottenPasswordCode,
  } = props;
  return (
    <React.Fragment>
      <h4 className="sweeps-font-bold w3-large w3-text-teal w3-margin-top">
        Password Reset
      </h4>
      <Field
        label="Email"
        errors={errors}
        handleChange={handleChange}
        name="email"
        value={email}
      />
      {forgotPasswordCodeSent && (
        <>
          <Field
            label="Verification Code from Email"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="verificationCode"
            value={verificationCode}
            type="password"
          />
          <Field
            label="Password"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="newPassword"
            value={newPassword}
            type="password"
          />
          <Field
            label="Confirm Password"
            errors={errors}
            onBlur={onBlur}
            handleChange={handleChange}
            name="confirmNewPassword"
            value={confirmNewPassword}
            type="password"
          />
        </>
      )}
      <p>
        Back to{' '}
        <a className="w3-text-teal" onClick={() => setResetPassword(false)}>
          login
        </a>
      </p>
      {errors['form'] && <Error error={errors['form']} />}
      {!forgotPasswordCodeSent && (
        <button
          onClick={() => requestForgottenPasswordCode()}
          className="pointer w3-btn w3-margin-top w3-margin-bottom w3-teal"
        >
          Request Reset Code
        </button>
      )}
      {forgotPasswordCodeSent && (
        <button
          onClick={() => verifyForgottenPasswordCode()}
          className="pointer w3-btn w3-margin-top w3-margin-bottom w3-teal"
        >
          Update Password
        </button>
      )}
    </React.Fragment>
  );
}

export default ResetPassword;
