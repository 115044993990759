/* eslint-disable jsx-a11y/alt-text */
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      className="footer"
      style={{
        borderTopLeftRadius: '10%',
        borderTopRightRadius: '10%',
        marginTop: '20px',
        bottom: '0px',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h4"
            noWrap
            onClick={() => navigate('/')}
            className="pointer"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'hattonBold',
              fontWeight: 900,
              color: 'white',
              textDecoration: 'none',
              marginTop: '0 !important',
            }}
          >
            Sweeps
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Typography
              className="pointer"
              onClick={() => navigate('/faq')}
              sx={{
                my: 2,
                color: 'white',
                display: 'block',
              }}
            >
              Frequently Asked Questions
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Typography
              className="pointer"
              onClick={() => {
                window.location = 'mailto:ben@thesweeps.io,conor@thesweeps.io';
              }}
              sx={{
                marginLeft: '10px',
                my: 2,
                color: 'white',
                display: 'block',
              }}
            >
              Get Help
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Button
              onClick={() => navigate('/how-it-works')}
              sx={{
                my: 2,
                marginLeft: '10px',
                color: 'green',
                display: 'block',
                backgroundColor: 'white',
                fontFamily: 'hattonBold',
              }}
            >
              <span className="sweeps-font-medium">Run a sweep</span>
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Footer;
