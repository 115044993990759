import React from 'react';

function NCBI(props) {
  return (
    <React.Fragment>
      <h3 className="w3-large sweeps-font-bold w3-text-sviolet">
        Prize Details
      </h3>
      <ul
        style={{
          listStyle: 'none',
          marginBlockStart: '0',
          paddingInlineStart: '0',
        }}
      >
        <li className="w3-margin-bottom">
          🥇 = Dinner for 4 at{' '}
          <a
            href="https://www.clubhotel.ie/dine/"
            target="_blank"
            rel="noreferrer"
          >
            The Club at Goffs
          </a>{' '}
        </li>
        <li className="w3-margin-bottom">
          🥈 = Golf 4-ball at{' '}
          <a
            href="https://www.palmerstownhouse.ie/golf.html"
            target="_blank"
            rel="noreferrer"
          >
            Palmerstown Golf Club
          </a>
        </li>
        <li>
          🥉 = Admission for 4 to Goffs Million at The Curragh Saturday,
          September 28th 2024
        </li>
      </ul>
    </React.Fragment>
  );
}

export default NCBI;
