/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  getMatchesDataForSweep,
  getNumberOfGroups,
} from '../../../Helpers/RugbyMatchesHelper';

const apiUrl = process.env.REACT_APP_API_URL;

export const useRugbyForm = ({ sweep, setNumberOfGroups }) => {
  const [state, setState] = useState({
    picks: {},
    matches: [],
  });

  useEffect(() => {
    if (sweep && sweep.competition.sport === 'rugby') {
      getMatchesDataForSweep(sweep, updateMatches);
    }
  }, [sweep]);

  const updateMatches = (matchesData) => {
    setNumberOfGroups(getNumberOfGroups(matchesData));
    setState({
      ...state,
      matches: matchesData,
    });
  };

  function handleMatchPicked(matchId, lowerBound, upperBound, teamId, draw) {
    setState({
      ...state,
      picks: {
        ...state.picks,
        [matchId]: {
          matchId,
          teamId,
          lowerBound,
          upperBound,
          draw,
        },
      },
    });
  }

  const onSubmit = (
    userId,
    email,
    nickName,
    sweep,
    referralCode,
    paymentId,
    freeEntry,
    tieBreaker,
    callbackSuccess,
    callbackError,
  ) => {
    axios
      .post(apiUrl + '/signupEntry', {
        entry: {
          user_id: userId,
          email: email,
          nickName: nickName,
          picks: state.picks,
          sweep_id: sweep.id,
          referral_code: referralCode,
          free_entry: freeEntry || false,
          payment_intent_id: paymentId,
          tie_breaker: tieBreaker,
        },
      })
      .then(function (response) {
        if (response.data.message.includes('Successfully created entry')) {
          callbackSuccess(response.data.referral_code);
        }
      })
      .catch(function (error) {
        callbackError(error);
      });
  };

  const getObjectShape = (tieBreaker, nickName) => {
    return {
      picks: state.picks,
      tie_breaker: tieBreaker,
      nickName: nickName,
    };
  };

  return {
    handlers: {
      onSubmit,
      getObjectShape,
      handleMatchPicked,
    },
    context: {
      matches: state.matches,
      picks: state.picks,
    },
  };
};
