import React from 'react';
import { CardContent, CardHeader, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Table from '../../Layouts/Table';

function View(props) {
  const { users } = props;

  function getUsersColumns() {
    return [
      {
        name: '#',
        cell: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: 'Name',
        sortable: true,
        selector: (row) => row.Attributes.name,
      },
      {
        name: 'Email',
        sortable: true,
        selector: (row) => row.Attributes.email,
      },
      {
        name: 'Phone',
        sortable: true,
        selector: (row) => row.Attributes.phone_number,
      },
    ];
  }

  return (
    <Grid container spacing={1} padding={2}>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <h3 className="w3-text-teal sweeps-font-bold">Users</h3>
            <span>Unique Users Count: {users.length}</span> <br />
            <Table data={users} columns={getUsersColumns()} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default View;
