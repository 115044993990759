import React from 'react';

function OwnSweep() {
  return (
    <div className="w3-teal no-margin-top no-margin-bottom">
      <div
        className="w3-margin no-margin-top no-margin-bottom"
        style={{ padding: '5px' }}
      >
        <h3
          className={`w3-large  sweeps-font-bold no-margin-top no-margin-bottom `}
        >
          <b> Interested in running your own sweep?</b>
        </h3>
        <span
          onClick={() => {
            window.location = 'mailto:ben@thesweeps.io,conor@thesweeps.io';
          }}
          className="pointer w3-medium"
        >
          Get in touch and let’s discuss
        </span>
        <i
          onClick={() => {
            window.location = 'mailto:ben@thesweeps.io,conor@thesweeps.io';
          }}
          className=" pointer fa fa-thumbs-up w3-margin-left w3-margin-right"
        ></i>
      </div>
    </div>
  );
}

export default OwnSweep;
