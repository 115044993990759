import React from 'react';

function NCBI(props) {
  return (
    <React.Fragment>
      <h3 className="w3-large sweeps-font-bold w3-text-sviolet">
        Prize Details
      </h3>
      <ul
        style={{
          listStyle: 'none',
          marginBlockStart: '0',
          paddingInlineStart: '0',
        }}
      >
        <li className="w3-margin-bottom">
          🥇 = 3 rounds of golf in{' '}
          <a href="https://www.rosapenna.ie/" target="_blank" rel="noreferrer">
            Rosapena (Old Tom Morris Links, Sandy Hills Links & St. Patrick’s
            Links)
          </a>{' '}
          + 3 nights in the Downing’s Coast Guard cottages for 4 people
        </li>
        <li className="w3-margin-bottom">
          🥈 = Team of 4 entry to the Ronnie Whelan Golf Classic in{' '}
          <a href="https://killeencastle.com/" target="_blank" rel="noreferrer">
            Killeen Castle Golf Club
          </a>{' '}
          on October 12th
        </li>
        <li>
          🥉 = 4 ball in{' '}
          <a
            href="https://www.countylouthgolfclub.com/"
            target="_blank"
            rel="noreferrer"
          >
            County Louth Golf Club
          </a>
        </li>
      </ul>
      <i className="w3-small">
        Downing’s Coast Guard Cottage not available July-August and bank
        holidays.
      </i>
    </React.Fragment>
  );
}

export default NCBI;
