import { configureStore } from '@reduxjs/toolkit';
import sweepsReducer from '../features/sweeps/sweepsSlice';
import organisationsReducer from '../features/organisations/organisationsSlice';
import myOrganisationsReducer from '../features/organisations/myOrganisationsSlice';
import entrantsReducer from '../features/entrants/entrantsSlice';
import lottoLicensesReducer from '../features/lottoLicenses/lottoLicensesSlice';
import referralsForSweepReducer from '../features/referrals/referralsSweepSlice';

export default configureStore({
  reducer: {
    sweeps: sweepsReducer,
    organisations: organisationsReducer,
    myOrganisations: myOrganisationsReducer,
    entrants: entrantsReducer,
    lottoLicenses: lottoLicensesReducer,
    referralsForSweep: referralsForSweepReducer,
  },
});
