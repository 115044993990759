/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

function Terms(props) {
  useEffect(() => {
    document.body.style.backgroundColor = '#006747';
  }, []);

  return (
    <React.Fragment>
      <div className="w3-container  w3-margin-top w3-animate-opacity w3-text-white">
        <div className="w3-container">
          <h1 className="sweeps-font-bold w3-jumbo w3-animate-top w3-center">
            TERMS AND CONDITIONS{' '}
          </h1>
          <div className="w3-container w3-text-white">
            <ul>
              <li>
                Entry to the competition is €10 per ticket, and payment must be
                made online via thesweeps.io. Entrants will be required to
                provide their full name, email, and phone number, and each
                ticket purchased will be assigned a unique number.
              </li>
              <li>
                The prize fund for the competition will be stated on the signup
                page, and the prize will be awarded to the winners in the form
                of a cash prize.
              </li>
              <li>Prizes will be broken down as described on signup form</li>
              <li>
                10% of the fund will be going to the organisers of the
                competition.
              </li>
              <li>
                The competition will close on the day prior to the Competition.
                This time will be clearly advertised. Entries received after
                this time will not be considered.
              </li>
              <li>
                The winner will be determined within 24 hours of the end of the
                tournament, and the organisation running the sweep or Sweeps
                will notify the winner by email or phone. If the winner cannot
                be contacted or does not claim the prize within 7 days of
                notification, we reserve the right to withdraw the prize from
                the winner and pick a replacement winner.
              </li>
              <li>
                The sweep organiser and Sweeps reserves the right to cancel or
                amend the competition and these terms and conditions without
                notice in the event of a catastrophe, war, civil or military
                disturbance, act of God, or any actual or anticipated breach of
                any applicable law or regulation or any other event outside of
                the promoters control.
              </li>
              <li>
                The sweep organiser and Sweeps are not responsible for
                inaccurate prize details supplied to any entrant by any third
                party connected with this competition.
              </li>
              <li>
                The sweep organiser / Sweeps decision in respect of all matters
                to do with the competition will be final and no correspondence
                will be entered into.
              </li>
              <li>
                By entering this competition, an entrant is indicating their
                agreement to be bound by these terms and conditions.
              </li>
              <li>
                The competition is in aid of the organiser of the sweep, and
                proceeds will go towards funding their club / charity.
              </li>
              <li>
                Sweeps will send you updates about the tournament during the
                event.
              </li>
            </ul>
          </div>
          <h2 className="sweeps-font-bold w3-jumbo w3-animate-top w3-center">
            Golf Competitions{' '}
          </h2>
          <div className="w3-container w3-text-white">
            <ul>
              <li>
                Each entrant will select five golfers who are participating in
                the Golf Tournament. The winner of the competition will be
                determined by the entrant's picks in combination with the
                results of the tournament.
              </li>
              <li>
                In the scenario where both participants have selected either the
                same winning score prediction, or are both one shot off (ie
                finishing score: -10, entry one: -9, entry 2: -11), then the
                winner will be decided by best finishing position of their
                selected player in Group 1. If still a tie, best finishing
                position in Group 2, and so on. If both players have the exact
                same teams and the tiebreaker process cannot split them, the
                prizes of 1st and 2nd will be split evenly between them.
              </li>
              <li>
                If your player withdraws BEFORE the entry close of the
                tournament, you can edit your team to choose an alternate
                selection before the first tee-time on Thursday.
                <ul>
                  <li>
                    If you do not select a new player, you will be given a
                    random player from that group to replace them, regardless of
                    whether their round has started or not (random selection
                    process will be recorded and shared).
                  </li>
                  <li>
                    If your player withdraws DURING the tournament, it counts as
                    a missed cut.
                  </li>
                  <li>
                    If an incident occurs where a player withdraws BEFORE their
                    round has begun, but AFTER the tournament has begun,
                    everyone with the selection will be given a random player
                    from that group to replace them, regardless of whether their
                    round has started or not (random selection process will be
                    recorded and shared).
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <h2 className="sweeps-font-bold w3-jumbo w3-animate-top w3-center">
            Rugby Competitions{' '}
          </h2>
          <div className="w3-container w3-text-white">
            <ul>
              <li>
                Participants must predict the winner and the score of each match
                listed in the sweep.
              </li>
              <li>
                Points System:
                <ul>
                  <li>
                    If a participant predicts the correct winning team, they
                    will receive 8 points.
                  </li>
                  <li>
                    If a participant predicts the correct winning team and the
                    exact score of the match, they will receive 13 points.
                  </li>
                  <li>
                    If a participant predicts a draw and the result is indeed a
                    draw, they will receive 30 points.
                  </li>
                </ul>
              </li>
              <li>
                Tiebreaker:
                <ul>
                  <li>
                    In the event of a tie between participants with the same
                    total points, the tiebreaker question will come into play.
                  </li>
                  <li>
                    Participants must provide their prediction for the
                    tiebreaker question along with their match predictions.
                  </li>
                </ul>
              </li>
              <li>
                Scoring:
                <ul>
                  <li>
                    The participant with the highest total points at the end of
                    the tournament wins the sweepstake.
                  </li>
                  <li>
                    In the case of a tie, the participant who predicted the
                    tiebreaker question closest to the actual value will be
                    declared the winner.
                  </li>
                  <li>
                    If there is still a tie after considering the tiebreaker
                    question, the prize may be shared among the tied
                    participants.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <h2 className="sweeps-font-bold w3-jumbo w3-animate-top w3-center">
            Horse Auction Competitions{' '}
          </h2>
          <div className="w3-container w3-text-white">
            <ul>
              <li>
                Entrants must select 5 yearlings – one from each of the 5 lists
              </li>
              <li>
                You can only select one yearling from each Stallion. <br />
                If there are four yearlings sired by FRANKEL in the sale – only
                one of those can be selected in a particular entry.
              </li>
              <li>
                The yearlings’ auction prices are combined to give the entrants’
                total for the competition.
                <ul>
                  <li>
                    If a yearling is unsold (Reserve Not Attained) then the sale
                    price is €0 for that individual.
                  </li>
                </ul>
              </li>
              <li>
                Tiebreaker:
                <ul>
                  <li>
                    In the event of a tie between participants with the same
                    total points, the tiebreaker question will come into play.
                  </li>
                  <li>
                    Participants must provide their prediction for the
                    tiebreaker question along with their yearling selections.
                  </li>
                </ul>
              </li>
              <li>
                Scoring:
                <ul>
                  <li>
                    The participant with the highest cumulative price at the end
                    of the sale wins the sweepstake.
                  </li>
                  <li>
                    In the case of a tie, the participant who predicted the
                    tiebreaker question closest to the actual value will be
                    declared the winner.
                  </li>
                  <li>
                    If there is still a tie after considering the tiebreaker
                    question, the prize may be shared among the tied
                    participants.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <h2 className="sweeps-font-bold w3-jumbo w3-animate-top w3-center">
            Refunds:{' '}
          </h2>
          <div className="w3-container w3-text-white">
            <ul>
              <li>
                Sweeps offers participants the chance to enter sweepstakes and
                win Prizes provided by our club and charity customers. Due to
                the nature of the services provided, all entries and purchases
                made on our platform are considered final and non-refundable. By
                participating in our platform, users acknowledge and agree to
                adhere to this refund policy.
              </li>
              <li>
                No Refunds for Entries: All entries purchased for our
                sweepstakes are non-refundable. Once an entry is submitted and
                confirmed, it cannot be reversed, canceled, or refunded for any
                reason.
              </li>
              <li>
                No Refunds for Technical Issues: In the event of technical
                difficulties, glitches, or interruptions affecting the user
                experience, the Company is not liable to provide refunds for
                entries or services. Every effort will be made to resolve
                technical issues promptly, but refunds will not be granted on
                this basis
              </li>
              <li>
                User Responsibility: Participants are responsible for ensuring
                the accuracy of their entries, payment details, and selections.
                Errors made by participants during the entry process do not
                warrant a refund.
              </li>
              <li>
                Policy Amendments: The Company retains the right to modify or
                update this refund policy at any time without prior notice.
                Participants are encouraged to review the policy periodically to
                stay informed about any changes.
              </li>
              <li>
                Contact and Support: If participants encounter any issues while
                using our platform or have questions regarding our refund
                policy, they can contact our customer support team for
                assistance. However, inquiries about refunds will not alter the
                policy outlined herein.
              </li>
              <li>
                By engaging with our sweepstakes and using our services,
                participants acknowledge that they have read, understood, and
                agreed to the terms outlined in this refund policy. It is
                recommended that users review the policy in its entirety before
                participating.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Terms;
