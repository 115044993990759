import React, { useEffect } from 'react';
import Table from '../../Layouts/Table';
import EditPostModal from './EditPostModal';
import { fetchPosts } from '../../../Helpers/BlogHelper';

function Posts(props) {
  const { user } = props;
  const [state, setState] = React.useState({
    posts: [],
  });

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
    fetchPosts(updatePostsState);
  }, []);

  function updatePostsState(response) {
    if (response.data) {
      setState({
        ...state,
        posts: response.data['posts'],
      });
    } else if (response.response) {
      setState({
        ...state,
        posts: [],
      });
    }
  }

  function getColumns() {
    return [
      {
        name: '',
        cell: (row) => (
          <button
            onClick={() =>
              (document.getElementById(`editPost-${row.id}`).style.display =
                'block')
            }
            className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
          >
            Edit
          </button>
        ),
      },
      {
        name: 'Title',
        cell: (row) => row.title,
      },
    ];
  }

  return (
    <>
      <h3 className="sweeps-font-bold w3-text-teal">Posts</h3>
      {state.posts.map((post) => {
        return <EditPostModal key={post.id} user={user} post={post} />;
      })}
      <Table data={state.posts} columns={getColumns()} />
    </>
  );
}

export default Posts;
