import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Name from '../Shared/Name';
import RiskColour from './RiskColour';
import styled, { keyframes } from 'styled-components';
import PlayerName from './PlayerName';
import Filters from '../Shared/Filters';
import { getClubForUserId } from '../../../Helpers/GaaClubsHelper';
import ExpandedRow from './ExpandedRow';
import { getPlayersDataForSweep } from '../../../Helpers/PlayerHelper';

let date = new Date();
let omitAtRisk = [0, 1, 2, 3, 6, 7].includes(date.getDay());

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      minWidth: '90px',
    },
  },
};

function getColumns(liveScores, playersData) {
  return [
    {
      name: '',
      cell: () => <i className="fa fa-angle-down"></i>,
      width: '30px',
    },
    {
      name: '',
      selector: (row, index) => row.postion + 1,
      grow: 0,
      width: '65px',
    },
    {
      name: 'Name',
      cell: (row) => <Name row={row} />,
    },
    {
      name: 'Score',
      selector: (row) => row.visual_score,
      center: true,
      grow: 0,
    },
    {
      name: 'Cut Risk Level',
      cell: (row) => <RiskColour row={row} />,
      center: true,
      omit: omitAtRisk,
    },
    {
      name: 'Group 1',
      selector: (row) => (
        <PlayerName player={row.group_1} liveScores={liveScores} />
      ),
      center: true,
      wrap: true,
    },
    {
      name: 'Group 2',
      selector: (row) => (
        <PlayerName player={row.group_2} liveScores={liveScores} />
      ),
      center: true,
      wrap: true,
    },
    {
      name: 'Group 3',
      selector: (row) => (
        <PlayerName player={row.group_3} liveScores={liveScores} />
      ),
      center: true,
      wrap: true,
    },
    {
      name: 'Group 4',
      selector: (row) => (
        <PlayerName player={row.group_4} liveScores={liveScores} />
      ),
      center: true,
      wrap: true,
    },
    {
      name: 'Group 5',
      selector: (row) => (
        <PlayerName player={row.group_5} liveScores={liveScores} />
      ),
      center: true,
      wrap: true,
    },
  ];
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid #006747;
  border-right: 2px solid #006747;
  border-bottom: 2px solid #006747;
  border-left: 4px solid #006747;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const CustomLoader = () => (
  <div style={{ padding: '24px' }}>
    <Spinner />
  </div>
);

function GolfLeaderboardTable(props) {
  const { sweep, user } = props;

  const [state, setState] = React.useState({
    name: '',
    players: [],
  });

  useEffect(() => {
    if (sweep) {
      getPlayersDataForSweep(sweep, updatePlayersState);
    }
  }, [sweep]);

  const updatePlayersState = (playersData) => {
    setState({
      ...state,
      players: playersData,
    });
  };

  function updateFilters(name) {
    setState({
      ...state,
      name,
    });
  }

  const [pending, setPending] = React.useState(true);
  let filteredMappedData;

  const { updatedScores, liveScores, textColor, results } = props;

  const sortedData = results.sort(function (a, b) {
    if (a.total_score == b.total_score) {
      return a.diff_from_leading_score - b.diff_from_leading_score;
    }
    return a.total_score - b.total_score;
  });

  const mappedData = sortedData.map((item, index) => ({
    postion: index,
    derivedClub: getClubForUserId(item.user_id),
    ...item,
  }));

  if (state.name === '') {
    filteredMappedData = mappedData;
  } else {
    filteredMappedData = getFilteredData(mappedData);
  }

  function getFilteredData(mappedData) {
    return mappedData.filter(function (sweepstakeEntry) {
      if (state.name !== '') {
        return isNameInSweepEntryName(sweepstakeEntry?.nickName);
      }
    });
  }

  function isNameInSweepEntryName(entryName) {
    return entryName
      ?.toString()
      .toUpperCase()
      .includes(state.name?.toUpperCase());
  }

  useEffect(() => {
    if (liveScores.length !== 0) {
      setPending(false);
    }
  }, [liveScores]);

  const conditionalRowStyles = [
    {
      when: (row) => user && row.user_id === user.userId,
      style: {
        backgroundColor: '#5b618a',
        color: 'white',
      },
    },
  ];

  if (filteredMappedData.length > 0) {
    filteredMappedData[0].defaultExpanded = true;
  }

  return (
    <>
      <div className="w3-row">
        <div className="w3-third">
          <Filters name={state.name} updateFilters={updateFilters} />
        </div>
        <div className="w3-twothird">
          <p className="w3-margin-top" style={{ float: 'right' }}>
            <i
              className={`fa fa-clock-o fa-fw w3-margin-right w3-large ${textColor}`}
            />
            Last Updated at {updatedScores.getHours()}:
            {updatedScores.getMinutes()}:{updatedScores.getSeconds()}
          </p>
        </div>
      </div>

      <DataTable
        columns={getColumns(liveScores, state.players)}
        data={filteredMappedData}
        customStyles={customStyles}
        progressPending={pending}
        progressComponent={<CustomLoader />}
        expandableRows={true}
        expandOnRowClicked={true}
        expandableRowsHideExpander={true}
        expandableRowsComponent={ExpandedRow}
        conditionalRowStyles={conditionalRowStyles}
        expandableRowsComponentProps={{ playersData: state.players }}
        expandableRowExpanded={(row) => row.defaultExpanded}
      />
    </>
  );
}

export default GolfLeaderboardTable;
