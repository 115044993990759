/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { getTeamForId } from '../../../Helpers/RugbyMatchesHelper';
import Team from './Team';
import SetDistanceSlider from '../../Layouts/Sliders/SetDistanceSlider';

const DISTANCE = 7;

function Match(props) {
  const { match, handleMatchPicked, selection, lowerBound, upperBound, size } =
    props;

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    getTeamForId(match?.teams, setTeams);
  }, []);

  const [selectedTeam, setSelectedTeam] = useState(selection || 0);
  const [scoreRange, setScoreRange] = useState([
    lowerBound || 1,
    upperBound || DISTANCE + 1,
  ]);

  const handleTeamChange = (selectedTeamId) => {
    setSelectedTeam(selectedTeamId);
    updateParent(selectedTeamId, scoreRange);
  };

  const handleScoreChange = (scoreRange) => {
    setScoreRange(scoreRange);
    updateParent(selectedTeam, scoreRange);
  };

  const updateParent = (selectedTeamId, scoreRange) => {
    handleMatchPicked(
      match?.gameId,
      scoreRange[0],
      scoreRange[1],
      selectedTeamId,
      selectedTeamId === -1,
    );
  };

  const homeTeam = teams[0] || undefined;
  const awayTeam = teams[1] || undefined;

  return (
    <div className={`${size ? size : 'w3-half'}`}>
      <div className="w3-container w3-padding w3-margin match-border">
        <div className="w3-center">
          <Team
            team={homeTeam}
            selected={selectedTeam}
            setSelected={handleTeamChange}
          />

          <div className="w3-col" style={{ width: '10%' }}>
            <div
              className="sweeps-font-bold w3-large w3-text-teal"
              style={{ marginTop: '50px' }}
            >
              vs
            </div>
          </div>
          <Team
            team={awayTeam}
            selected={selectedTeam}
            setSelected={handleTeamChange}
          />

          <button
            className={`w3-padding w3-margin-top w3-small w3-center w3-button w3-border ${
              -1 === selectedTeam ? 'w3-teal' : ''
            }`}
            onClick={() => handleTeamChange(-1)}
            style={{ width: '50%' }}
          >
            Draw
          </button>

          <div className="w3-container">
            <div className="w3-row-padding">
              <h5 className="sweeps-font-medium w3-text-teal">Score Range</h5>
              {selectedTeam > 0 && (
                <p>
                  {
                    teams.filter((team) => team.teamId === selectedTeam)[0]
                      ?.name
                  }{' '}
                  by between {scoreRange[0]} and {scoreRange[1]}
                </p>
              )}
              {selectedTeam === -1 && <p>Draw</p>}
              {selectedTeam === 0 && (
                <p>Pick a team and move slider below to choose a score range</p>
              )}
            </div>
            <SetDistanceSlider
              disabled={selectedTeam === -1}
              onChange={handleScoreChange}
              initialLowerBound={lowerBound || 1}
              initialUpperBound={upperBound || DISTANCE + 1}
              distance={DISTANCE}
              max={60}
            />
          </div>
          <div className="w3-container w3-margin-top">
            {new Date(match?.date).toDateString()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Match;
