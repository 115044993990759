import React, { useEffect } from 'react';
import { useOrganisations } from '../../features/useOrganisations';
import OrganisationsView from './OrganisationsView';
import { useNavigate } from 'react-router-dom';

function OrganisationsContainer(props) {
  const { user } = props;
  const { getOrganisationsArray } = useOrganisations();
  const organisations = getOrganisationsArray();

  const navigate = useNavigate('');

  useEffect(() => {
    if (!user?.adminAccess) {
      navigate('/');
    }
  }, [user]);

  return <OrganisationsView user={user} organisations={organisations} />;
}

export default OrganisationsContainer;
