import React from 'react';

function ContactOrganiser() {
  return (
    <React.Fragment>
      <h3 className="w3-large sweeps-font-bold w3-text-sviolet">
        Prize Details
      </h3>
      <p>Contact Organiser for details.</p>
    </React.Fragment>
  );
}

export default ContactOrganiser;
