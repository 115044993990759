import React, { useEffect } from 'react';
import Contact from '../Contact/Contact';
import { Link } from 'react-router-dom';

function AcceptingEntries() {
  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
  }, []);

  return (
    <>
      <div className="w3-row">
        <div className="w3-third">
          <img
            src="/open23.jpeg"
            style={{ width: '100%' }}
            alt="The Open 2023"
          />
        </div>
        <div className="w3-twothird">
          <div className="w3-container">
            <h2 className="w3-text-teal sweeps-font-bold w3-center">
              Get in Touch to Run your own Sweeps:
            </h2>
            <h4 className="w3-text-sviolet sweeps-font-medium w3-center">
              <Link to="/how-it-works">
                Click here to find out how it works
              </Link>
            </h4>
            <Contact />
          </div>
        </div>
      </div>
    </>
  );
}

export default AcceptingEntries;
