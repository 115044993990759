import React from 'react';
import { isEntriesFinished, prettyDate } from '../../Helpers/MiscHelpers';
import Card from '../Layouts/Card';
import { useNavigate } from 'react-router-dom';
import { getUrlAlias } from '../../Helpers/SweepsHelper';
import DOMPurify from 'dompurify';
import PrizeWrapper from '../Prizes/Wrapper';

function Details(props) {
  const { sweep } = props;
  const navigate = useNavigate();

  const cleanHTML =
    !sweep.the_sweep &&
    DOMPurify.sanitize(sweep?.organisation?.bio, {
      USE_PROFILES: { html: true },
    });

  return (
    <div className="w3-margin-top" data-testid="details">
      <div className="w3-half">
        <Card>
          <div className="w3-padding">
            <h1 className="sweeps-font-bold w3-text-teal">
              {sweep?.competition?.name}
              {!sweep.the_sweep && ' - ' + sweep?.organisation?.name}
            </h1>
            {!sweep.the_sweep && (
              <>
                <div dangerouslySetInnerHTML={{ __html: cleanHTML }}></div>
                <p>
                  Visit{' '}
                  <a href={sweep?.organisation?.link} target="_blank">
                    {sweep?.organisation?.name}
                  </a>{' '}
                  Website
                </p>
              </>
            )}

            {isEntriesFinished(sweep.competition) && (
              <div className="w3-container w3-margin-top">
                <button
                  onClick={() => {
                    navigate(`/${getUrlAlias(sweep)}/leaderboard`);
                  }}
                  className="pointer w3-btn w3-center w3-margin-top w3-margin-bottom w3-teal"
                >
                  Visit Leaderboard
                </button>
              </div>
            )}
          </div>
        </Card>
        <div className="w3-half">
          <Card>
            <div className="w3-padding">
              <h3 className="w3-large sweeps-font-bold w3-text-sviolet">
                Entry Details
              </h3>
              <ul
                style={{
                  listStyle: 'none',
                  marginBlockStart: '0',
                  paddingInlineStart: '0',
                }}
              >
                <li>
                  <i className="fa fa-calendar fa-fw w3-margin-right w3-large w3-text-teal"></i>{' '}
                  Entries open on{' '}
                  {prettyDate(sweep?.competition?.entry_open_date)}
                </li>
                <li>
                  <i className="fa fa-calendar fa-fw w3-margin-right w3-large w3-text-teal"></i>{' '}
                  Entries close on{' '}
                  {prettyDate(sweep?.competition?.entry_close_date)}
                </li>
                <li>
                  <i className="fa fa-calendar fa-fw w3-margin-right w3-large w3-text-teal"></i>{' '}
                  Tournament starts on{' '}
                  {prettyDate(sweep?.competition?.start_date)}
                </li>
                <li>
                  <i className="fa fa-calendar fa-fw w3-margin-right w3-large w3-text-teal"></i>{' '}
                  Tournament ends on {prettyDate(sweep?.competition?.end_date)}
                </li>
              </ul>
            </div>
          </Card>
        </div>
        <div className="w3-half">
          <Card>
            <div className="w3-padding">
              <PrizeWrapper sweep={sweep} />
            </div>
          </Card>
        </div>
      </div>
      {!sweep.the_sweep && (
        <div className="w3-half">
          <Card>
            <img
              src={sweep?.image_url}
              alt={sweep?.organisation?.name}
              style={{ width: '100%' }}
            />
          </Card>
        </div>
      )}
    </div>
  );
}

export default Details;
