import React from 'react';
import EditEntry from './EditEntry';

function GolfEntry(props) {
  const { entry, sweep, refreshEntries, visible } = props;

  return (
    <EditEntry
      refreshEntries={refreshEntries}
      entry={entry}
      sweep={sweep}
      visible={visible}
    />
  );
}

export default GolfEntry;
