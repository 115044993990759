/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Oval } from 'react-loader-spinner';

function Loader() {
  return (
    <div className="w3-center w3-container" style={{ height: '200px' }}>
      <Oval
        wrapperStyle={{
          margin: 'auto',
          position: 'absolute',
          left: '50%',
          width: '200px',
        }}
        ariaLabel="loading-indicator"
        height={100}
        width={100}
        strokeWidth={5}
        strokeWidthSecondary={1}
        color="teal"
        secondaryColor="white"
      />
    </div>
  );
}

export default Loader;
