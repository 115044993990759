/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function Error(props) {
  const { error } = props;
  return (
    <React.Fragment>
      {error && (
        <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-red">
          {error.messages.map((message) => (
            <p className="w3-margin-top">
              <i className={`fa fa-warning fa-fw w3-margin-right`} />
              {message}
            </p>
          ))}
        </div>
      )}
    </React.Fragment>
  );
}

export default Error;
