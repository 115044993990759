import React from 'react';
import { Link } from 'react-router-dom';

function AboutUs() {
  return (
    <>
      <div className="w3-row">
        <div className="w3-container">
          <h2 className="w3-text-sviolet sweeps-font-bold w3-center">
            About Sweeps
          </h2>
          <p className="w3-center">
            Welcome to Sweeps, where fundraising meets excitement and community
            spirit! We are dedicated to revolutionizing the way clubs and
            charities raise funds by providing engaging and effortless online
            sweepstakes for their members and friends alike.
          </p>
          <p className="w3-center">
            At Sweeps, our mission is simple yet powerful: to build engaging and
            fun new ways for clubs and charities to fundraise with ease. We
            understand the importance of creating an enjoyable and seamless
            fundraising experience for both participants and organizers alike.
          </p>

          <p className="w3-center">
            What sets Sweeps apart in the fundraising space, is our unwavering
            commitment to user experience and ease of use. We have designed our
            platform with simplicity in mind, ensuring that both players and
            organizers can effortlessly navigate and engage with our sweeps.
          </p>

          <p className="w3-center">
            We take great pride in the success stories we have already achieved.
            The Louth LGFA, for instance, raised an impressive €5600 through one
            sweep for the Masters Golf Tournament. This is just one example of
            how Sweeps can make a significant impact on fundraising efforts.
          </p>

          <p className="w3-center">
            Whether you are a sports enthusiast looking to support your local
            club or an organizer seeking a hassle-free fundraising solution,
            Sweeps is here to make your experience unforgettable. With minimal
            setup required, our platform allows you to dive right into the
            action, run a handful of sweeps a year, and significantly increase
            your annual budget.
          </p>

          <p className="w3-center">
            If you have any questions, please reach out and we look forward to
            working with you!
          </p>

          <p className="w3-center">
            <Link to="/how-it-works">Want to run your own sweep?</Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
