import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { uploadFile } from '../../Helpers/S3Helper';
import { toast } from 'react-toastify';

export const lottoLicensesSlice = createSlice({
  name: 'lottoLicenses',
  initialState: {
    licenses: {},
    fetchingStatus: 'idle',
    lastUpdated: '',
  },
  extraReducers(builder) {
    builder.addCase(addNewLicense.fulfilled, (state, action) => {
      const license = action.payload.license;
      const file = action.meta.arg.file;
      state.licenses[license.id] = action.meta.arg;
      uploadFile(
        action.payload,
        {
          file_name: file,
        },
        () => toast.success('Uploaded Lotto License'),
      );
    });
  },
});

export const addNewLicense = createAsyncThunk(
  'lottoLicenses/addNewLicense',
  async (initialLicense) => {
    const user = initialLicense?.user;
    delete initialLicense.user;
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/organisations/license',
      initialLicense,
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    );
    return response.data;
  },
);

export default lottoLicensesSlice.reducer;
