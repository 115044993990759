import React from 'react';

import SelectSweep from '../../Form/Fields/SweepsField';

function Form(props) {
  const { handleChange, sweepId, organisationId } = props;

  return (
    <>
      <SelectSweep
        id={sweepId}
        handleChange={handleChange}
        organisationId={organisationId}
      />
      <input
        label="Upload License"
        type="file"
        onChange={handleChange}
        name="file"
      />
    </>
  );
}

export default Form;
