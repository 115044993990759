import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import AuthWrapper from './AuthWrapper';
import { Oval } from 'react-loader-spinner';

const apiUrl = process.env.REACT_APP_API_URL;

function ConfirmUser(props) {
  const { fetchUserDetails, user } = props;
  const [searchParams] = useSearchParams();
  const username = searchParams.get('uuid');
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const [state, setState] = React.useState({
    error: '',
    successfulSignup: false,
    responded: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post(apiUrl + '/account/confirm', {
        username: username,
        code: code,
      })
      .then(function (response) {
        if (response.data.message.includes('Successfully created user')) {
          setState({
            ...state,
            error: '',
            successfulSignup: true,
            responded: true,
          });
        }
      })
      .catch(function (error) {
        setState({
          ...state,
          error: error?.response?.data?.message,
          successfulSignup: false,
          responded: true,
        });
      });
  }, [username, code]);

  if (!state.responded) {
    return (
      <Oval
        wrapperStyle={{
          margin: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '200px',
        }}
        ariaLabel="loading-indicator"
        height={100}
        width={100}
        strokeWidth={5}
        strokeWidthSecondary={1}
        color="teal"
        secondaryColor="white"
      />
    );
  }
  let errorDisplay;
  if (!state.successfulSignup) {
    if (state.error !== 'User is already confirmed.') {
      errorDisplay = <>{state.error} Trying reaching out for help...</>;
    }
  }

  if (user) {
    navigate('/entries');
  }

  return (
    <>
      {(state.successfulSignup ||
        state.error === 'User is already confirmed.') && (
        <>
          <div className="w3-row-padding w3-margin-top">
            <div className="w3-row">
              <div className="w3-container w3-card w3-white">
                <div className="w3-margin">
                  <AuthWrapper
                    email={email}
                    successMessage="Confirmed Email"
                    fetchUserDetails={fetchUserDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {errorDisplay && (
        <>
          <div className="w3-row-padding w3-margin-top">
            <div className="w3-row">
              <div className="w3-container w3-card w3-white">
                <div className="w3-margin">
                  {!state.successfulSignup && <>{errorDisplay}</>}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ConfirmUser;
