import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import IndividualSweep from './IndividualSweep';

function Sweeps(props) {
  const { sweeps, user } = props;

  const [currentSweep, setCurrentSweep] = useState(sweeps[0] || {});

  useEffect(() => {
    setCurrentSweep(sweeps[0] || {});
  }, [sweeps]);

  return (
    <>
      <span>Select a sweep to see details below:</span>
      <Grid container spacing={2}>
        {sweeps.map((sweep) => {
          return (
            <Grid
              key={sweep.id}
              onClick={() => setCurrentSweep(sweep)}
              item
              md={4}
            >
              <Paper
                variant="outlined"
                sx={{
                  margin: 'auto',
                  textAlign: 'center',
                  backgroundColor: `${
                    currentSweep?.id === sweep.id ? 'lightgray' : 'white'
                  }`,
                }}
              >
                <b>{sweep?.competition?.name}</b>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <hr />
      {sweeps.map((sweep) => {
        const display = sweep.id === currentSweep?.id ? 'block' : 'none';
        return (
          <div key={sweep.id} style={{ display: `${display}` }}>
            <IndividualSweep {...props} user={user} sweep={sweep} />
          </div>
        );
      })}
    </>
  );
}

export default Sweeps;
