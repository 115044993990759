import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadSweeps } from '../../../features/useLoadSweeps';
import SweepsAdminView from './SweepsAdminView';

function SweepsAdminContainer(props) {
  const { user } = props;
  const { getSweepsArray } = useLoadSweeps();
  let sweeps = getSweepsArray();

  const navigate = useNavigate('');

  useEffect(() => {
    if (!user?.adminAccess) {
      navigate('/');
    }
  }, [user]);

  return <SweepsAdminView user={user} sweeps={sweeps} />;
}

export default SweepsAdminContainer;
