import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getEntrantsForSweep,
  getStatus,
  getLastUpdated,
  fetchEntrants,
} from './entrants/entrantsSlice';

export const useLoadEntrants = (sweep, user) => {
  const entrantsStatus = useSelector((state) => getStatus(state, sweep?.id));
  const lastUpdated = useSelector((state) => getLastUpdated(state, sweep?.id));

  const dispatch = useDispatch();

  useEffect(() => {
    if (entrantsStatus === 'idle' || !entrantsStatus) {
      dispatch(
        fetchEntrants({
          sweepId: sweep?.id,
          user: user,
        }),
      );
    }
  }, [entrantsStatus, dispatch]);

  const getEntrants = () => {
    return useSelector((state) => getEntrantsForSweep(state, sweep?.id));
  };

  return {
    entrantsStatus,
    getEntrants,
    lastUpdated,
  };
};
