import FiftyFourtyTenFive from '../Components/Prizes/FiftyFourtyTenFive';
import FiveThousand from '../Components/Prizes/FiveThousand';
import NCBI from '../Components/Prizes/NCBI';
import ThousandLimit from '../Components/Prizes/ThousandLimit';
import TwentyFiveEuro from '../Components/Prizes/TwentyFiveEuro';
import TwoHundredGiveaway from '../Components/Prizes/TwoHundredGiveaway';
import Orby_1_2023 from '../Components/Prizes/Orby_1_2023';
import ContactOrganiser from '../Components/Prizes/Organiser';

const prizeOptions = {
  '50_40_10_5': {
    id: '50_40_10_5',
    name: '50% Prize, 40% Organisation, 10% Sweeps, 5 Prizes',
    prizes: [30, 10, 5, 3, 2],
    organisation: 40,
    sweeps: 10,
    fund: 1000,
    monetary: true,
    component: <FiftyFourtyTenFive prizeKey="50_40_10_5" />,
  },
  '1000_3_limit': {
    id: '1000_3_limit',
    name: '€1000 Prize, 10% Sweeps, 3 Prizes',
    prizes: [700, 200, 100],
    organisation: 4,
    sweeps: 10,
    fund: 1000,
    monetary: true,
    component: <ThousandLimit prizeKey="1000_3_limit" />,
  },
  '1500_3_limit': {
    id: '1500_3_limit',
    name: '€1500 Prize, 10% Sweeps, 3 Prizes',
    prizes: [1000, 300, 200],
    organisation: 4,
    sweeps: 10,
    fund: 1500,
    monetary: true,
    component: <ThousandLimit prizeKey="1500_3_limit" />,
  },
  five_thousand: {
    id: 'five_thousand',
    name: '€5000',
    prizes: [5000],
    organisation: 0,
    sweeps: 10,
    fund: 5000,
    monetary: true,
    component: <FiveThousand prizeKey="five_thousand" />,
  },
  25: {
    id: '25',
    name: '€25 Euro',
    prizes: [25],
    organisation: 0,
    sweeps: 0,
    fund: 25,
    monetary: true,
    component: <TwentyFiveEuro prizeKey="25" />,
  },
  ncbi_irish_open: {
    id: 'ncbi_irish_open',
    name: 'NCBI Irish Open Prize',
    prizes: [],
    organisation: 0,
    sweeps: 10,
    fund: 3800,
    monetary: false,
    component: <NCBI prizeKey="ncbi_irish_open" />,
  },
  two_hundred_giveaway: {
    id: 'two_hundred_giveaway',
    name: '200 Euro RWC Giveaway',
    prizes: [],
    organisation: 0,
    sweeps: 0,
    fund: 200,
    monetary: true,
    component: <TwoHundredGiveaway prizeKey="two_hundred_giveaway" />,
  },
  orby_1_2023: {
    id: 'orby_1_2023',
    name: 'Orby 1 2023',
    prizes: [],
    organisation: 0,
    sweeps: 0,
    fund: 850,
    monetary: false,
    component: <Orby_1_2023 prizeKey="orby_1_2023" />,
  },
  organiser: {
    id: 'organiser',
    name: 'Contact Organiser',
    prizes: [],
    organisation: 0,
    sweeps: 0,
    fund: 0,
    monetary: false,
    component: <ContactOrganiser prizeKey="organiser" />,
  },
};

export function getPrizeOptions() {
  return prizeOptions;
}

export function getPrizeDisplayName(key) {
  return prizeOptions[key]?.name;
}

export function getPrizeForKey(key) {
  return prizeOptions[key];
}
