import { Referral } from './Referral';
import { isEntriesOpen } from '../../Helpers/MiscHelpers';
import { useLoadSweeps } from '../../features/useLoadSweeps';

const ReferralsEntriesWrapper = (props) => {
  const { entries, user } = props;

  const { getSweepsArray } = useLoadSweeps();

  const sweeps = getSweepsArray();

  const getSweepById = (sweepId) => {
    return sweeps.filter((sweep) => sweep.id === sweepId)[0];
  };

  const getUniqueEnteredSweepsWithOpenEntries = () => {
    const uniqueSweeps = {};
    entries.forEach((entry) => {
      if (!(entry['sweepstake'] in uniqueSweeps)) {
        const sweepObject = getSweepById(entry['sweepstake']);
        if (isEntriesOpen(sweepObject?.competition)) {
          uniqueSweeps[entry['sweepstake']] = getSweepById(entry['sweepstake']);
        }
      }
    });
    return uniqueSweeps;
  };

  const sweepsWithOpenEntries = getUniqueEnteredSweepsWithOpenEntries();

  return (
    <>
      <div className="w3-container w3-margin-top">
        <h3 className="sweeps-font-bold w3-text-teal">Your Referrals</h3>
        <p>
          For every 5th team entered using your link will earn you a free entry
          to this sweep.
        </p>
      </div>
      {Object.entries(sweepsWithOpenEntries).map((sweep) => {
        return (
          <div class="w3-half">
            <Referral user={user} sweepId={sweep[0]} sweep={sweep[1]} />
          </div>
        );
      })}
      {Object.entries(sweepsWithOpenEntries).length === 0 && (
        <>
          <div className="w3-container">
            <b>You have no active referral codes.</b>
          </div>
        </>
      )}
    </>
  );
};

export { ReferralsEntriesWrapper };
