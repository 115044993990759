import axios from 'axios';

export const getOptions = (sweep, callback) => {
  if (sweep?.competition?.config_file) {
    fetchOptions(sweep.competition, callback);
  }
};

export const getOptionsByStallion = (options) => {
  const optionsByStallion = {};
  options.forEach((option) => {
    if (Object.keys(optionsByStallion).includes(option.Stallion)) {
      optionsByStallion[option.Stallion].push(option);
    } else {
      optionsByStallion[option.Stallion] = [option];
    }
  });

  return optionsByStallion;
};

const fetchOptions = (competition, callback) => {
  axios
    .get(competition.config_file)
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};
