/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from 'react';
import InitPayment from '../Payment/InitPayment';
import Register from '../Account/Register/Register';
import Success from './Success';
import { getSharingText } from '../../Helpers/MiscHelpers';
import Tiebreak from './Tiebreak';
import NickNameSlide from './NickNameSlide';
import Card from '../Layouts/Card';

import { SubmitNoPaymentTeam } from './SubmitNoPaymentTeam';
import HorseAuctionForm from './HorseAuction/Form';
import GolfForm from './Golf/Form';
import Form from './Rugby/Form';
import { useForm } from './hooks/useForm';

// THESE CHANGE
const TIEBREAK_SLIDE = 50;
const REGISTER_SLIDE = 51;
const NICK_NAME_SLIDE = 52;
const INIT_PAYMENT_SLIDE = 53;
const SUMBIT_NO_PAYMENT_SLIDE = 54;
const ERROR_SLIDE = 60;

const SignupWrapper = (props) => {
  const { fetchUserDetails, user, sweep } = props;

  const { state, handlers, context } = useForm(props);
  const {
    getObjectToStoreInStripe,
    submitSelections,
    onBlur,
    onRegisteredUser,
    handleChange,
    nextSlide,
    previousSlide,
  } = handlers;

  return (
    <>
      {state.successfulSignup && (
        <Success
          getTwitterShareText={getSharingText}
          sweep={sweep}
          myReferralCode={state.myReferralCode}
        />
      )}
      {!state.successfulSignup && (
        <form className="w3-container" onSubmit={(e) => e.preventDefault()}>
          {Object.keys(sweep).length > 0 && (
            <>
              {sweep?.competition?.sport === 'golf' && (
                <GolfForm
                  context={{
                    sweep,
                    ...context,
                    ...state,
                  }}
                  handlers={handlers}
                />
              )}

              {sweep?.competition?.sport === 'rugby' && (
                <Form
                  context={{
                    sweep,
                    matches: context.matches,
                    picks: context.picks,
                    ...state,
                  }}
                  handlers={handlers}
                />
              )}
              {sweep?.competition?.sport === 'horse_auction' && (
                <HorseAuctionForm
                  context={{
                    sweep,
                    options: context.options,
                    picks: context.picks,
                    ...state,
                  }}
                  handlers={handlers}
                />
              )}
            </>
          )}

          {state.activeSlide === TIEBREAK_SLIDE && (
            <Card>
              <div className="w3-padding">
                <Tiebreak
                  onBlur={onBlur}
                  handleChange={handleChange}
                  nextSlide={nextSlide}
                  previousSlide={previousSlide}
                  tieBreaker={state.tieBreaker}
                  label={'Tiebreaker:' + sweep.competition.tiebreaker_question}
                />
              </div>
            </Card>
          )}

          {state.activeSlide === REGISTER_SLIDE && (
            <Card>
              <Register
                onSuccess={onRegisteredUser}
                fetchUserDetails={fetchUserDetails}
                previousSlide={previousSlide}
                prominentLogin={true}
              />
            </Card>
          )}

          {state.activeSlide === NICK_NAME_SLIDE && (
            <Card>
              <NickNameSlide
                nextSlide={nextSlide}
                previousSlide={() => previousSlide()}
                nickName={state.nickName}
                onBlur={onBlur}
                handleChange={handleChange}
                hidePrevious={!user}
              />
            </Card>
          )}

          {state.activeSlide === INIT_PAYMENT_SLIDE && (
            <Card>
              <InitPayment
                receiptEmail={state.email}
                objectToStore={getObjectToStoreInStripe}
                submitSelections={submitSelections}
                sweep_id={sweep.id}
                sweep={sweep}
                connected_account_id={sweep.connected_account_id}
                previousSlide={previousSlide}
              />
            </Card>
          )}
          {state.activeSlide === SUMBIT_NO_PAYMENT_SLIDE && (
            <Card>
              <SubmitNoPaymentTeam
                sweep={sweep}
                previousSlide={previousSlide}
                submitSelections={submitSelections}
              />
            </Card>
          )}
          {state.activeSlide === ERROR_SLIDE &&
            state.errors['submitSelections'] && (
              <Card>
                <>
                  <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-red">
                    <p className="w3-margin-top">
                      <i className={`fa fa-warning fa-fw w3-margin-right`} />
                      {state.errors['submitSelections'].messages[0]}
                    </p>
                  </div>
                  <button
                    onClick={() => previousSlide(state.numberOfGroups)}
                    className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
                  >
                    Back
                  </button>
                </>
              </Card>
            )}
        </form>
      )}
    </>
  );
};

export default SignupWrapper;
