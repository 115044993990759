/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PlayerAvatar from '../../Golfers/PlayerAvatar';
import { useEffect } from 'react';
import { getPlayersDataForSweep } from '../../../Helpers/PlayerHelper';

function SelectionsContainer(props) {
  const { group_1, group_2, group_3, group_4, group_5, sweep } = props;

  const [state, setState] = React.useState({
    players: [],
  });

  useEffect(() => {
    if (sweep) {
      getPlayersDataForSweep(sweep, updatePlayersState);
    }
  }, [sweep]);

  const updatePlayersState = (playersData) => {
    setState({
      ...state,
      players: playersData,
    });
  };

  function isAnySelected() {
    return group_1 || group_2 || group_3 || group_4 || group_5;
  }
  return (
    isAnySelected() && (
      <div className="w3-container">
        <div className="w3-row-padding">
          <div className="w3-row">
            <div className="w3-container w3-teal w3-card ">
              {/* Desktop */}
              <header className="w3-row w3-container w3-hide-small">
                <div className="w3-col w3-mobile" style={{ width: '20%' }}>
                  <PlayerAvatar allPlayersData={state.players} id={group_1} />
                </div>
                <div className="w3-col w3-mobile" style={{ width: '20%' }}>
                  <PlayerAvatar allPlayersData={state.players} id={group_2} />
                </div>
                <div className="w3-col w3-mobile" style={{ width: '20%' }}>
                  <PlayerAvatar allPlayersData={state.players} id={group_3} />
                </div>
                <div className="w3-col w3-mobile" style={{ width: '20%' }}>
                  <PlayerAvatar allPlayersData={state.players} id={group_4} />
                </div>
                <div className="w3-col w3-mobile" style={{ width: '20%' }}>
                  <PlayerAvatar allPlayersData={state.players} id={group_5} />
                </div>
              </header>
              {/* Mobile */}
              <header className="w3-container w3-hide-medium w3-hide-large">
                <div className="w3-row">
                  <div className="w3-col s6">
                    <PlayerAvatar allPlayersData={state.players} id={group_1} />
                  </div>
                  <div className="w3-col s6">
                    <PlayerAvatar allPlayersData={state.players} id={group_2} />
                  </div>
                </div>
                <div className="w3-row">
                  <div className="w3-col s6">
                    <PlayerAvatar allPlayersData={state.players} id={group_3} />
                  </div>
                  <div className="w3-col s6">
                    <PlayerAvatar allPlayersData={state.players} id={group_4} />
                  </div>
                </div>
                <div className="w3-row">
                  <div className="w3-col s12">
                    <PlayerAvatar allPlayersData={state.players} id={group_5} />
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default SelectionsContainer;
