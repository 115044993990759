import React from 'react';
import HorseOptionResult from './HorseOptionResult';

const ExpandedRow = (props) => {
  const { data } = props;

  return (
    <>
      <div className="w3-row" style={{ maxWidth: '100vw' }}>
        {data?.picks &&
          Object.keys(data.picks).map((key) => {
            return (
              <HorseOptionResult
                key={key}
                option={data.picks[key]}
                result={data.results[key]}
              />
            );
          })}
      </div>
    </>
  );
};

export default React.memo(ExpandedRow);
