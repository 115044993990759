import axios from 'axios';
import { uploadFile } from './S3Helper';

export function createSweep(user, sweep, callback) {
  if (sweep.alias.indexOf(' ') >= 0) {
    alert('Cannot create an alias with spaces');
  } else {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/sweeps',
        {
          sweep: {
            user_id: user.userId,
            the_sweep: sweep.the_sweep,
            organisation_id: sweep.organisation_id,
            admins: sweep.admins,
            competition_id: sweep.competition_id,
            prize_breakdown: sweep.prize_breakdown,
            connected_account_id: sweep.connected_account_id,
            platform_fee: sweep.platform_fee,
            entry_fee: sweep.entry_fee,
            private_sweep: sweep.private_sweep,
            alias: sweep.alias,
            file_name: sweep?.file?.name ? sweep.file.name : '',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user.id_token}`,
          },
        },
      )
      .then(function (response) {
        uploadFile(
          response,
          {
            file_name: sweep.file,
          },
          callback,
        );
      })
      .catch(function (error) {
        callback(error);
      });
  }
}

export function editSweep(user, sweep, callback) {
  axios
    .post(
      process.env.REACT_APP_API_URL + '/sweeps/edit',
      {
        sweep: {
          id: sweep.id,
          the_sweep: sweep.the_sweep,
          organisation_id: sweep.organisation_id,
          admins: sweep.admins,
          competition_id: sweep.competition_id,
          prize_breakdown: sweep.prize_breakdown,
          connected_account_id: sweep.connected_account_id,
          platform_fee: sweep.platform_fee,
          entry_fee: sweep.entry_fee,
          private_sweep: sweep.private_sweep,
          file_name: sweep?.file?.name ? sweep.file.name : '',
        },
      },
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    )
    .then(function (response) {
      uploadFile(
        response,
        {
          file_name: sweep.file,
        },
        callback,
      );
    })
    .catch(function (error) {
      callback(error);
    });
}

export const getUrlAlias = (sweep) => {
  if (sweep?.id && sweep?.alias) {
    return sweep.alias;
  }
  if (sweep?.id) {
    return sweep.id;
  }
  return 'masters23';
};

export const getEntryFee = (sweep) => {
  return `€${parseInt(sweep?.entry_fee) / 100 || '10'}`;
};

export const hasTiebreakQuestion = (sweep) => {
  if (sweep.competition?.sport === 'golf') {
    return true;
  }
  return (
    sweep.competition?.tiebreaker_question != undefined ||
    sweep.competition?.tiebreaker_question != ''
  );
};
