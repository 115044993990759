import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Entries from './Entries';

function EntriesIndexWrapper(props) {
  const { user } = props;

  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    if (!user) {
      navigate('/');
    }
  }, [user]);

  return (
    <>
      <div className="w3-row-padding w3-margin-bottom">
        <div className="w3-container w3-margin-top">
          {user && (
            <>
              <Entries user={user} />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EntriesIndexWrapper;
