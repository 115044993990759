/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Modal from '../Layouts/Modal';
import EditSweep from './EditSweep';

function EditSweepModal(props) {
  const { sweep, user } = props;
  return (
    <React.Fragment>
      <Modal
        id={`editSweep-${sweep.id}`}
        child={<EditSweep user={user} sweep={sweep} />}
      />
    </React.Fragment>
  );
}

export default EditSweepModal;
