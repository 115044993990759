/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function EqualHeightColumns(props) {
  const { children } = props;

  return (
    <div className="w3-row-padding col-container">
      {children.map((child) => {
        return <div className="col-mine">{child}</div>;
      })}
    </div>
  );
}

export default EqualHeightColumns;
