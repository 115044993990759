import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import store from './app/store';
import { Provider } from 'react-redux';
import Terms from './Terms';
import FourOhFour from './FourOhFour';
import axios from 'axios';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import './fonts/hatton/bold_700.otf';
import './fonts/hatton/medium_500.otf';
import './fonts/hatton/light_200.otf';
import '@fontsource/dm-sans';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import FAQ from './FAQ';
import Dashboard from './Components/Dashboard/Dashboard';
import ConfirmUser from './Components/Account/Login/ConfirmUser';
import RegisterWrapper from './Components/Account/Register/RegisterWrapper';
import { getUserFromLocalStorage } from './Helpers/UserHelper';
import EntriesIndexWrapper from './Components/Entries/EntriesIndexWrapper';
import Index from './Components/Index/Index';
import SweepsGallery from './Components/Sweeps/Gallery';
import FullPageWrapper from './Components/Layouts/FullPageWrapper';
import SweepsSignup from './Components/SweepsSignup/SweepsSignup';
import ReactGA from 'react-ga4';
import { logMetric } from './Helpers/AnalyticsHelper';
import BlogGallery from './Components/Blog/Gallery';
import Post from './Components/Blog/Posts/Post';
import LeaderboardWrapper from './Components/Leaderboard/LeaderboardWrapper';
import ClubsInformation from './Components/Index/ClubsInformation';
import OrganisationsContainer from './Components/Organisations/OrganisationsContainer';
import { ThemeProvider, createTheme } from '@mui/material';
import OrganisationsAccountContainer from './Components/Organisations/Account/Container';
import SweepsAdminContainer from './Components/Sweeps/Admin/SweepsAdminContainer';
import CompetitionsAdminContainer from './Components/Competitions/Admin/CompetitionsAdminContainer';
import BlogContainer from './Components/Blog/Admin/Container';
import EntriesContainer from './Components/Sweeps/Admin/EntriesContainer';
import UsersContainer from './Components/Account/Admin/Container';
import ReferralsContainer from './Components/Referrals/Admin/ReferralsContainer';
import DataTransformationContainer from './Components/Sweeps/Admin/DataTransformationContainer';
import AdminFullPageWrapper from './Components/Layouts/AdminFullPageWrapper';
import ScrollToTop from './Components/Layouts/ScrollToTop';

export default function App() {
  const [state, setState] = useState({
    user: undefined,
    loading: true,
  });

  ReactGA.initialize([
    {
      trackingId: 'G-KG99N0XJ6G',
    },
  ]);

  function handleLogout() {
    logMetric('auth', 'logout');

    setState({
      ...state,
      user: undefined,
    });
  }

  useEffect(() => {
    fetchUserDetails();
  }, []);

  function storeUserInLocalStorage(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  function fetchUserDetails(firstAttempt = true) {
    const parsedUser = getUserFromLocalStorage();
    if (parsedUser) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            '/account?accessToken=' +
            parsedUser.accessToken,
          {
            headers: {
              Authorization: `Bearer ${parsedUser.id_token}`,
            },
          },
        )
        .then(function (response) {
          const user = {
            ...response.data['user'],
            id_token: parsedUser.id_token,
          };
          parsedUser.username = response.data['user']['email'];
          parsedUser.uuid = response.data['user']['userId'];
          parsedUser.adminAccess = response.data['user']['adminAccess'];

          storeUserInLocalStorage(parsedUser);
          setState({
            ...state,
            user: user,
            loading: false,
          });
        })
        .catch(function (error) {
          if (error?.response?.data?.error) {
            setState({
              ...state,
              loading: false,
              user: undefined,
            });
          }
          if (firstAttempt) {
            console.log('Attempting refresh');
            refreshToken(parsedUser);
          } else {
            setState({
              ...state,
              loading: false,
              user: undefined,
            });
            localStorage.removeItem('user');
          }
        });
    } else {
      setState({
        ...state,
        loading: false,
      });
    }
  }

  function isValidRefresh(response) {
    return response.data.id_token;
  }

  function refreshToken(user) {
    const data = {
      username: user.username,
      refresh_token: user.refresh_token,
    };
    axios
      .post(process.env.REACT_APP_API_URL + '/login', data)
      .then(function (response) {
        if (isValidRefresh(response)) {
          const parsedUser = getUserFromLocalStorage();
          if (parsedUser) {
            parsedUser.id_token = response.data.id_token;
            storeUserInLocalStorage(parsedUser);
          }
          fetchUserDetails(false);
        }
      })
      .catch(function (error) {
        setState({
          ...state,
          loading: false,
        });
      });
  }

  const theme = createTheme({
    palette: {
      background: {
        paper: '#FFF', // your color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<Index />}
                />
              }
            />
            <Route
              path="/how-it-works"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={
                    <ClubsInformation
                      user={state.user}
                      fetchUserDetails={fetchUserDetails}
                    />
                  }
                />
              }
            />
            <Route
              path="/:sweep_id"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={
                    <SweepsSignup
                      user={state.user}
                      fetchUserDetails={fetchUserDetails}
                    />
                  }
                />
              }
            />
            <Route
              path="/:sweep_id/entry"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={
                    <SweepsSignup
                      user={state.user}
                      fetchUserDetails={fetchUserDetails}
                    />
                  }
                />
              }
            />
            <Route
              path="/:sweep_id/leaderboard"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<LeaderboardWrapper user={state.user} />}
                />
              }
            />
            <Route
              path="/register"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={
                    <RegisterWrapper
                      user={state.user}
                      fetchUserDetails={fetchUserDetails}
                    />
                  }
                />
              }
            />
            <Route
              path="/entries"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<EntriesIndexWrapper user={state.user} />}
                />
              }
            />
            <Route
              path="/faq"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<FAQ />}
                />
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<Dashboard user={state.user} />}
                />
              }
            />
            <Route
              path="/confirm"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={
                    <ConfirmUser
                      user={state.user}
                      fetchUserDetails={fetchUserDetails}
                    />
                  }
                />
              }
            />
            <Route
              path="/sweeps"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<SweepsGallery />}
                />
              }
            />
            <Route
              path="/results"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<SweepsGallery results={true} />}
                />
              }
            />
            <Route
              path="/:post_id/post"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<Post user={state.user} />}
                />
              }
            />
            <Route
              path="/posts"
              element={
                <FullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<BlogGallery />}
                />
              }
            />
            <Route
              path="admin/dashboard/organisations"
              element={
                <AdminFullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<OrganisationsContainer user={state.user} />}
                />
              }
            />
            <Route
              path="admin/dashboard/sweeps"
              element={
                <AdminFullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<SweepsAdminContainer user={state.user} />}
                />
              }
            />
            <Route
              path="admin/dashboard/sweeps/entries"
              element={
                <AdminFullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<EntriesContainer user={state.user} />}
                />
              }
            />
            <Route
              path="admin/dashboard/sweeps/referrals"
              element={
                <AdminFullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<ReferralsContainer user={state.user} />}
                />
              }
            />
            <Route
              path="admin/dashboard/sweeps/data-transfer"
              element={
                <AdminFullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<DataTransformationContainer user={state.user} />}
                />
              }
            />
            <Route
              path="admin/dashboard/competitions"
              element={
                <AdminFullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<CompetitionsAdminContainer user={state.user} />}
                />
              }
            />
            <Route
              path="admin/dashboard/blog"
              element={
                <AdminFullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<BlogContainer user={state.user} />}
                />
              }
            />
            <Route
              path="admin/dashboard/users"
              element={
                <AdminFullPageWrapper
                  user={state.user}
                  loading={state.loading}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<UsersContainer user={state.user} />}
                />
              }
            />
            <Route path="/terms" element={<Terms />} />
            <Route
              path="/account/organisations"
              element={
                <FullPageWrapper
                  user={state.user}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={<OrganisationsAccountContainer user={state.user} />}
                />
              }
            />
            <Route
              path="/account/organisations/:organisationId/stripe/return"
              element={
                <FullPageWrapper
                  user={state.user}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={
                    <OrganisationsAccountContainer
                      stripeReturn={true}
                      user={state.user}
                    />
                  }
                />
              }
            />
            <Route
              path="/account/organisations/:organisationId/stripe/refresh"
              element={
                <FullPageWrapper
                  user={state.user}
                  fetchUserDetails={fetchUserDetails}
                  handleLogout={handleLogout}
                  child={
                    <OrganisationsAccountContainer
                      stripeRefresh={true}
                      user={state.user}
                    />
                  }
                />
              }
            />
            <Route path="/lost" element={<FourOhFour />} />
            <Route path="/*" element={<FourOhFour />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
