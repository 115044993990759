import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sweepsSlice = createSlice({
  name: 'sweeps',
  initialState: {
    sweeps: {},
    status: 'idle',
    lastUpdated: '',
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSweeps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSweeps.fulfilled, (state, action) => {
        state.status = 'succeeded';
        action.payload.sweeps.forEach((sweep) => {
          state.sweeps[sweep.id] = sweep;
        });
        state.lastUpdated = Date.now();
      })
      .addCase(fetchSweeps.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const fetchSweeps = createAsyncThunk('sweeps/fetchSweeps', async () => {
  const response = await axios.get(process.env.REACT_APP_API_URL + '/sweeps');
  return response.data;
});

export const getSweeps = (state) => state.sweeps.sweeps;

export const getSweepByAlias = (state, alias) => {
  const sweepsObj = state.sweeps.sweeps;
  let returnVal;
  Object.keys(sweepsObj ?? []).forEach((sweepId) => {
    if (sweepsObj[sweepId].alias === alias) {
      returnVal = sweepsObj[sweepId];
    }
  });
  return returnVal;
};

export const getSweepsForOrganisation = (state, organisationId) => {
  const sweepsObj = state.sweeps.sweeps;
  const sweepsForOrg = [];
  Object.keys(sweepsObj ?? []).forEach((sweepId) => {
    if (sweepsObj[sweepId].organisation_id === organisationId) {
      sweepsForOrg.push(sweepsObj[sweepId]);
    }
  });
  return sweepsForOrg;
};

export const getSweepWithId = (state, sweepId) => {
  const sweepsObj = state.sweeps.sweeps;
  return sweepsObj[sweepId];
};

export const getStatus = (state) => state.sweeps.status;
export const getLastUpdated = (state) => state.sweeps.lastUpdated;

export default sweepsSlice.reducer;
