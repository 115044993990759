import React, { useEffect, useState } from 'react';
import { useMyOrganisations } from '../../../features/useMyOrganisations';
import OrganisationsView from './View';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

function OrganisationsAccountContainer(props) {
  const { user, stripeReturn, stripeRefresh } = props;
  const { organisationId } = useParams();
  const { getOrganisationsArray } = useMyOrganisations({ user: user });
  let organisations = getOrganisationsArray();
  const [currentOrganisation, setCurrentOrganisation] = useState();

  const navigate = useNavigate('');

  useEffect(() => {
    if (organisationId && (stripeRefresh || stripeReturn)) {
      organisations.forEach((organisation) => {
        if (organisation.id === organisationId) {
          setCurrentOrganisation(organisation);
        }
      });
    } else if (
      !organisationId &&
      !currentOrganisation &&
      organisations.length > 0
    ) {
      setCurrentOrganisation(organisations[0]);
    }
  }, [organisationId, organisations]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  return organisations.length > 0 ? (
    <OrganisationsView
      user={user}
      organisations={organisations}
      currentOrganisation={currentOrganisation}
      setCurrentOrganisation={setCurrentOrganisation}
      orgDeepLinked={organisationId && (stripeRefresh || stripeReturn)}
      {...props}
    />
  ) : (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}

export default OrganisationsAccountContainer;
