import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material';

function Dashboard(props) {
  const { user } = props;

  const navigate = useNavigate('');

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
    if (!user || !user?.adminAccess) {
      navigate('/');
    }
  }, [user]);

  if (user && user?.adminAccess) {
    return (
      <>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={6} md={4}>
            <Card>
              <CardHeader
                className="w3-text-teal sweeps-font-bold"
                title="Organisations"
              />
              <CardContent>
                <Button
                  onClick={() => navigate('/admin/dashboard/organisations')}
                >
                  Organisations
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
              <CardHeader
                className="w3-text-teal sweeps-font-bold"
                title="Sweeps"
              />
              <CardContent>
                <Button onClick={() => navigate('/admin/dashboard/sweeps')}>
                  Sweeps
                </Button>
                <Button
                  onClick={() => navigate('/admin/dashboard/sweeps/entries')}
                >
                  Entries
                </Button>
                <Button
                  onClick={() => navigate('/admin/dashboard/sweeps/referrals')}
                >
                  Referrals
                </Button>
                <Button
                  onClick={() =>
                    navigate('/admin/dashboard/sweeps/data-transfer')
                  }
                >
                  Data Transformation
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
              <CardHeader
                className="w3-text-teal sweeps-font-bold"
                title="Competitions"
              />
              <CardContent>
                <Button
                  onClick={() => navigate('/admin/dashboard/competitions')}
                >
                  Competitions
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
              <CardHeader
                className="w3-text-teal sweeps-font-bold"
                title="Blog"
              />
              <CardContent>
                <Button onClick={() => navigate('/admin/dashboard/blog')}>
                  Blog
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
              <CardHeader
                className="w3-text-teal sweeps-font-bold"
                title="Users"
              />
              <CardContent>
                <Button onClick={() => navigate('/admin/dashboard/users')}>
                  Users
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
}

export default Dashboard;
