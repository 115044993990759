import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const organisationsSlice = createSlice({
  name: 'organisations',
  initialState: {
    organisations: {},
    fetchingStatus: 'idle',
    lastUpdated: '',
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrganisations.pending, (state) => {
        state.fetchingStatus = 'loading';
      })
      .addCase(fetchOrganisations.fulfilled, (state, action) => {
        state.fetchingStatus = 'succeeded';
        action.payload.organisations.forEach((org) => {
          state.organisations[org.id] = org;
        });
        state.lastUpdated = Date.now();
      })
      .addCase(fetchOrganisations.rejected, (state, action) => {
        state.fetchingStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewOrganisation.fulfilled, (state, action) => {
        const organisation = action.payload.organisation;
        state.organisations[organisation.id] = organisation;
      })
      .addCase(editOrganisation.fulfilled, (state, action) => {
        const organisation = action.payload.organisation;
        state.organisations[organisation.id] = organisation;
      });
  },
});

export const fetchOrganisations = createAsyncThunk(
  'organisations/fetchOrganisations',
  async () => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + '/organisations',
    );
    return response.data;
  },
);

export const addNewOrganisation = createAsyncThunk(
  'organisations/addNewOrganisation',
  async (initialOrganisation) => {
    const user = initialOrganisation?.user;
    delete initialOrganisation.user;
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/organisations',
      initialOrganisation,
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    );
    return response.data;
  },
);

export const editOrganisation = createAsyncThunk(
  'organisations/editOrganisation',
  async (organisation) => {
    const user = organisation?.user;
    delete organisation.user;
    const response = await axios.post(
      process.env.REACT_APP_API_URL + '/organisations/edit',
      organisation,
      {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      },
    );
    return response.data;
  },
);

export const getOrganisations = (state) => state.organisations.organisations;

export const getOrganisationWithId = (state, organisationId) => {
  const organisationsObj = state.organisations.organisations;
  return organisationsObj[organisationId];
};

export const getFetchingStatus = (state) => state.organisations.fetchingStatus;
export const getLastUpdated = (state) => state.organisations.lastUpdated;

export default organisationsSlice.reducer;
