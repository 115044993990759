import louthClubs from '../Data/GaaClubs/louth.json';

export function getClubs() {
  return louthClubs;
}

export function getClubFromKey(key) {
  return louthClubs.filter((club) => club.id === key)[0];
}

export function getClubForUserId(userId) {
  let clubId = '';
  const localStorageUserClubs = localStorage.getItem('usersClubs');
  if (localStorageUserClubs) {
    let usersClubs = JSON.parse(localStorageUserClubs);
    usersClubs.forEach((userClub) => {
      if (userClub?.cognito_id === userId) {
        clubId = userClub?.club_id;
      }
    });
  }
  return clubId;
}
