/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import axios from 'axios';
import { getEntryFee } from '../../Helpers/SweepsHelper';

function InitPayment(props) {
  const {
    receiptEmail,
    objectToStore,
    sweep_id,
    sweep,
    connected_account_id,
    submitSelections,
    previousSlide,
  } = props;

  const [generatedClientSecret, setGeneratedClientSecret] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [errors, setErrors] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  let stripePromise;
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, {
      stripeAccount: connected_account_id[0]['account_id'],
    });
  }

  useEffect(() => {
    if (!paymentIntentId || !generatedClientSecret) {
      // TODO this still generates every time
      // can support every sport
      axios
        .post(apiUrl + '/entries/createPayment', {
          data: {
            receipt_email: receiptEmail,
            object_to_store: objectToStore(),
            sweep_id: sweep_id,
          },
        })
        .then(function (response) {
          setGeneratedClientSecret(response.data.paymentIntent.client_secret);
          setPaymentIntentId(response.data.paymentIntent.id);
        })
        .catch(function (error) {
          console.log(error);
          setErrors(error.response.message);
        });
    }
  }, []);

  const options = {
    clientSecret: generatedClientSecret,
  };

  return (
    <React.Fragment>
      {generatedClientSecret && (
        <div className="w3-padding">
          <h3 className="w3-text-teal">Pay and Submit</h3>
          <p>{getEntryFee(sweep)}</p>
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm
              submitSelections={submitSelections}
              paymentIntentId={paymentIntentId}
              previousSlide={previousSlide}
            />
          </Elements>
        </div>
      )}
      {!generatedClientSecret && (
        <>
          <div className="w3-padding">
            <h3 className="w3-text-teal">Pay and Submit</h3>
            <p>€10</p>
            {errors !== '' && (
              <>
                <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-red">
                  <p className="w3-margin-top">
                    <i className={`fa fa-warning fa-fw w3-margin-right`} />
                    {errors}
                  </p>
                </div>
                <button
                  onClick={() => previousSlide()}
                  className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
                >
                  Back
                </button>
              </>
            )}
            {!errors && (
              <Oval
                wrapperStyle={{ margin: 'auto' }}
                ariaLabel="loading-indicator"
                height={50}
                width={100}
                strokeWidth={5}
                strokeWidthSecondary={1}
                color="teal"
                secondaryColor="white"
              />
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default InitPayment;
