/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Group from './Group';
import HowItWorks from '../HowItWorks';
import Card from '../../Layouts/Card';
import Rules from './Rules';

function SignupForm(props) {
  const { context, handlers } = props;

  const { sweep, options, activeSlide, picks } = context;

  const { nextSlide, previousSlide, handleOptionPicked, handleOptionUnpicked } =
    handlers;

  return (
    <div data-testid="horseAuctionSignupForm">
      {activeSlide === 0 && (
        <>
          <HowItWorks nextSlide={nextSlide} sweep={sweep}>
            <Rules sweep={sweep} />
          </HowItWorks>
        </>
      )}

      {activeSlide === 1 && (
        <Card>
          <Group
            options={options.filter((option) => option.Group === 1)}
            groupTitle="Group 1"
            picks={picks}
            handleOptionPicked={handleOptionPicked}
            handleOptionUnpicked={handleOptionUnpicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}
      {activeSlide === 2 && (
        <Card>
          <Group
            options={options.filter((option) => option.Group === 2)}
            groupTitle="Group 2"
            picks={picks}
            handleOptionPicked={handleOptionPicked}
            handleOptionUnpicked={handleOptionUnpicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}
      {activeSlide === 3 && (
        <Card>
          <Group
            options={options.filter((option) => option.Group === 3)}
            groupTitle="Group 3"
            picks={picks}
            handleOptionPicked={handleOptionPicked}
            handleOptionUnpicked={handleOptionUnpicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}
      {activeSlide === 4 && (
        <Card>
          <Group
            options={options.filter((option) => option.Group === 4)}
            groupTitle="Group 4"
            picks={picks}
            handleOptionPicked={handleOptionPicked}
            handleOptionUnpicked={handleOptionUnpicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}
      {activeSlide === 5 && (
        <Card>
          <Group
            options={options.filter((option) => option.Group === 5)}
            groupTitle="Group 5"
            picks={picks}
            handleOptionPicked={handleOptionPicked}
            handleOptionUnpicked={handleOptionUnpicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}
    </div>
  );
}

export default SignupForm;
