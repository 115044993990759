/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import Error from '../Error';
import {
  fetchCompetitions,
  getCompetitionFromCompId,
} from '../../../Helpers/CompetitionHelper';

function CompetitionsField(props) {
  const { errors, handleChange, value } = props;

  const [state, setState] = React.useState({
    competitions: [],
  });

  useEffect(() => {
    fetchCompetitions(handleCompetitionsLoaded);
  }, []);

  function handleCompetitionsLoaded(response) {
    if (response.data) {
      setState({
        ...state,
        competitions: response.data['competitions'],
      });
    }
  }

  const input = (
    <select
      defaultValue={''}
      onChange={handleChange}
      className="w3-select"
      name="competition_id"
    >
      {value && value !== 'none' && (
        <option value={value} selected>
          {getCompetitionFromCompId(state.competitions, value)?.name}
        </option>
      )}
      <option value="none">Select Competition</option>
      {state.competitions.map((comp) => {
        return (
          <option key={comp.id} value={comp.id}>
            {comp.name}
          </option>
        );
      })}
    </select>
  );

  return (
    <React.Fragment>
      <label className={`w3-large w3-text-teal w3-margin-top`}>
        <b>Select Competition</b>
      </label>
      {errors && errors['competition_id'] && (
        <Error error={errors['competition_id']} />
      )}
      {input}
    </React.Fragment>
  );
}

export default CompetitionsField;
