export const getNextSlide = (
  currentSlide,
  numberOfGroups,
  tieBreakSlide,
  loggedIn,
  registerSlide,
  nickNameSlide,
  paymentSlide,
  hasTiebreak,
) => {
  if (currentSlide === nickNameSlide) {
    return paymentSlide;
  }
  if (currentSlide === tieBreakSlide && loggedIn) {
    // Skip the login / register slide
    return nickNameSlide;
  }
  if (currentSlide !== tieBreakSlide || !loggedIn) {
    if (currentSlide === numberOfGroups) {
      if (!hasTiebreak) {
        if (loggedIn) {
          return nickNameSlide;
        } else {
          return registerSlide;
        }
      } else {
        return tieBreakSlide;
      }
    }
    return currentSlide + 1;
  }
};

export const getPreviousSlide = (
  previousSlide,
  currentSlide,
  numberOfGroups,
  tieBreakSlide,
  registerSlide,
  nickNameSlide,
  hasTiebreak,
) => {
  if (previousSlide) {
    return previousSlide;
  } else {
    if (currentSlide === nickNameSlide) {
      return hasTiebreak ? tieBreakSlide : numberOfGroups;
    } else if (currentSlide === registerSlide) {
      return hasTiebreak ? tieBreakSlide : numberOfGroups;
    } else if (currentSlide === tieBreakSlide) {
      return numberOfGroups;
    } else if (
      currentSlide - 1 < tieBreakSlide &&
      currentSlide - 1 > numberOfGroups
    ) {
      return registerSlide;
    } else {
      return currentSlide - 1;
    }
  }
};
