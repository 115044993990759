/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Error from '../../Form/Error';
import Field from '../../Form/Fields/Field';

function CreatePassword(props) {
  const {
    newPassword,
    confirmNewPassword,
    errors,
    loginFailed,
    handleChange,
    onBlur,
    updatePassword,
  } = props;
  return (
    <React.Fragment>
      <>
        <h4 className="sweeps-font-bold w3-medium w3-text-teal">
          Create your own password:
        </h4>
        <Field
          label="Password"
          errors={errors}
          onBlur={onBlur}
          handleChange={handleChange}
          name="newPassword"
          value={newPassword}
          type="password"
        />
        <Field
          label="Confirm Password"
          errors={errors}
          onBlur={onBlur}
          handleChange={handleChange}
          name="confirmNewPassword"
          value={confirmNewPassword}
          type="password"
        />
        {loginFailed && <Error error={errors['form']} />}
        <button
          onClick={() => updatePassword()}
          className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        >
          Update Password
        </button>
      </>
    </React.Fragment>
  );
}

export default CreatePassword;
