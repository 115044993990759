/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import PlayerAvatar from '../../Golfers/PlayerAvatar';
import PlayerDetails from '../../Golfers/PlayerDetails';
import { fetchDataGolfRanking } from '../../../Helpers/DataGolfHelper';

function Group(props) {
  const {
    players,
    allPlayersData,
    selected,
    groupTitle,
    groupKey,
    handlePlayerPicked,
    previousSlide,
    nextSlide,
    hidePrevious,
  } = props;

  const [state, setState] = React.useState({
    selected: '',
  });

  function handlePlayerPickedWithinGroup(id, group) {
    handlePlayerPicked(id, group);
    setState({
      ...state,
      selected: id,
    });
  }

  useEffect(() => {
    setState({
      ...state,
      selected: selected,
    });
  }, []);

  const sortedPlayers = players.sort(function (a, b) {
    const aRanking = fetchDataGolfRanking(a)?.datagolf_rank || 100000;
    const bRanking = fetchDataGolfRanking(b)?.datagolf_rank || 100000;
    return aRanking - bRanking;
  });
  const divider = sortedPlayers.length / 3;
  const columnOne = sortedPlayers.slice(0, divider);
  const columnTwo = sortedPlayers.slice(divider, divider * 2);
  const columnThree = sortedPlayers.slice(divider * 2, sortedPlayers.length);

  const progressButton =
    state.selected !== '' ? (
      <button
        onClick={() => nextSlide()}
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
      >
        Next
      </button>
    ) : (
      <button
        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
        disabled
      >
        Next
      </button>
    );

  const mobile = window.innerWidth <= 600; // The with we move to row per player.
  const selectedOnMobile = state.selected && mobile;
  const selectOnDesktop = state.selected && !mobile;

  return (
    <>
      <div className="w3-row-padding">
        <h4 className="sweeps-font-medium w3-text-teal">{groupTitle}</h4>
        <p>Select a player and continue to the next group</p>
      </div>
      {selectOnDesktop && (
        <>
          <div className="w3-third">
            <PlayerDetails
              allPlayersData={allPlayersData}
              selectedPlayerId={state.selected}
            />
          </div>
        </>
      )}
      <div className={selectOnDesktop ? 'w3-twothird' : 'w3-row'}>
        <div className="w3-third">
          {columnOne.map((player) => (
            <>
              <PlayerAvatar
                allPlayersData={allPlayersData}
                key={player.id}
                handlePlayerPicked={handlePlayerPickedWithinGroup}
                group={groupKey}
                active={player.id === state.selected}
                id={player.id}
              />
              {player.id === state.selected && selectedOnMobile && (
                <>
                  <div className="w3-row">
                    <PlayerDetails
                      allPlayersData={allPlayersData}
                      mobile={true}
                      selectedPlayerId={player.id}
                    />
                  </div>
                </>
              )}
            </>
          ))}
        </div>
        <div className="w3-third">
          {columnTwo.map((player) => (
            <>
              <PlayerAvatar
                allPlayersData={allPlayersData}
                key={player.id}
                handlePlayerPicked={handlePlayerPickedWithinGroup}
                group={groupKey}
                active={player.id === state.selected}
                id={player.id}
              />
              {player.id === state.selected && selectedOnMobile && (
                <>
                  <div className="w3-row">
                    <PlayerDetails
                      allPlayersData={allPlayersData}
                      mobile={true}
                      selectedPlayerId={player.id}
                    />
                  </div>
                </>
              )}
            </>
          ))}
        </div>
        <div className="w3-third">
          {columnThree.map((player) => (
            <>
              <PlayerAvatar
                allPlayersData={allPlayersData}
                key={player.id}
                handlePlayerPicked={handlePlayerPickedWithinGroup}
                group={groupKey}
                active={player.id === state.selected}
                id={player.id}
              />
              {player.id === state.selected && selectedOnMobile && (
                <>
                  <div className="w3-row">
                    <PlayerDetails
                      allPlayersData={allPlayersData}
                      mobile={true}
                      selectedPlayerId={player.id}
                    />
                  </div>
                </>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="w3-row-padding">
        {!hidePrevious && (
          <button
            onClick={() => previousSlide()}
            className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
          >
            Back
          </button>
        )}

        {progressButton}
      </div>
    </>
  );
}

export default Group;
