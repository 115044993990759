/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Group from './Group';
import Card from '../../Layouts/Card';
import HowItWorks from '../HowItWorks';
import Rules from './Rules';

const Form = (props) => {
  const { context, handlers } = props;

  const { sweep, matches, activeSlide, picks } = context;

  const { nextSlide, previousSlide, handleMatchPicked } = handlers;

  return (
    <div>
      {activeSlide === 0 && (
        <>
          <HowItWorks nextSlide={nextSlide} sweep={sweep}>
            <Rules sweep={sweep} />
          </HowItWorks>
        </>
      )}
      {activeSlide === 1 && (
        <Card>
          <Group
            matches={matches
              .filter((match) => match.group === 1)
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              })}
            groupTitle="Group 1"
            picks={picks}
            handleMatchPicked={handleMatchPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}

      {activeSlide === 2 && (
        <Card>
          <Group
            matches={matches
              .filter((match) => match.group === 2)
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              })}
            groupTitle="Group 2"
            picks={picks}
            handleMatchPicked={handleMatchPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}

      {activeSlide === 3 && (
        <Card>
          <Group
            matches={matches
              .filter((match) => match.group === 3)
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              })}
            groupTitle="Group 3"
            picks={picks}
            handleMatchPicked={handleMatchPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}

      {activeSlide === 4 && (
        <Card>
          <Group
            matches={matches
              .filter((match) => match.group === 4)
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              })}
            groupTitle="Group 4"
            picks={picks}
            handleMatchPicked={handleMatchPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}
    </div>
  );
};

export default Form;
