import React from 'react';

function TwoHundredGiveaway() {
  return (
    <React.Fragment>
      <h3 className="w3-large sweeps-font-bold w3-text-sviolet">
        Prize Details
      </h3>
      <ul
        style={{
          listStyle: 'none',
          marginBlockStart: '0',
          paddingInlineStart: '0',
        }}
      >
        <li>🥇 = €200</li>
      </ul>
      <i className="w3-small">
        One entrant with the correct score will be choosen at random to win €200
      </i>
    </React.Fragment>
  );
}

export default TwoHundredGiveaway;
