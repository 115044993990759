/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

function Team(props) {
  const { team, selected, setSelected, score, showScore, noName } = props;
  return (
    <div className="w3-col" style={{ width: '45%' }}>
      <div className="w3-container">
        <img
          data-testid="team-image"
          src={team?.imageUrl}
          style={{ aspectRatio: '4.5/3.7' }}
          className={'w3-circle centeredImage'}
        />

        {showScore && score && (
          <span className="centeredImage w3-small" style={{ height: '25px' }}>
            {score.score}
          </span>
        )}

        {!noName && (
          <span className="centeredImage" style={{ height: '50px' }}>
            {team?.name}
          </span>
        )}

        {!showScore && (
          <button
            data-testid="teamButton"
            className={`w3-padding w3-margin-top w3-small w3-center w3-button w3-block w3-border ${
              selected === team?.teamId ? 'w3-teal' : ''
            }`}
            onClick={() => setSelected(team?.teamId)}
          >
            {team?.shortName}
          </button>
        )}
      </div>
    </div>
  );
}

export default Team;
