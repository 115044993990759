import PasswordValidator from 'password-validator';
import { toast } from 'react-toastify';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const validatePassword = (password, confirmPassword) => {
  const errorsArray = [];
  const validator = getPasswordValidator();
  if (validator.validate(password, { details: true }).length > 0) {
    errorsArray.push('password_doesnt_meet_criteria');
    const failedRules = validator.validate(password, { details: true });
    failedRules.forEach((failedRule) =>
      toast.error(failedRule.message, {
        toastId: failedRule.message,
      }),
    );
  }

  if (confirmPassword !== '' && password !== confirmPassword) {
    errorsArray.push('Passwords must match!');
    toast.error('Passwords must match!', {
      toastId: 'passwords_match_password',
    });
  }

  return errorsArray;
};

export const isValidPassword = (password) => {
  return (
    getPasswordValidator().validate(password, { details: true }).length === 0
  );
};

export const validateRequiredField = (targetName, value) => {
  const errorsArray = [];
  if (!value || !/\S/.test(value)) {
    errorsArray.push('Mandatory field, please enter a value');
    toast.error('Mandatory field, please enter a value', {
      toastId: 'mandatory_field_' + targetName,
    });
  }

  console.log(errorsArray);

  return errorsArray;
};

export const validatePhoneNumber = (targetName, value) => {
  const errorsArray = [];
  if (targetName === 'phone' && !isValidPhoneNumber(value)) {
    toast.error('Invalid phone number', {
      toastId: 'invalid_phone_number',
    });
    errorsArray.push('Invalid phone number');
  }

  return errorsArray;
};

export const validateEmail = (targetName, email, emailConfirmation) => {
  const errorsArray = [];
  if (
    (targetName === 'emailConfirmation' || targetName === 'email') &&
    emailsPopulated(email, emailConfirmation) &&
    !isEmailsMatching(email, emailConfirmation)
  ) {
    toast.error('Emails must match', {
      toastId: 'emails_must_match',
    });
    errorsArray.push('Emails must match');
  }

  return errorsArray;
};

const isEmailsMatching = (email, emailConfirmation) => {
  return email === emailConfirmation;
};

const emailsPopulated = (email, emailConfirmation) => {
  return email !== '' && emailConfirmation !== '';
};

const getPasswordValidator = () => {
  return new PasswordValidator()
    .is()
    .min(8)
    .is()
    .max(100)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits(1)
    .has()
    .not()
    .spaces();
};
