import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { editPost } from '../../../Helpers/BlogHelper';

import SuccessPanel from '../../Form/SuccessPanel';
import Form from './Form';

function EditPost(props) {
  const { user, post } = props;

  const navigate = useNavigate('');
  if (!user?.adminAccess) {
    navigate('/');
  }

  const [state, setState] = React.useState({
    id: post.id,
    title: post.title,
    competition_id: post.competition_id,
    body: post.body,
    user_id: user?.userId,
    file: undefined,
    errors: {},
    successfulCreate: false,
    failedCreate: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleChange(evt) {
    const value =
      evt.target.name === 'file' ? evt.target.files[0] : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const createCallback = (success) => {
    setState({
      ...state,
      failedCreate: !success,
      successfulCreate: success,
    });
  };

  return (
    <div class="w3-padding">
      {state.successfulCreate && (
        <SuccessPanel message="Successfully edited post" />
      )}
      {state.failedCreate && 'Failed to edit post'}
      <h3 className="sweeps-font-bold w3-text-teal">Edit Blog Post</h3>

      <hr />
      <div className="w3-container">
        <div className="w3-row">
          <img
            className="center"
            style={{ maxHeight: '300px' }}
            src={post.image_url}
          />
        </div>
      </div>
      <hr />

      <Form
        user={user}
        errors={state.errors}
        handleChange={handleChange}
        title={state.title}
        body={state.body}
        competition_id={state.competition_id}
        file={state.file}
      />

      <button
        onClick={() => editPost(user, state, createCallback)}
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Edit
      </button>
    </div>
  );
}

export default EditPost;
