/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import { getUserFromLocalStorage } from '../../../Helpers/UserHelper';
import { validateReferral } from '../../../Helpers/ReferralsHelper';
import { logMetric } from '../../../Helpers/AnalyticsHelper';
import { hasTiebreakQuestion } from '../../../Helpers/SweepsHelper';
import { getNextSlide, getPreviousSlide } from '../../../Helpers/SlideHelper';
import { validateRequiredField } from '../../../Helpers/RegisterHelper';
import { useRugbyForm } from './useRugbyForm';
import { useHorseAuctionForm } from './useHorseAuctionForms';
import { useGolfForm } from './useGolfForm';

// DUPLICATED in useForm
const TIEBREAK_SLIDE = 50;
const REGISTER_SLIDE = 51;
const NICK_NAME_SLIDE = 52;
const INIT_PAYMENT_SLIDE = 53;
const SUMBIT_NO_PAYMENT_SLIDE = 54;
const ERROR_SLIDE = 60;

export const useForm = ({
  user,
  sweep,
  referralCode,
  entryType,
  scrollToReference,
}) => {
  const [state, setState] = useState({
    email: '',
    nickName: '',
    tieBreaker: 0,
    activeSlide: 0,
    numberOfGroups: 0,
    errors: {},
    userId: '',
    referralCode,
    myReferralCode: '',
    type: entryType,
    successfulSignup: false,
  });

  const setNumberOfGroups = (numberOfGroups) => {
    setState({
      ...state,
      numberOfGroups: numberOfGroups,
    });
  };

  const setActiveSlide = (activeSlide) => {
    setState({
      ...state,
      activeSlide: activeSlide,
    });
  };

  const executeScroll = () => scrollToReference.current.scrollIntoView();

  const { handlers: rugbyHandlers, context: rugbyContext } = useRugbyForm({
    sweep,
    setNumberOfGroups,
  });

  const { handlers: horseAuctionHandlers, context: horseAuctionContext } =
    useHorseAuctionForm({
      sweep,
      setNumberOfGroups,
    });

  const { handlers: golfHandlers, context: golfContext } = useGolfForm({
    sweep,
    setNumberOfGroups,
    setActiveSlide,
    executeScroll,
  });

  const competitionHandlersMap = {
    rugby: rugbyHandlers,
    horse_auction: horseAuctionHandlers,
    golf: golfHandlers,
  };
  const competitionContextsMap = {
    rugby: rugbyContext,
    horse_auction: horseAuctionContext,
    golf: golfContext,
  };

  const competitionFormHandlers =
    competitionHandlersMap[sweep.competition?.sport];
  const competitionFormContext =
    competitionContextsMap[sweep.competition?.sport];

  useEffect(() => {
    if (
      (state.activeSlide === TIEBREAK_SLIDE ||
        (state.activeSlide === state.numberOfGroups &&
          !hasTiebreakQuestion(sweep))) &&
      user
    ) {
      getUserDetailsFromLocalStorage();
    }
  }, [state.activeSlide, sweep]);

  useEffect(() => {
    if (state.userId && state.email && state.activeSlide === REGISTER_SLIDE) {
      setState({
        ...state,
        activeSlide: NICK_NAME_SLIDE,
      });
    }
  }, [state.userId, state.email, state.activeSlide]);

  const nextSlide = () => {
    executeScroll();
    const paymentSlide = sweep?.private_sweep
      ? SUMBIT_NO_PAYMENT_SLIDE
      : INIT_PAYMENT_SLIDE;
    const nextSlide = getNextSlide(
      state.activeSlide,
      state.numberOfGroups,
      TIEBREAK_SLIDE,
      user,
      REGISTER_SLIDE,
      NICK_NAME_SLIDE,
      paymentSlide,
      hasTiebreakQuestion(sweep),
    );

    logMetric('signup', 'nextSignupSlide', nextSlide);

    if (nextSlide === paymentSlide && user && entryType === 'claim') {
      console.log('Checking if validation');
      validateReferral(sweep, user, setRefferalPaymentSlide);
    } else {
      setState({
        ...state,
        activeSlide: nextSlide,
      });
    }
  };

  // Callback function from checking for valid free entry
  const setRefferalPaymentSlide = (isReferralCodeValid) => {
    const moveToSlide = isReferralCodeValid
      ? SUMBIT_NO_PAYMENT_SLIDE
      : INIT_PAYMENT_SLIDE;
    setState({
      ...state,
      activeSlide: moveToSlide,
    });
  };

  const previousSlide = (slide) => {
    executeScroll();
    const previousSlide = getPreviousSlide(
      slide,
      state.activeSlide,
      state.numberOfGroups,
      TIEBREAK_SLIDE,
      REGISTER_SLIDE,
      NICK_NAME_SLIDE,
      hasTiebreakQuestion(sweep),
    );
    setState({
      ...state,
      activeSlide: previousSlide,
    });
  };

  function onRegisteredUser(userId, email) {
    if (userId && email) {
      setState({
        ...state,
        userId: userId,
        email: email,
      });
    } else {
      getUserDetailsFromLocalStorage();
    }
  }

  function getUserDetailsFromLocalStorage() {
    const user = getUserFromLocalStorage();
    if (user?.username) {
      setState({
        ...state,
        userId: user.uuid,
        email: user.username,
      });
    } else {
      setTimeout(() => {
        getUserDetailsFromLocalStorage();
      }, 1000);
    }
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function onBlur(evt) {
    const value = evt.target.value;
    let errors = [];
    errors = validateRequiredField(evt.target.name, value);
    if (errors.length > 0) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: errors,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
          form: undefined,
        },
      });
    }
  }

  const handleSuccessfulSubmission = (referralCode) => {
    setState({
      ...state,
      successfulSignup: true,
      myReferralCode: referralCode,
    });
  };

  const handlFailedSubmission = (error) => {
    setState({
      ...state,
      activeSlide: ERROR_SLIDE,
      errors: {
        ...state.errors,
        submitSelections: {
          messages: [error.response.data.message],
        },
      },
    });
  };

  function submitSelections(paymentId, freeEntry) {
    if (freeEntry) {
      logMetric('referral', 'signupWithFreeEntry');
    }
    competitionFormHandlers.onSubmit(
      state.userId,
      state.email,
      state.nickName,
      sweep,
      state.referralCode,
      paymentId,
      freeEntry,
      state.tieBreaker,
      handleSuccessfulSubmission,
      handlFailedSubmission,
    );
  }

  const getObjectToStoreInStripe = () => {
    return competitionFormHandlers.getObjectShape(
      state.tieBreaker,
      state.nickName,
    );
  };

  return {
    state,
    handlers: {
      ...competitionFormHandlers,
      getObjectToStoreInStripe,
      submitSelections,
      setNumberOfGroups,
      onBlur,
      onRegisteredUser,
      handleChange,
      nextSlide,
      previousSlide,
    },
    context: {
      ...competitionFormContext,
    },
  };
};
