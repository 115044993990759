import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

function TwitterFeed(props) {
  const { textColor } = props;

  return (
    <React.Fragment>
      <h3 className={`w3-large sweeps-font-bold ${textColor}`}>Latest News</h3>
      <TwitterTimelineEmbed
        id="1546551896789471233"
        onLoad={function noRefCheck() {}}
        options={{
          height: 700,
        }}
        sourceType="list"
      />
    </React.Fragment>
  );
}

export default TwitterFeed;
