import { useGridApiContext } from '@mui/x-data-grid';
import React from 'react';
import { WYSIWYG } from '../Form/Fields/WYSIWYG';

function WYSIWYGDataTable(props) {
  const { value, id, field } = props;

  let apiRef = useGridApiContext();

  const handleChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <WYSIWYG onChange={handleChange} name="bio" value={value || ''} />;
}

export default WYSIWYGDataTable;
