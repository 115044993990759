import React from 'react';
import { getPrizeForKey } from '../../Helpers/PrizeHelper';

function TwentyFiveEuro(props) {
  const { prizeKey } = props;
  const prize = getPrizeForKey(prizeKey);
  return (
    <React.Fragment>
      <h3 className="w3-large sweeps-font-bold w3-text-sviolet">
        Prize Details
      </h3>
      <ul
        style={{
          listStyle: 'none',
          marginBlockStart: '0',
          paddingInlineStart: '0',
        }}
      >
        <li>🥇 = €{prize.prizes[0]}</li>
      </ul>
    </React.Fragment>
  );
}

export default TwentyFiveEuro;
