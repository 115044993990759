/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Error from '../../Form/Error';
import Field from '../../Form/Fields/Field';

function Login(props) {
  const {
    email,
    password,
    errors,
    loginFailed,
    handleChange,
    handleSubmit,
    navigateToRegister,
    setResetPassword,
  } = props;
  return (
    <React.Fragment>
      <>
        <h4 className="sweeps-font-bold w3-large w3-text-teal w3-margin-top">
          Login
        </h4>
        <Field
          label="Email"
          errors={errors}
          handleChange={handleChange}
          name="email"
          value={email}
        />
        <Field
          label="Password"
          errors={errors}
          handleChange={handleChange}
          name="password"
          type="password"
          value={password}
        />
        {loginFailed && <Error error={errors['form']} />}
        <p>
          Don't have an account? Register{' '}
          <a
            className="w3-text-teal pointer"
            onClick={() => navigateToRegister()}
          >
            here
          </a>
        </p>
        <p>
          Forgot your password? Reset{' '}
          <a
            className="w3-text-teal pointer"
            onClick={() => setResetPassword(true)}
          >
            now
          </a>
        </p>
        <button
          onClick={() => handleSubmit()}
          className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
        >
          Login
        </button>
        <p className="w3-small">
          Can't login? Check our{' '}
          <a className="w3-text-teal pointer" href="/faq">
            frequently asked questions
          </a>
        </p>
      </>
    </React.Fragment>
  );
}

export default Login;
