/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import AuthWrapper from './AuthWrapper';
import Modal from '../../Layouts/Modal';

function AuthModal(props) {
  const { fetchUserDetails, successMessage } = props;
  return (
    <React.Fragment>
      <Modal
        id="loginForm"
        style={{ color: 'black' }}
        child={
          <AuthWrapper
            fetchUserDetails={fetchUserDetails}
            successMessage={successMessage}
          />
        }
      />
    </React.Fragment>
  );
}

export default AuthModal;
