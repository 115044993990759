import React, { useEffect } from 'react';
import Table from '../Layouts/Table';
import { fetchCompetitions } from '../../Helpers/CompetitionHelper';
import CompetitionName from './CompetitionName';
import EditCompetitionModal from './EditCompetitionModal';

function Competitions(props) {
  const { user } = props;
  const [state, setState] = React.useState({
    competitions: [],
  });

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
    fetchCompetitions(updateCompetitionsState);
  }, []);

  const refreshCompetitions = () => {
    fetchCompetitions(updateCompetitionsState);
  };

  function updateCompetitionsState(response) {
    if (response.data) {
      setState({
        ...state,
        competitions: response.data['competitions'],
      });
    } else if (response.response) {
      setState({
        ...state,
        competitions: [],
      });
    }
  }

  function getColumns() {
    return [
      {
        name: '',
        cell: (row) => (
          <button
            onClick={() =>
              (document.getElementById(
                `editCompetition-${row.id}`,
              ).style.display = 'block')
            }
            className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
          >
            Edit
          </button>
        ),
      },
      {
        name: 'Name',
        cell: (row) => <CompetitionName competition={row} />,
      },
      {
        name: 'Sport',
        selector: (row) => row.sport,
      },
      {
        name: 'Format',
        selector: (row) => row.format,
      },
      {
        name: 'Date',
        selector: (row) => row.date_created,
        sortable: true,
      },
      {
        name: 'Start Date',
        selector: (row) => row.start_date,
        sortable: true,
        wrap: true,
      },
      {
        name: 'End Date',
        selector: (row) => row.end_date,
        sortable: true,
        wrap: true,
      },
      {
        name: 'Entries Open',
        selector: (row) => row.entry_open_date,
        sortable: true,
        wrap: true,
      },
      {
        name: 'Entries Close',
        selector: (row) => row.entry_close_date,
        sortable: true,
        wrap: true,
      },
    ];
  }

  return (
    <>
      <h3 className="sweeps-font-bold w3-text-teal">Competitions</h3>
      {state.competitions.map((competition) => {
        return (
          <EditCompetitionModal
            refreshCompetitions={refreshCompetitions}
            user={user}
            competition={competition}
          />
        );
      })}
      <Table data={state.competitions} columns={getColumns()} />
    </>
  );
}

export default Competitions;
