import teams from '../Data/Rugby/teams.json';
import axios from 'axios';

export const getMatchesDataForSweep = (sweep, callback) => {
  if (sweep?.competition?.config_file) {
    fetchExpandedMatchesData(sweep.competition, callback);
  }
};

export const getTeamForId = (teamIds, callback) => {
  callback(teams?.filter((team) => teamIds?.includes(team.teamId)));
};

export const getNumberOfGroups = (matches) => {
  let largestGroup = 0;
  matches?.forEach((match) => {
    largestGroup = match.group > largestGroup ? match.group : largestGroup;
  });
  return largestGroup;
};

const fetchExpandedMatchesData = (competition, callback) => {
  axios
    .get(competition.config_file)
    .then(function (response) {
      callback(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};
