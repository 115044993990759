import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import View from './View';
import { fetchUsers } from '../../../Helpers/UserHelper';

function Container(props) {
  const { user } = props;

  const navigate = useNavigate('');

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!user?.adminAccess) {
      navigate('/');
    } else {
      fetchUsers(user, setUsers);
    }
  }, [user]);

  return <View users={users} user={user} />;
}

export default Container;
