import React from 'react';
import { CardContent, CardHeader, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CreateSweep from '../CreateSweep';
import Sweeps from '../Sweeps';

function SweepsAdminView(props) {
  const { user, sweeps } = props;

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <CreateSweep user={user} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <Sweeps sweeps={sweeps} user={user} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SweepsAdminView;
