/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EntriesWrapper from './EntriesWrapper';
import { sliceIntoChunks } from '../../Helpers/MiscHelpers';
import { ReferralsEntriesWrapper } from '../Referrals/ReferralsWrapper';
import { Link } from 'react-router-dom';

function Entries(props) {
  const { user } = props;

  const [entries, setEntries] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    refreshEntries();
  }, []);

  // Gets current users entries
  function refreshEntries() {
    axios
      .get(process.env.REACT_APP_API_URL + '/user/entries', {
        headers: {
          Authorization: `Bearer ${user.id_token}`,
        },
      })
      .then(function (response) {
        setEntries(response.data['entries']);
        setLoaded(true);
      })
      .catch(function (error) {
        // TODO: display an error if this happens
        console.log(error);
      });
  }

  const chunkedEntries = sliceIntoChunks(
    entries.sort(function (a, b) {
      return new Date(b.date_created) - new Date(a.date_created);
    }),
    3,
  );

  return (
    <React.Fragment>
      <>
        <ReferralsEntriesWrapper entries={entries} user={user} />

        {/* get unique sweeps that entries have not closed for ye */}
        <div className="w3-container w3-margin-top">
          <h3 className="sweeps-font-bold w3-text-teal">Your Entries</h3>
          <p>
            You may edit your entries until the entries closing date. At which
            point, the leaderboard will become active.
          </p>
          {chunkedEntries.length === 0 && loaded && (
            <h6 className="sweeps-font-bold w3-text-teal">
              You haven't signed up to any sweeps. Find a sweep to enter{' '}
              <Link to="/sweeps">here</Link>
            </h6>
          )}
        </div>
        {chunkedEntries.map((arrray, index) => {
          return (
            <EntriesWrapper
              user={user}
              key={index}
              entriesChunk={arrray}
              refreshEntries={refreshEntries}
            />
          );
        })}
      </>
    </React.Fragment>
  );
}

export default Entries;
