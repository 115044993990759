import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewOrganisation } from '../../features/organisations/organisationsSlice';
import Form from './Form';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';

function CreateOrganisation(props) {
  const { user } = props;

  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const dispatch = useDispatch();

  const [emails, setEmails] = useState([]);

  const [state, setState] = React.useState({
    organisationName: '',
    primarySport: '',
    emails: [],
    location: '',
    link: '',
    bio: '',
    file: undefined,
    errors: {},
    successfulCreate: false,
    failedCreate: false,
  });

  function handleChange(evt) {
    const value =
      evt.target.name === 'file' ? evt.target.files[0] : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const canSave =
    [state.organisationName, state.primarySport, state.location].every(
      Boolean,
    ) && addRequestStatus === 'idle';

  const onSavePostClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');
        await dispatch(
          addNewOrganisation({
            name: state.organisationName,
            primarySport: state.primarySport,
            location: state.location,
            link: state.link,
            bio: state.bio,
            admins: emails,
            user: user,
          }),
        ).unwrap();
        // TODO: reset the state
      } catch (err) {
        toast.error('Failed to save the organisation');
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <div style={{ margin: '4px' }}>
      <Form
        user={user}
        errors={state.errors}
        handleChange={handleChange}
        organisationName={state.organisationName}
        location={state.location}
        primarySport={state.primarySport}
        link={state.link}
        bio={state.bio}
        emails={emails}
        setEmails={setEmails}
      />

      <Button
        onClick={() => onSavePostClicked()}
        variant="contained"
        disabled={addRequestStatus != 'idle'}
        className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
      >
        Create
      </Button>
    </div>
  );
}

export default CreateOrganisation;
