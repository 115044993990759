/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect } from 'react';
import Group from '../../SweepsSignup/HorseAuction/Group';
import Tiebreak from '../../SweepsSignup/Tiebreak';
import NickNameSlide from '../../SweepsSignup/NickNameSlide';
import { logMetric } from '../../../Helpers/AnalyticsHelper';
import { editEntry } from '../../../Helpers/EntriesHelper';
import { hasTiebreakQuestion } from '../../../Helpers/SweepsHelper';
import { getOptions } from '../../../Helpers/HorseAuctionHelper';
import { toast } from 'react-toastify';
const TIEBREAK_SLIDE = 6;
const NICK_NAME_SLIDE = 7;
const ERROR_SLIDE = 12;

function EditEntry(props) {
  const { entry, refreshEntries, sweep } = props;

  const scrollToReference = useRef(null);
  const numberOfGroups = 5;
  const executeScroll = () => scrollToReference.current.scrollIntoView();

  const [state, setState] = React.useState({
    picks: entry.picks,
    tieBreaker: entry.tie_breaker,
    activeSlide: 1,
    errors: {},
    options: [],
    nickName: entry.nickName,
    successfulSignup: false,
    editAgain: false,
  });

  const getInitalValidPicks = (optionsData) => {
    const validPicks = {};
    Object.keys(entry.picks).forEach((pick) => {
      if (
        optionsData.filter((option) => parseInt(option.Lot) === parseInt(pick))
          .length > 0
      ) {
        validPicks[pick] = entry.picks[pick];
      }
    });
    return validPicks;
  };

  useEffect(() => {
    if (sweep) {
      getOptions(sweep, updateOptions);
    }
  }, [sweep]);

  const updateOptions = (optionsData) => {
    setState({
      ...state,
      options: optionsData,
      picks: getInitalValidPicks(optionsData),
    });
  };

  const nextSlide = () => {
    executeScroll();
    if (state.activeSlide === numberOfGroups) {
      const slide = hasTiebreakQuestion(sweep)
        ? TIEBREAK_SLIDE
        : NICK_NAME_SLIDE;
      setState({
        ...state,
        activeSlide: slide,
      });
      return;
    }
    setState({
      ...state,
      activeSlide: state.activeSlide + 1,
    });
  };

  const previousSlide = (slide) => {
    executeScroll();
    if (slide) {
      setState({
        ...state,
        activeSlide: slide,
      });
      return;
    }
    let previousSlide = 1000;
    if (state.activeSlide === NICK_NAME_SLIDE) {
      previousSlide = hasTiebreakQuestion(sweep)
        ? TIEBREAK_SLIDE
        : numberOfGroups;
      setState({
        ...state,
        activeSlide: previousSlide,
      });
      return;
    }
    previousSlide =
      state.activeSlide === TIEBREAK_SLIDE
        ? numberOfGroups
        : state.activeSlide - 1;
    setState({
      ...state,
      activeSlide: previousSlide,
    });
  };

  function handleOptionPicked(optionId, sex, stallion, dam, consignor) {
    // Only allowed a max of 5 picks
    const currentPickCount = Object.keys(state.picks).length;
    if (Object.keys(state.picks).length < 5) {
      setState({
        ...state,
        picks: {
          ...state.picks,
          [optionId]: {
            sex,
            optionId,
            stallion,
            dam,
            consignor,
          },
        },
      });
    } else {
      toast.error("You've already selected 5 yearlings.", {
        toastId: 'already_selected_5',
      });
    }
  }

  const handleOptionUnpicked = (optionId) => {
    const picks = state.picks;
    delete picks[optionId];
    setState({
      ...state,
      picks: picks,
    });
  };

  function editAgain() {
    setState({
      ...state,
      successfulSignup: false,
      editAgain: true,
      activeSlide: 1,
    });
  }

  const handleSubmissionResponse = (response) => {
    if (response.data.message.includes('Successfully edited entry')) {
      logMetric('entries', 'successfulEdit');
      setState({
        ...state,
        successfulSignup: true,
      });
      refreshEntries();
    } else {
      logMetric('entries', 'failedEdit');
      setState({
        ...state,
        activeSlide: 12,
        errors: {
          ...state.errors,
          editEntry: {
            messages: [response.response.data.message],
          },
        },
      });
    }
  };

  const submitSelections = () => {
    if (sweep) {
      const newEntry = {
        id: entry.id,
        nickName: state.nickName,
        picks: state.picks,
        sweep_id: sweep.id,
        tie_breaker: state.tieBreaker,
      };
      editEntry(newEntry, handleSubmissionResponse);
    }
  };

  function handleChange(evt) {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function onBlur(evt) {
    const value = evt.target.value;
    let messages = [];
    let addErrors = false;

    if (!value || !/\S/.test(value)) {
      addErrors = true;
      toast.error('Mandatory field, please enter a value', {
        toastId: 'mandatory_field',
      });
      messages.push('Mandatory field, please enter a value');
    }

    if (addErrors) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: messages,
          },
        },
      });
    } else {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: undefined,
        },
      });
    }
  }

  return (
    <React.Fragment>
      <>
        <div id={'editEntry' + entry.id} className="w3-modal w3-medium">
          <div
            className="w3-modal-content"
            style={{ backgroundColor: 'transparent' }}
          >
            <span
              onClick={() => {
                document.getElementById(`editEntry${entry.id}`).style.display =
                  'none';
              }}
              className="pointer w3-button w3-xlarge w3-display-topright times"
            >
              &times;
            </span>

            <div className="w3-row-padding w3-margin-top">
              <div ref={scrollToReference}></div>
              <div className="w3-row">
                <div className="w3-container w3-card w3-white">
                  {state.successfulSignup && (
                    <>
                      <h3 className="sweeps-font-bold w3-text-teal w3-margin-top">
                        Entry Successfully Edited
                      </h3>
                      <button
                        onClick={() => editAgain()}
                        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
                      >
                        Update your entry
                      </button>
                      <button
                        onClick={() => {
                          document.getElementById(
                            `editEntry${entry.id}`,
                          ).style.display = 'none';
                        }}
                        className="w3-btn w3-margin-top w3-margin-left w3-margin-bottom w3-teal"
                      >
                        Return to dashboard
                      </button>
                    </>
                  )}
                  {!state.successfulSignup && (
                    <form
                      className="w3-container"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      {state.activeSlide === 1 && (
                        <Group
                          options={state.options.filter(
                            (option) => option.Group === 1,
                          )}
                          groupTitle="Group 1"
                          picks={state.picks}
                          handleOptionPicked={handleOptionPicked}
                          handleOptionUnpicked={handleOptionUnpicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                          hidePrevious={true}
                        />
                      )}
                      {state.activeSlide === 2 && (
                        <Group
                          options={state.options.filter(
                            (option) => option.Group === 2,
                          )}
                          groupTitle="Group 2"
                          picks={state.picks}
                          handleOptionPicked={handleOptionPicked}
                          handleOptionUnpicked={handleOptionUnpicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}
                      {state.activeSlide === 3 && (
                        <Group
                          options={state.options.filter(
                            (option) => option.Group === 3,
                          )}
                          groupTitle="Group 3"
                          picks={state.picks}
                          handleOptionPicked={handleOptionPicked}
                          handleOptionUnpicked={handleOptionUnpicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}
                      {state.activeSlide === 4 && (
                        <Group
                          options={state.options.filter(
                            (option) => option.Group === 4,
                          )}
                          groupTitle="Group 4"
                          picks={state.picks}
                          handleOptionPicked={handleOptionPicked}
                          handleOptionUnpicked={handleOptionUnpicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}
                      {state.activeSlide === 5 && (
                        <Group
                          options={state.options.filter(
                            (option) => option.Group === 5,
                          )}
                          groupTitle="Group 5"
                          picks={state.picks}
                          handleOptionPicked={handleOptionPicked}
                          handleOptionUnpicked={handleOptionUnpicked}
                          previousSlide={previousSlide}
                          nextSlide={nextSlide}
                        />
                      )}

                      {state.activeSlide === TIEBREAK_SLIDE && (
                        <Tiebreak
                          onBlur={onBlur}
                          handleChange={handleChange}
                          nextSlide={nextSlide}
                          previousSlide={previousSlide}
                          tieBreaker={state.tieBreaker}
                          label={
                            'Tiebreaker:' +
                            sweep.competition.tiebreaker_question
                          }
                        />
                      )}

                      {state.activeSlide === NICK_NAME_SLIDE && (
                        <NickNameSlide
                          onBlur={onBlur}
                          handleChange={handleChange}
                          nextSlide={submitSelections}
                          lastStep={true}
                          previousSlide={previousSlide}
                          nickName={state.nickName}
                        />
                      )}

                      {state.activeSlide === ERROR_SLIDE &&
                        state.errors['editEntry'] && (
                          <>
                            <div className="w3-round-xlarge w3-panel w3-cell-middle w3-pale-red">
                              <p className="w3-margin-top">
                                <i
                                  className={`fa fa-warning fa-fw w3-margin-right`}
                                />
                                {state.errors['editEntry'].messages[0]}
                              </p>
                            </div>
                            <button
                              onClick={() => previousSlide(0)}
                              className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
                            >
                              Back
                            </button>
                          </>
                        )}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}

export default EditEntry;
