import React from 'react';
import { CardContent, CardHeader, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Competitions from '../Competitions';
import CreateCompetition from '../CreateCompetition';

function AdminView(props) {
  const { user } = props;

  return (
    <Grid container spacing={1} padding={2}>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <CreateCompetition user={user} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        <Card>
          <CardHeader className="w3-text-teal sweeps-font-bold" />
          <CardContent>
            <Competitions user={user} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AdminView;
