import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid';
import { useLoadEntrants } from '../../features/useLoadEntrants';

const columns = [
  {
    field: 'nickName',
    headerName: 'Nick Name',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'phone_number',
    headerName: 'Phone',
    width: 250,
  },
];

export default function EntrantsDataTable(props) {
  const { sweep, user } = props;
  const { getEntrants } = useLoadEntrants(sweep, user);
  const entrants = getEntrants();

  return (
    <Box sx={{ height: 400, width: '100%', paddingBottom: '40px' }}>
      <h5 className="sweeps-font-bold">Entrants:</h5>
      <hr />
      <DataGrid
        rows={entrants || []}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50, 100]}
      />
    </Box>
  );
}
