import { Editor } from 'react-draft-wysiwyg';
import { React, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export function WYSIWYG(props) {
  const { onChange, name, value } = props;

  // Value should be in HTML format
  const contentBlock = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks,
  );
  const [state, setState] = useState({
    editorState: EditorState.createWithContent(contentState),
  });

  const onEditorStateChange = (editorState) => {
    const changeEvt = {
      target: {
        name: name,
        value: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      },
    };
    onChange(changeEvt);
    setState({
      editorState,
    });
  };

  return (
    <Editor
      editorState={state.editorState}
      onEditorStateChange={onEditorStateChange}
    />
  );
}
