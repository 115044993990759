/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import axios from 'axios';

import RegisterUserFields from './RegisterUserFields';
import AuthWrapper from '../Login/AuthWrapper';
import {
  isValidPassword,
  validateEmail,
  validatePassword,
  validatePhoneNumber,
  validateRequiredField,
} from '../../../Helpers/RegisterHelper';

const apiUrl = process.env.REACT_APP_API_URL;

function Register(props) {
  const { previousSlide, onSuccess, fetchUserDetails, prominentLogin } = props;

  const fields = [
    'name',
    'email',
    'phone',
    'over18',
    'signupTerms',
    'emailConfirmation',
    'password',
    'confirmPassword',
  ];
  const [state, setState] = React.useState({
    name: '',
    email: '',
    emailConfirmation: '',
    phone: '',
    over18: false,
    signupTerms: false,
    password: '',
    confirmPassword: '',
    errors: {},
    alreadyHasAccount: false,
  });

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
  }, []);

  function canCreateAccount() {
    if (state.name && state.email && state.phone && state.password) {
      return true;
    }
    return false;
  }

  const createAccount = () => {
    if (!canCreateAccount()) {
      return;
    }
    axios
      .post(apiUrl + '/account', {
        name: state.name,
        email: state.email,
        phoneNumber: state.phone,
        password: state.password,
      })
      .then(function (response) {
        if (response.data.message.includes('Successfully created user')) {
          if (onSuccess) {
            onSuccess(response.data.user_id, state.email);
          }
        }
      })
      .catch(function (error) {
        setState({
          ...state,
          errors: {
            ...state.errors,
            form: {
              messages: [error?.response?.data?.message],
            },
          },
        });
      });
  };

  function handlePhoneChange(value) {
    setState({
      ...state,
      phone: value,
    });
  }

  function handleChange(evt) {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function onBlur(evt) {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    let errors = [];
    let password = state.password;
    let confirmPassword = state.confirmPassword;

    errors = errors.concat(validateRequiredField(evt.target.name, value));
    errors = errors.concat(
      validatePhoneNumber(evt.target.name, state.phone ? state.phone : ''),
    );
    errors = errors.concat(
      validateEmail(evt.target.name, state.email, state.emailConfirmation),
    );

    if (evt.target.name === 'password') {
      password = value;
      errors = errors.concat(validatePassword(password, state.confirmPassword));
    }
    if (evt.target.name === 'confirmPassword') {
      confirmPassword = value;
      errors = errors.concat(validatePassword(state.password, value));
    }

    if (errors.length > 0) {
      setState({
        ...state,
        errors: {
          ...state.errors,
          [evt.target.name]: {
            messages: errors,
          },
        },
      });
    } else {
      if (
        (evt.target.name === 'emailConfirmation' ||
          evt.target.name === 'email') &&
        emailsPopulated() &&
        isEmailsMatching()
      ) {
        setState({
          ...state,
          errors: {
            ...state.errors,
            email: undefined,
            emailConfirmation: undefined,
            form: undefined,
          },
        });
      } else if (
        isPasswordField(evt) &&
        isPasswordsMatching(password, confirmPassword) &&
        isValidPassword(password)
      ) {
        setState({
          ...state,
          errors: {
            ...state.errors,
            password: undefined,
            confirmPassword: undefined,
            form: undefined,
          },
        });
      } else {
        setState({
          ...state,
          errors: {
            ...state.errors,
            [evt.target.name]: undefined,
            form: undefined,
          },
        });
      }
    }
  }

  const isPasswordField = (event) => {
    return (
      event.target.name === 'password' ||
      event.target.name === 'confirmPassword'
    );
  };

  const isPasswordsMatching = (password, passwordConfirmation) => {
    return password === passwordConfirmation;
  };

  function isEmailsMatching() {
    if (state.email === state.emailConfirmation) {
      return true;
    }
    return false;
  }

  function emailsPopulated() {
    if (state.email !== '' && state.emailConfirmation !== '') {
      return true;
    }
  }

  let submissionEnabled = true;
  fields.forEach((field) => {
    if (state[field] === '' || state[field] === false || state.errors[field]) {
      submissionEnabled = false;
    }
  });

  function navigateToLogin() {
    setState({
      ...state,
      alreadyHasAccount: true,
    });
  }

  function setRegisterState() {
    setState({
      ...state,
      alreadyHasAccount: false,
    });
  }

  const registerComponent = (
    <>
      <h4 className="sweeps-font-bold w3-large w3-text-teal w3-margin-top">
        Register with Sweeps
      </h4>
      <p>
        Already have an account? Login{' '}
        <a className="w3-text-teal pointer" onClick={() => navigateToLogin()}>
          here
        </a>
      </p>
      <RegisterUserFields
        progressEnabled={submissionEnabled}
        onBlur={onBlur}
        handleChange={handleChange}
        handlePhoneChange={handlePhoneChange}
        name={state.name}
        email={state.email}
        phone={state.phone}
        over18={state.over18}
        signupTerms={state.signupTerms}
        password={state.password}
        confirmPassword={state.confirmPassword}
        previousSlide={previousSlide}
        createAccount={createAccount}
        nextSlide={createAccount}
        errors={state.errors}
      />
    </>
  );

  return (
    <React.Fragment>
      {prominentLogin && (
        <>
          <h3 className="w3-center sweeps-font-bold w3-large w3-text-teal w3-margin-top">
            Please either login or register an account to continue
          </h3>
          <div className="w3-half">
            <div className="w3-padding">
              <AuthWrapper
                onSuccess={onSuccess}
                setRegisterState={setRegisterState}
                fetchUserDetails={fetchUserDetails}
              />
            </div>
          </div>
          <div className="w3-half">
            <div className="w3-padding">{registerComponent}</div>
          </div>
        </>
      )}
      {!prominentLogin && (
        <>
          {!state.alreadyHasAccount && registerComponent}
          {state.alreadyHasAccount && (
            <>
              <AuthWrapper
                onSuccess={onSuccess}
                setRegisterState={setRegisterState}
                fetchUserDetails={fetchUserDetails}
              />
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default Register;
