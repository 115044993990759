import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import OrganisationsDetails from './Details';
import { useLoadSweeps } from '../../../features/useLoadSweeps';
import SweepsForOrganisation from './Sweeps';
import StripeConnectionContainer from './Stripe/StripeConnectionContainer';
import LottoLicenseContainer from '../Licenses/Container';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function IndividualOrganisation(props) {
  const { organisation, stripeReturn, stripeRefresh } = props;
  const [value, setValue] = React.useState(0);

  const { getSweepsArray } = useLoadSweeps();
  const sweeps = getSweepsArray();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSweepsForOrg = () => {
    return sweeps.filter(
      (sweep) => sweep?.organisation_id === organisation?.id,
    );
  };

  useEffect(() => {
    if (stripeReturn || stripeRefresh) {
      handleChange({}, 1);
    }
  }, [stripeReturn, stripeRefresh]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="Organisation Tabs"
        >
          <Tab label="Sweeps" {...a11yProps(0)} />
          <Tab label="Stripe" {...a11yProps(1)} />
          <Tab label="Add Lotto License" {...a11yProps(2)} />
          <Tab label="Details" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SweepsForOrganisation
          {...props}
          organisation={organisation}
          sweeps={getSweepsForOrg()}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <StripeConnectionContainer organisation={organisation} {...props} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <LottoLicenseContainer {...props} organisation={organisation} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <OrganisationsDetails organisation={organisation} />
      </CustomTabPanel>
    </Box>
  );
}
