import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../Layouts/Table';
import { getPrizeDisplayName } from '../../Helpers/PrizeHelper';
import CompetitionName from '../Competitions/CompetitionName';
import EditSweepModal from './EditSweepModal';
import { getUrlAlias } from '../../Helpers/SweepsHelper';

function Sweeps(props) {
  const { user, sweeps } = props;
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = '#f1f1f1';
    window.scrollTo(0, 0);
  }, []);

  function getColumns(document, navigate) {
    return [
      {
        name: '',
        cell: (row) => (
          <button
            onClick={() => navigate(`/${getUrlAlias(row)}`)}
            className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
          >
            Home
          </button>
        ),
      },
      {
        name: '',
        cell: (row) => (
          <button
            onClick={() => navigate(`/${getUrlAlias(row)}/leaderboard`)}
            className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
          >
            Scores
          </button>
        ),
      },
      {
        name: '',
        cell: (row) => (
          <button
            onClick={() =>
              (document.getElementById(`editSweep-${row.id}`).style.display =
                'block')
            }
            className="w3-btn w3-margin-top w3-margin-bottom w3-teal"
          >
            Edit
          </button>
        ),
      },
      {
        name: 'Organisation',
        selector: (row) => row?.organisation?.name,
        wrap: true,
      },
      {
        name: 'Prize',
        selector: (row) => getPrizeDisplayName(row.prize_breakdown),
        wrap: true,
      },
      {
        name: 'Competition',
        cell: (row) => <CompetitionName competition={row.competition} />,
      },
      {
        name: 'Date',
        selector: (row) => row.date_created,
        wrap: true,
      },
      {
        name: 'Private',
        selector: (row) => (row?.private_sweep ? 'Yes' : 'No'),
        wrap: true,
      },
    ];
  }

  return (
    <>
      <h3 className="sweeps-font-bold w3-text-teal">Sweeps</h3>
      {sweeps.map((sweep) => {
        return <EditSweepModal key={sweep.id} user={user} sweep={sweep} />;
      })}
      <Table data={sweeps} columns={getColumns(document, navigate)} />
    </>
  );
}

export default Sweeps;
