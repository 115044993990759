import React from 'react';
import Group from './Group';
import HowItWorks from '../HowItWorks';
import Card from '../../Layouts/Card';
import Rules from './Rules';
import SelectionsContainer from './SelectionsContainer';

function SignupForm(props) {
  const { context, handlers } = props;

  const {
    sweep,
    activeSlide,
    players,
    groupOnePlayers,
    groupTwoPlayers,
    groupThreePlayers,
    groupFourPlayers,
    groupFivePlayers,
    group_1,
    group_2,
    group_3,
    group_4,
    group_5,
  } = context;

  const { nextSlide, previousSlide, pickTeam, handlePlayerPicked } = handlers;

  return (
    <>
      <SelectionsContainer
        sweep={sweep}
        group_1={group_1}
        group_2={group_2}
        group_3={group_3}
        group_4={group_4}
        group_5={group_5}
      />

      {activeSlide === 0 && (
        <>
          <HowItWorks nextSlide={nextSlide} pickTeam={pickTeam} sweep={sweep}>
            <Rules sweep={sweep} />
          </HowItWorks>
        </>
      )}

      {activeSlide === 1 && (
        <Card>
          <Group
            players={groupOnePlayers}
            allPlayersData={players}
            selected={group_1}
            groupTitle="Group 1"
            groupKey="group_1"
            handlePlayerPicked={handlePlayerPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}

      {activeSlide === 2 && (
        <Card>
          <Group
            players={groupTwoPlayers}
            allPlayersData={players}
            selected={group_2}
            groupTitle="Group 2"
            groupKey="group_2"
            handlePlayerPicked={handlePlayerPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}

      {activeSlide === 3 && (
        <Card>
          <Group
            players={groupThreePlayers}
            allPlayersData={players}
            selected={group_3}
            groupTitle="Group 3"
            groupKey="group_3"
            handlePlayerPicked={handlePlayerPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}

      {activeSlide === 4 && (
        <Card>
          <Group
            players={groupFourPlayers}
            allPlayersData={players}
            selected={group_4}
            groupTitle="Group 4"
            groupKey="group_4"
            handlePlayerPicked={handlePlayerPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}

      {activeSlide === 5 && (
        <Card>
          <Group
            players={groupFivePlayers}
            allPlayersData={players}
            selected={group_5}
            groupTitle="Group 5"
            groupKey="group_5"
            handlePlayerPicked={handlePlayerPicked}
            previousSlide={previousSlide}
            nextSlide={nextSlide}
          />
        </Card>
      )}
    </>
  );
}

export default SignupForm;
